import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../../../../app/store";
import {addFavouriteEventCalendarCall, getCalendarCall, removeFavouriteEventCalendarCall} from "./calendarAPI";
import {setActiveElementArea, setCurrentAreaAction} from "../../area/areaSlice";



export interface CalendarState{
  calendarEvents: any[],
  calendarUserEvents: any[],
}

const initialState: CalendarState = {
  calendarEvents : [],
  calendarUserEvents : []
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setCalendarEvents: (state, action: PayloadAction<any>) => {
      state.calendarEvents =  action.payload;
    },
    resetCalendarEvents: (state) => {
      state.calendarEvents =  [];
    },
    setCalendarUserEvents: (state, action: PayloadAction<any>) => {
      state.calendarUserEvents =  action.payload;
    },
    resetCalendarUserEvents: (state) => {
      state.calendarUserEvents =  [];
    },
    addEventToFavorite: (state, action: PayloadAction<string>) => {
      state.calendarUserEvents = [...state.calendarUserEvents.filter(ele => ele.id.toString() !== action.payload.toString()), state.calendarEvents.find(ele => ele.id.toString() === action.payload.toString())]
    },
    removeEventByFavorite: (state, action: PayloadAction<string>) => {
      state.calendarUserEvents = state.calendarUserEvents.filter(ele => ele.id.toString() !== action.payload.toString())
    }
  },
});

export const {
  setCalendarEvents,
  resetCalendarEvents,
  setCalendarUserEvents,
  resetCalendarUserEvents,
  addEventToFavorite,
  removeEventByFavorite
} = calendarSlice.actions;

export const setCalendarAction = (call: any= {}): AppThunk => (dispatch, getState) => {
  const eventId = getState().event.settings?.id;
  getCalendarCall(eventId).then(res => {
    dispatch(setCalendarEvents(res.calendar_events))
    dispatch(setCalendarUserEvents(res.calendar_events_user))
  })

};

export const addFavoriteEventCalendarAction = (id: string): AppThunk => (dispatch, getState) => {
  addFavouriteEventCalendarCall(id).then(res => {
    dispatch(addEventToFavorite(id))
  })
};
export const removeFavoriteEventCalendarAction = (id: string): AppThunk => (dispatch, getState) => {
  removeFavouriteEventCalendarCall(id).then(res => {
    dispatch(removeEventByFavorite(id))
  })
};

export const openModuleAction = (moduleId: string): AppThunk => (dispatch, getState) => {
  const eventId = getState().event.settings?.id;
  dispatch(setActiveElementArea({actions: {
      link: `${process.env.REACT_APP_URL}events/${eventId}/modules/${moduleId}`
    }}))

};
export const resetCalendar = (): AppThunk => (dispatch, getState) => {
  dispatch(resetCalendarEvents())
  dispatch(resetCalendarUserEvents())
};

export const selectCalendarEvents = (state: RootState) => state.calendar.calendarEvents;
export const selectCalendarUserEvents = (state: RootState) => state.calendar.calendarUserEvents;

export default calendarSlice.reducer;
