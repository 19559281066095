import React from "react";
import CardRounded from "../../../../components/shared/cards/card/cardRounded/CardRounded";
import { calcVh } from "../../../../components/shared/utils/vhUtils";

export function ContentBox({
	inAnimation,
	html = "",
}: {
	html?: any;
	inAnimation: boolean;
}) {
	return (
		<>
			<div
				className={`content-box ${inAnimation ? "in" : ""} ${html ? "login-event-description" : ""}`}
				style={{
					maxHeight: `calc(${calcVh(100)} - 60px)`,
					display: "flex",
					flexDirection: "column",
				}}>
				<CardRounded
					styleCard={{
						// maxHeight: `calc(${calcVh(100)} - 60px - 50px)`,
						maxHeight: `100%`,
					}}>
					<div
						dangerouslySetInnerHTML={{ __html: html }}
						style={
							{
								height: "100%",
								overflow: "auto",
								overflowX: "hidden",
								WebkitOverflowScrolling: "touch",
							} as any
						}></div>
				</CardRounded>
			</div>
		</>
	);
}
