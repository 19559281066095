import React from "react";
import Input from "../../design/form/input/Input";
import Select from "../../design/form/select/Select";
import TextArea from "../../design/form/textArea/TextArea";
import Switch from "../../design/form/switch/Switch";
import CheckboxList from "../../design/form/checkboxList/CheckboxList";
import { Row, Col } from "react-bootstrap";
import Radio from "../../design/form/radio/Radio";
import Progress from "../../design/form/progress/Progress";
import { FormControlCustomModel } from "./models/FormElementModel";
import InputFile from "../../design/form/file-custom/InputFile";

const FormControlCustom = ({
	isSubmit,
	errors,
	dataElement,
	name,
	value,
	changeValue = () => {},
	typeElement = "input",
	options = [],
	onBlur,
}: FormControlCustomModel) => {
	return (
		<>
			{typeElement === "input" && (
				<Input
					name={name}
					error={isSubmit && !!errors && !!errors[name]}
					value={value}
					onChangeAction={changeValue}
					onBlur={onBlur}
					classNameGroup={dataElement.className}
					{...dataElement}
				/>
			)}
			{typeElement === "file" && (
				<InputFile
					name={name}
					error={isSubmit && !!errors && !!errors[name]}
					value={value}
					onChangeAction={changeValue}
					onBlur={onBlur}
					{...dataElement}
				/>
			)}
			{typeElement === "select" && (
				<Select
					error={isSubmit && !!errors && !!errors[name]}
					name={name}
					options={options}
					value={value}
					className={dataElement.className}
					menuPlacement={(dataElement as any)?.menuPlacement || "top"}
					{...dataElement}
					changeSelect={changeValue}
				/>
			)}
			{typeElement === "textarea" && (
				<TextArea
					name={name}
					value={value}
					onBlur={onBlur}
					classNameGroup={dataElement.className}
					{...dataElement}
					onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
						changeValue(event.target.value);
					}}
				/>
			)}
			{typeElement === "radio" && (
				<Row>
					{options.map((ele, index) => {
						return (
							<Col xs="auto" key={index}>
								<Radio
									name={name}
									value={ele.value}
									label={ele.label}
									checked={ele.value === value}
									{...dataElement}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										changeValue(event.target.value)
									}
								/>
							</Col>
						);
					})}
				</Row>
			)}
			{typeElement === "checkbox" && (
				<CheckboxList
					value={value}
					options={options}
					name={name}
					changeChecked={changeValue}
				/>
			)}
			{typeElement === "progress" && (
				<Progress
					value={value}
					changeProgress={(event: React.ChangeEvent<HTMLInputElement>) => {
						changeValue(event.target.value);
					}}
				/>
			)}
			{typeElement === "switch" && (
				<Switch
					error={isSubmit && !!errors && !!errors[name]}
					name={name}
					checked={value}
					changeSwitch={(event: React.ChangeEvent<HTMLInputElement>) => {
						changeValue(
							dataElement && dataElement.type === "checkbox"
								? event.target.checked
								: event.target.value
						);
					}}></Switch>
			)}
		</>
	);
};

export default FormControlCustom;
