import React, {useEffect} from "react";
import {Profile} from "../../../models/Profile";

import {createStructureForm} from "../../../components/shared/utils/forms/useStructureForm";
import FormElements from "../../../components/shared/utils/forms/FormElements";
import {
    UseFormSubmitModel,
} from "../../../components/shared/utils/forms/models/UseFormModel";
import {useForm} from "../../../components/shared/utils/forms/useForm";
import {useDispatch, useSelector} from "react-redux";
import {
    registerAction, selectResetForm, selectSteps,
    setStepsAction,
} from "./registerSlice";
import {Col, Row} from "react-bootstrap";
import PrimaryButton from "../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import {useTranslation} from "react-i18next";
import {useRegister} from "./useRegister";
import {selectProfilesFilter} from "./registerSelectors";
import {calcVh} from "../../../components/shared/utils/vhUtils";

interface IRegister {
    profiles: Profile[];
    changeProfileId?: () => void;
    goLogin: () => void;
    successCallback?: () => void
}

const Register = ({profiles, changeProfileId, goLogin, successCallback}: IRegister) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const steps = useSelector(selectSteps);
    const resetForm = useSelector(selectResetForm);
    const filterProfiles = useSelector(selectProfilesFilter);
    const {allStep} = useRegister(t, filterProfiles, steps)
    const stepsStructure = allStep.map((ele) =>
        createStructureForm(
            ele.formStructure.map((el) => ({
                ...el,
                dataElement: {...el.dataElement},
                col: {xs: 12},
            }))
        )
    );

    const submitHandler: UseFormSubmitModel = (event, data) => {
        const callback = () => goLogin();
        dispatch(registerAction(data, callback, successCallback));
    };

    const validations = allStep
        .map((ele) => ele.validations)
        .reduce((obj, ele) => ({...obj, ...ele}), {});

    const {isSubmit, errors, submitAction, changeValue, dataForm, setData} =
        useForm(submitHandler, validations);
    useEffect(() => {
        resetForm && setData({profile_id: filterProfiles?.length ? filterProfiles[0].id.toString() : undefined})
    }, [resetForm])
    useEffect(() => {
        if (filterProfiles?.length && !dataForm.profile_id) {
            setData({profile_id: filterProfiles[0].id.toString()})
        }
    }, [filterProfiles])

    useEffect(() => {
        changeProfileId && changeProfileId();
        dispatch(setStepsAction(dataForm.profile_id));
    }, [dataForm.profile_id]);

    return (
        <div
            style={{overflowY: "auto", overflowX: 'hidden', maxHeight: `calc( ${calcVh(80)} - 120px)`}}>
            <form name="registerForm" id="registerForm" onSubmit={submitAction}>
                <div className="row">
                    {stepsStructure.map((step, index) => (
                        <div className="col-12" key={index}>
                            <FormElements
                                data={step}
                                errors={errors}
                                isSubmit={isSubmit}
                                dataForm={dataForm}
                                changeValue={changeValue}
                            />
                        </div>
                    ))}
                </div>
                <Row>
                    <Col>
                        <PrimaryButton className="my-2">
                            {t("login.register").toUpperCase()}
                        </PrimaryButton>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default Register;
