import React from "react";
import FormGroupCustom from "./FormGroupCustom";
import { FormElementModelNew } from "./models/FormElementModel";
import { Col, Row } from "react-bootstrap";
import { ValueName } from "./models/DataForm";

const FormElements = ({
  data = [],
  errors,
  isSubmit = false,
  changeValue = () => {},
  dataForm,
  onBlur = () => {},
}: {
  data: FormElementModelNew[];
  isSubmit?: boolean;
  errors: { [key: string]: any };
  changeValue?: ({ value, name }: ValueName) => void;
  dataForm: { [key: string]: any };
  onBlur?: ({ value, name }: ValueName) => void;
}) => {
  let issetCol = !!data.find((ele) => ele.col);

  const formGroup = ({
    label,
     subLabel,
    name,
    dataElement = {},
    typeElement,
    options,
  }: FormElementModelNew) => (
    <FormGroupCustom
      key={name}
      isSubmit={isSubmit}
      name={name}
      errors={errors}
      label={label}
      subLabel={subLabel}
      display={dataElement.display}
      classInputGroup={dataElement.className}
      dataElement={dataElement}
      typeElement={typeElement}
      value={dataForm[name]}
      options={options}
      changeValue={(value) => changeValue({ value: typeElement === 'file'? {[name]: value} : value, name })}
      onBlur={(value) => onBlur({ value, name })}
    />
  );
  const form = () =>
    data.map((ele: FormElementModelNew, index) => ColContainer(ele, index));
  const ColContainer = (ele: FormElementModelNew, index: number) => {
    return issetCol ? (
      <Col key={index} {...ele.col}>
        {formGroup(ele)}
      </Col>
    ) : (
      formGroup(ele)
    );
  };

  return <>{issetCol ? <Row>{form()}</Row> : form()}</>;
};

export default FormElements;
