import React from "react";
import { MsgProps } from "../models/MsgProps";
import MsgSuccess from "../msgSuccess/MsgSuccess";
import {useDispatch, useSelector} from "react-redux";
import {removeMessage, selectMessages} from "../../../../app/messages/messageSlice";

const MsgList = () => {
	const dispatch = useDispatch();
	const list: MsgProps[] = useSelector(selectMessages).map( ele =>
		({
			title: (ele.opening_time && new Date(ele.opening_time).toLocaleString([], {hour: '2-digit', minute:'2-digit'})) || '',
			body: ele.message,
			id: ele.opening_time,
			type: "success"
		} as any)
	)
	const closeAction = (toast: any) => {
		dispatch(removeMessage(toast))
	}
	return (
		<>
			<div className="shared-msgList">
				{list.map((toast) => {
					switch (toast.type) {
						case "success":
							return (
								<MsgSuccess
									key={toast.id}
									{...toast}
									closeAction={() => closeAction(toast)}
								/>
							);
						case "warning":
							return <></>;
						case "error":
							return <></>;
						case "unauthorized":
							return <></>;
						default:
							return;
					}
				})}
			</div>
		</>
	);
};

export default MsgList;
