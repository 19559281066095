import React from "react";
import { Popover } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";

const PopoverCustom = ({
	children,
	placement = "auto",
	trigger = ["hover", "focus"],
	tooltipBodyTemplate,
	tooltipStyle,
}: {
	children?: any;
	placement?: "auto" | "top" | "right" | "bottom" | "left";
	trigger?: "hover" | "click" | "focus" | ("hover" | "click" | "focus")[];
	tooltipBodyTemplate: any;
	tooltipStyle?: any;
}) => {
	const stylePopover = {
		backgroundColor: tooltipStyle?.background_color,
		backgroundOpacity: tooltipStyle?.background_opacity,
		borderRadius: tooltipStyle?.border_radius,
		padding: tooltipStyle?.padding,
		width: tooltipStyle?.width,
	};

	const styleColorArrow = {
		"--color-arrow-tooltip-var": tooltipStyle?.background_color,
	} as React.CSSProperties;

	return (
		<OverlayTrigger
			trigger={trigger}
			key={placement}
			placement={tooltipStyle?.position || placement}
			overlay={
				<Popover
					style={{ ...stylePopover, ...styleColorArrow }}
					arrowProps={{
						ref: () => null,
						style: {},
					}}
					className="shared-tooltip"
					id={`popover-${placement}`}>
					<Popover.Content className="content">
						{tooltipBodyTemplate}
					</Popover.Content>
				</Popover>
			}>
			{children}
		</OverlayTrigger>
	);
};
export default PopoverCustom;
