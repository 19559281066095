import React, { useEffect } from "react";
import { Error404 } from "../404/Error404";
import { useDispatch, useSelector } from "react-redux";
import { selectInitEvent, selectSettingEvent, setEvent, setEventInit } from "./eventSlice";
import { SwitchPage } from "../SwitchPage";
import { selectIsLogged, setRefreshData } from "../../../app/auth/authSlice";
import { AuthHome } from "../../auth/authHome/AuthHome";
import ErrorView from "../../../app/errors/ErrorView";

export function Event() {
	const init = useSelector(selectInitEvent);
	const setting = useSelector(selectSettingEvent);
	const isLogged = useSelector(selectIsLogged);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setEvent());
	}, []);
	useEffect(() => {
		setting && dispatch(setRefreshData(setting.id));
	}, [setting]);
	useEffect(() => {
		setting && !setting?.online && !isLogged && dispatch(setEventInit(false));
	}, [setting, isLogged]);


	return (
		<div className="app-container">
			<ErrorView />
			{init && !isLogged && <SwitchPage {...(setting || {})} />}
			{init && isLogged && <AuthHome />}
			{init === false && <Error404 />}
			{init === undefined && <div>Loading...</div>}
		</div>
	);
}
