import React, {useEffect, useState} from "react";
import { FormGroup } from "react-bootstrap";
import Select from "../design/form/select/Select";
import i18n, {dropdownSelectableLangs} from "../../../config/i18n";
import SelectReact from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {
	selectLanguage,
	selectLanguagesOption,
	setLanguage,
	setLanguageAction
} from "../../../features/public/event/eventSlice";

interface IProps {
	onSelectedLanguageChange?: (nextLanguage: string) => void;
}


const LanguageSelector = ({onSelectedLanguageChange}: IProps) => {
	const selectedLanguage = useSelector(selectLanguage);
	const availableLanguages = useSelector(selectLanguagesOption);
	// const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
	const dispatch = useDispatch();
	const changeLanguage = (lng: string) => {
		dispatch(setLanguageAction(lng))
		onSelectedLanguageChange && onSelectedLanguageChange(lng)
	};
	useEffect(() => {
		i18n.changeLanguage(selectedLanguage)
	}, [selectedLanguage])

	return (
		<FormGroup style={{ marginBottom: "0px" }} className="lang-select">
			<Select
				className="lang-select"
				name="select"
				options={availableLanguages}
				changeSelect={changeLanguage}
				value={selectedLanguage}
			/>
		</FormGroup>
	);
};

export default LanguageSelector;
