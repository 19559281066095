import SendBird from "sendbird";
import {
    addUpdateChannelAction,
    setChannelPrivate,
    setChannelsAction, setLoading,
    setNotify,
    setUsersChatAction,
    splitPublicChannel
} from "../chatSlice";
let sb: SendBird.SendBirdInstance;
let dispatchLink: any;
let eventIdChat: string;
const recoveryAllList = (listQuery: any, data: any[] = [], callback: (data: any[]) => void) => {
    listQuery.next(function (list = [], error: any){
        if(list && list.length){
            const dataNew = [...data, ...list];
            recoveryAllList(listQuery, dataNew, callback)
        } else {
            callback(data)
        }
    })
}

export const initSendBirdChat = (dispatch: any, eventId: number, userId: any) => {
    eventIdChat = eventId.toString();
    sb = new SendBird({appId: process.env.REACT_APP_SENDBIRD_ID || ''});
    sb.connect(userId, function (user, error){
        if(!(user.metaData as any)?.eventId){
            user.createMetaData({eventId: eventIdChat}, function (data, error){
                console.log(data, error);
            })
        }
    })
    dispatchLink = dispatch;
    var channelHandler = new sb.ChannelHandler();
    var userEventHandler = new sb.UserEventHandler();

    userEventHandler.onTotalUnreadMessageCountUpdated = function(totalCount, countByCustomTypes) {
        dispatch(setNotify(totalCount));
    };

    sb.addUserEventHandler('45', userEventHandler);
    channelHandler.onChannelChanged= function (channel){
        dispatch(addUpdateChannelAction(channel))
    }
    sb.addChannelHandler('5', channelHandler)
    //
    //


    const listQuery = sb.OpenChannel.createOpenChannelListQuery();
    listQuery.customTypes = ['room_'+eventId, 'public_'+eventId, 'stand_'+eventId]
    listQuery.limit = 100;
    const callback = (groupChannelList: any[])=> dispatch(splitPublicChannel(groupChannelList));
    recoveryAllList(listQuery, [], callback)

    const myQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    myQuery.limit = 100;
    const callbackPrivate = (groupChannelList: any[])=> {
        dispatch(setChannelsAction(groupChannelList));
        groupChannelList && groupChannelList.length && dispatch(setChannelPrivate(groupChannelList[0], false))
    };
    recoveryAllList(myQuery, [], callbackPrivate)
}
export const getListUserChat = () => {
    var listQuery = sb && sb.createApplicationUserListQuery && sb.createApplicationUserListQuery();
    listQuery.limit = 100;
    listQuery.metaDataKeyFilter = 'eventId'
    listQuery.metaDataValuesFilter = [eventIdChat]
    dispatchLink && dispatchLink(setLoading(true))
    const callback = (users: any[]) => {
        dispatchLink && dispatchLink(setUsersChatAction(users));
        setTimeout(() => {
            dispatchLink && dispatchLink(setLoading(false));
        }, 400)

    }
    recoveryAllList(listQuery, [], callback)
}

export const removeListerSendBird = () => {
    sb?.removeChannelHandler('5');
    sb?.removeChannelHandler('45');
}
