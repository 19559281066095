import React from "react";
import Icon from "../../../../../components/shared/icon/Icon";
import ItemRoom from "./ItemRoom";

export default function ListStands({
	channels,
	setPublicChannel,
	selectChannel,
	chatActive,
	setChatActive,
}: {
	channels: any[];
	setPublicChannel: (channel: any) => void;
	selectChannel?: any;
	chatActive: string | undefined;
	setChatActive: (data: string | undefined) => void;
}) {
	return (
		<div className="rooms">
			<h5>Stands</h5>
			<div
				className="content-item"
				style={{
					maxHeight: !chatActive || chatActive === "stand" ? "2000px" : "0px",
				}}>
				<div className="list-rooms">
					{channels &&
						channels.map((ele) => (
							<ItemRoom
								key={ele.name}
								item={ele}
								clickHandler={() => setPublicChannel(ele)}
								isActive={!!selectChannel && selectChannel.url === ele.url}
							/>
						))}
				</div>
				{!chatActive || chatActive !== "stand" ? (
					<Icon icon="down" clickAction={() => setChatActive("stand")} />
				) : (
					<Icon
						icon="down"
						style={{ transform: "rotate(180deg)" }}
						clickAction={() => setChatActive(undefined)}
					/>
				)}
			</div>
		</div>
	);
}
