import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction, selectUser } from "../../../app/auth/authSlice";
import {
	selectDefaultModule,
	selectSettingEvent,
} from "../../public/event/eventSlice";
import { setCallAction } from "../callSlice";
import { AreaHome } from "./area/Area";
import {
	selectArea,
	selectCurrentArea,
	setCurrentAreaAction,
} from "./area/areaSlice";
import NavbarApp from "./header/NavbarApp";
import { CallView } from "./sendbird/CallView";
import ListUsersCall from "./sendbird/components/ListUsersCall";
import { SendBirdCustom } from "./sendbird/SendBirdCustom";
import { sendCallAction } from "./SendBirdCallAction";
import {
	calendarData,
	optionMenu,
	suitecaseData,
} from "./menu/desktop/menuTool/menuData";
import { CardInfo } from "./CardInfo";
import { CardAction } from "./CardAction";
import Profile from "./sendbird/components/profile/Profile";
import { activeOpenChatAction, setOpenUserChat } from "../chatSlice";
import { openModuleAction } from "./sections/calendar/calendarSlice";
import { createUserIdChat } from "../../../components/shared/utils/chatUtils";
import { addSuccessMessage } from "../../../app/errors/errorSlice";
import { useTranslation } from "react-i18next";
import { Menu } from "./menu/Menu";
import {
	selectSelectedViewMobile,
	setSelectedView,
} from "../../../app/mobileSlice";
import MenuAreas from "./menu/MenuAreas";
import { selectIsMobile } from "../../../app/generalSettingsSlice";
import Privacy from "./Privacy";
import CardAppointment from "./appointment/CardAppointment";
import MsgList from "../../../components/shared/messages/msgList/MsgList";
import { MsgProps } from "../../../components/shared/messages/models/MsgProps";
import { messageBroadcast } from "../../../app/messages/messageAPI";
import { initMessageBroadcast } from "../../../app/messages/messageSlice";
import ListAppointment from "./appointment/ListAppointment";
import Posters from "./posters/Posters";
import AuthRegister from "./sendbird/components/AuthRegister";

export function AuthHome() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const area = useSelector(selectArea);
	const defaultModule = useSelector(selectDefaultModule);
	const userLogged = useSelector(selectUser);
	const isGuest = userLogged?.role === 'guest'
	const user = useSelector(selectUser);
	const event = useSelector(selectSettingEvent);
	const isMobile = useSelector(selectIsMobile);
	const selectedView = useSelector(selectSelectedViewMobile);
	const [showChat, setShowChat] = useState<boolean>(false);
	const [showRegister, setShowRegister] = useState<boolean>(false);
	const [showInfo, setShowInfo] = useState<string | undefined>();
	const [showCallList, setShowCallList] = useState<boolean>(false);
	const [inAnimation, setInAnimation] = useState<boolean>(false);
	const [showProfile, setShowProfile] = useState<boolean | string>(false);
	const [showCalendarProfile, setShowCalendarProfile] = useState<
		string | undefined
	>();
	const [showRepresentativeList, setShowRepresentativeList] = useState<
		string | undefined
	>();
	const [showPosters, setShowPosters] = useState<
		string | undefined
	>();
	const currentArea = useSelector(selectCurrentArea);

	useEffect(() => {
		setTimeout(() => {
			setInAnimation(true);
			defaultModule && dispatch(openModuleAction(defaultModule));
		}, 200);
	}, []);

	useEffect(() => {
		userLogged && dispatch(initMessageBroadcast());
	}, [userLogged]);
	const setCurrentAreaHandler = (id: number) => {
		if (area?.id !== id) {
			setInAnimation(false);
			setTimeout(() => {
				dispatch(setCurrentAreaAction(id));
				setInAnimation(true);
			}, 400);
		}
	};
	const delayUnmountAnimation = () => {
		setInAnimation(false);
		setTimeout(() => {
			dispatch(logoutAction());
		}, 400);
	};
	const closeInfo = () => {
		setShowInfo(undefined);
	};
	const closeAll = () => {
		setShowProfile(false);
		setShowChat(false);
		closeInfo();
	};
	const callUser = (ele: any) => {
		dispatch(setCallAction({ state: "video" }));
		setShowCallList(false);
		setTimeout(() => {
			sendCallAction(ele.userId, dispatch);
		}, 100);
	};
	const switchOpenTab = (ele: any) => {
		if(isGuest){
			toggleActionRegister()
		}else{
			if (ele.name === optionMenu.suitcase || ele.name === optionMenu.calendar) {
				setShowChat(false);
				setShowCallList(false);
				setShowInfo(ele.name);
			} else {
				setShowChat(false);
				setShowInfo(undefined);
				setShowCallList(!showCallList);
			}
		}
	};
	useEffect(() => {
		if (isMobile) {
			selectedView && selectedView === "chat" && toggleActionChat();
			selectedView &&
				selectedView === "calendar" &&
				switchOpenTab(calendarData);
			selectedView &&
				selectedView === "favourite" &&
				switchOpenTab(suitecaseData);
			selectedView && selectedView === "dock" && closeAll();
		}
	}, [selectedView]);
	useEffect(() => {
		showProfile && isMobile && closeInfo();
	}, [showProfile]);

	const openProfileByIdUser = (data: string | number) => {
		setShowProfile(createUserIdChat(data, event.id));
	};
	const activeOpenChat = () => {
		dispatch(activeOpenChatAction(() => setShowChat(true)));
	};
	const toggleActionChat = () => {
		if(isGuest){
			toggleActionRegister()
		} else {
			setShowCallList(false);
			setShowInfo(undefined);
			setShowChat(!showChat);
		}
	};
	const toggleActionRegister = () => {
		setShowRegister(!showRegister)
	}
	const openChatUser = (userId: string) => {
		dispatch(setOpenUserChat(userId));
	};
	const copyClipboard = () => {
		navigator.clipboard.writeText(
			// `${window.location.origin}${window.location.pathname}?area_id=${currentArea}`
			process.env.REACT_APP_URL_SHARE + `?type=area&id=${currentArea}`
		);
		dispatch(
			addSuccessMessage({
				title: t("actions.copyClipboard"),
			})
		);
	};
	const style: any = isMobile
		? {
				backgroundImage: `url("${
					area?.mobile_background
						? area?.mobile_background
						: event.login_bg_path
				}")`,
				backgroundSize: "cover",
		  }
		: {};

	return (
		<>
			<NavbarApp
				theme={area?.theme || "light"}
				logo={event.logo_path}
				setShowProfile={setShowProfile}
				changeArea={setCurrentAreaHandler}
				onLogout={delayUnmountAnimation}
				copyClipboard={copyClipboard}
			/>
			<main style={style} className="authHome-container">
				{showInfo && (
					<CardInfo
						openProfile={openProfileByIdUser}
						type={showInfo}
						changeArea={setCurrentAreaHandler}
						closeAction={() =>
							dispatch(setSelectedView("area")) && setShowInfo(undefined)
						}
					/>
				)}
				{showRegister && <AuthRegister closeHandler={toggleActionRegister}/>}
				{/*{event.login_bg_path}*/}
				<CardAction />
				{user && !isGuest && (
					<SendBirdCustom
						setShowChat={setShowChat}
						setShowProfile={setShowProfile}
						profileUrl={user.avatar}
						nickname={`${user.name} ${user.surname}`}
						showChat={showChat}
						userId={createUserIdChat(user?.id, event.id)}
						eventId={event.id}
						theme={area?.theme || "light"}
						callUserHandler={callUser}
					/>
				)}
				{!isGuest &&  <CallView />}
				{showCallList && (
					<ListUsersCall
						openChatUser={openChatUser}
						closeHandler={() => setShowCallList(!showCallList)}
						callUserHandler={callUser}
						setShowProfile={setShowProfile}
					/>
				)}
				{selectedView === "dock" && (
					<MenuAreas
						theme={area?.theme || "light"}
						setCurrentArea={setCurrentAreaHandler}
					/>
				)}
				<div
					className={inAnimation ? "fadeIn" : "fadeOut"}
					style={{ transition: "opacity 0.8s" }}>
					{area && selectedView !== "dock" && (
						<AreaHome
							area={area}
							activeChat={activeOpenChat}
							changeArea={setCurrentAreaHandler}
							activeCalendarProfile={setShowCalendarProfile}
							activeRepresentativesList={setShowRepresentativeList}
							activePosters={setShowPosters}
						/>
					)}
				</div>
				{userLogged && showProfile && (
					<Profile
						openChatUser={openChatUser}
						event={event}
						showProfile={showProfile}
						user={userLogged}
						callUserHandler={callUser}
						closeHandler={() => setShowProfile(false)}
					/>
				)}
				{showCalendarProfile && userLogged && (
					<CardAppointment
						openChatUser={openChatUser}
						eventId={event.id}
						user={userLogged}
						showProfile={showCalendarProfile}
						callUserHandler={callUser}
						closeHandler={() => setShowCalendarProfile(undefined)}
					/>
				)}
				{showPosters && (<Posters
					eventId={event.id}
					showPosters={showPosters}
					closeHandler={() => setShowPosters(undefined)}
				/>)}
				{showRepresentativeList && (
					<ListAppointment
						callUserHandler={callUser}
						openChatUser={openChatUser}
						eventId={event.id}
						setShowProfile={(id) => {
							setShowRepresentativeList(undefined);
							setShowCalendarProfile(id)
						}}
						showListAppointment={showRepresentativeList}
						closeHandler={() => setShowRepresentativeList(undefined)}
					/>
				)}
			</main>
			<MsgList />
			<Privacy />
			<Menu
				theme={area?.theme || "light"}
				setCurrentArea={setCurrentAreaHandler}
				toggleChat={toggleActionChat}
				isShowChat={showChat}
				toggleTool={switchOpenTab}
			/>
		</>
	);
}
