import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";



export interface CallState{
  call: any,
}

const initialState: CallState = {
  call : {}
};

export const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    setCall: (state, action: PayloadAction<any>) => {
      state.call =  action.payload;
    },
    resetCall: (state) => {
      state.call =  {};
    }
  },
});

export const {
  setCall,
  resetCall
} = callSlice.actions;

export const setCallAction = (call: any= {}): AppThunk => (dispatch) => {
  dispatch(setCall(call))
};

export const selectCall = (state: RootState) => state.call.call;

export default callSlice.reducer;
