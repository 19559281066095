import React from "react";
import { IMenuItem } from "../../../model/MenuModel";
import ToolItem from "./components/ToolItem";
import msg from "../../../../../../assets/img/menuTool/msg.svg";
import closemsg from "../../../../../../assets/img/menuTool/closemsg.svg";
import Notify from "./components/Notify";
import { useSelector } from "react-redux";
import { selectNotify } from "../../../../chatSlice";
import { MenuToolModel } from "./model/MenuToolModel";
import { selectExcludedFeatures } from "../../../../../../app/auth/authSlice";
import { calendarData, calluserData, suitecaseData } from "./menuData";

const MenuTool = ({
	theme,
	toggleChat,
	isShowChat,
	toggleTool,
}: MenuToolModel) => {
	const notify = useSelector(selectNotify);
	const excludedFeatures = useSelector(selectExcludedFeatures);
	const menuItemsData: IMenuItem[] = [
		suitecaseData,
		{ ...calluserData, hide: excludedFeatures?.includes("users_list") },
		calendarData,
	];
	return (
		<div className="menu-tool">
			{menuItemsData.map((ele: IMenuItem, i: number) => (
				<ToolItem
					menuItem={ele}
					theme={theme}
					key={i}
					clickElement={() => toggleTool(ele)}
				/>
			))}
			<div
				className={`center-tool ${theme} ${
					excludedFeatures?.includes("chat") ? "noIcon" : ""
				}`}>
				{!!notify && <Notify nNotify={notify} />}
				{!excludedFeatures?.includes("chat") && (
					<img src={isShowChat ? closemsg : msg} onClick={toggleChat} />
				)}
			</div>
		</div>
	);
};

export default MenuTool;
