import React, { useEffect, useRef, useState } from "react";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";

import { useDispatch, useSelector } from "react-redux";
import {
	createNewChatAction,
	initSelectedChannel,
	resetCloseSelectedChannel,
	resetOpenUserChat,
	selectChannels,
	selectMinifyChannel,
	selectOpenChatMobile,
	selectOpenUserChat,
	selectPublicChannel,
	selectRoomsChannels,
	selectSelectedChannel,
	selectStandChannels,
	setChannelPublic,
	setMinifyChannel,
	setMinifyChannelAction
} from "../../../chatSlice";
import { selectUser } from "../../../../../app/auth/authSlice";
import { selectSettingEvent } from "../../../../public/event/eventSlice";
import HeaderChat from "./HeaderChat";
import ListChat from "./ListChat";
import ChatViewCustom from "./ChatViewCustom";
import RightPanel from "./RightPanel";
import MinifyChannel from "./MinifyChannel";
import { selectIsMobile } from "../../../../../app/generalSettingsSlice";

export default function CustomSendBird({
	setShowChat,
	callUserHandler,
	showChat,
	setShowProfile,
}: {
	showChat: boolean;
	callUserHandler: (ele: any) => void;
	setShowChat: (val: boolean) => void;
	setShowProfile: (data: boolean | string) => void;
}) {
	const [inAnimation, setInAnimation] = useState<boolean>(false);
	const [show, setShow] = useState<boolean>(false);
	const dispatch = useDispatch();
	const channels = useSelector(selectChannels);
	const event = useSelector(selectSettingEvent);
	const userLogged = useSelector(selectUser);
	const roomsChannel = useSelector(selectRoomsChannels);
	const publicChannel = useSelector(selectPublicChannel);
	const standChannels = useSelector(selectStandChannels);
	const selectChannel = useSelector(selectSelectedChannel);
	const openUserChat = useSelector(selectOpenUserChat);
	const minifyChannel = useSelector(selectMinifyChannel);
	const context = useSendbirdStateContext();
	const isMobile = useSelector(selectIsMobile);
	const isOpenChatMobile = useSelector(selectOpenChatMobile);
	const sdkInstance = sendBirdSelectors.getSdk(context);

	const userLoggedBirdId = `${userLogged?.id}___${event.id}`;

	const [showUsers, setShowUsers] = useState<boolean>(false);

	const [showMembers, setShowMembers] = useState<boolean>(false);
	useEffect(() => {
		if (openUserChat) {
			setShowChat(true);
			dispatch(createNewChatAction(sdkInstance, [openUserChat]));
			dispatch(resetOpenUserChat());
		}
	}, [openUserChat]);
	useEffect(() => {
		if (showChat) {
			setShow(showChat);
			dispatch(initSelectedChannel());
			minifyChannel && dispatch(setMinifyChannel(undefined));
			setTimeout(() => {
				setInAnimation(showChat);
			}, 10);
		} else {
			setInAnimation(showChat);
			setTimeout(() => {
				dispatch(resetCloseSelectedChannel());
				setShow(showChat);
			}, 200);
		}
	}, [showChat]);
	const setPublicChannelHandler = (channel: any) => {
		dispatch(setChannelPublic(channel));
	};
	const divRef = useRef<HTMLDivElement>(null);
	const handleClickOutside = (event: MouseEvent) => {
		if (
			!isMobile &&
			!divRef.current?.contains(event?.target as any) &&
			!document
				.querySelector(".no-close-outside")
				?.contains(event?.target as any)
		) {
			setShowChat(false);
		}
	};
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	});
	const listClass = ["sendBird-app", inAnimation ? "fadeIn" : "fadeOut"];
	return (
		<>
			{minifyChannel && (
				<MinifyChannel
					reopenChat={() => setShowChat(true)}
					selectChannel={minifyChannel}
					setShowProfile={setShowProfile}
					resetMinify={() => dispatch(setMinifyChannel(undefined))}
				/>
			)}
			<div
				ref={divRef}
				className={listClass.join(" ")}
				style={{
					transition: "opacity 0.8s",
					display: !show ? "none" : undefined,
				}}>
				<div className="sendbird-app__wrap">
					<HeaderChat
						setShowChat={setShowChat}
						userLoggedBirdId={userLoggedBirdId}
						userLogged={userLogged}
						selectChannel={selectChannel}
						setShowUsers={setShowUsers}
						setShowProfile={setShowProfile}
						setShowMembers={setShowMembers}
						showMembers={showMembers}
						callUserHandler={callUserHandler}
						setMinusChat={() => {
							dispatch(setMinifyChannelAction());
							setShowChat(false);
						}}
					/>
					<div className="message-chat">
						{(!isMobile || (!isOpenChatMobile && isMobile)) && (
							<ListChat
								publicChannel={publicChannel}
								setPublicChannelHandler={setPublicChannelHandler}
								sdkInstance={sdkInstance}
								selectChannel={selectChannel}
								showUsers={showUsers}
								userLogged={userLogged}
								userLoggedBirdId={userLoggedBirdId}
								setShowUsers={setShowUsers}
								roomsChannel={roomsChannel}
								channels={channels}
								standChannels={standChannels}
							/>
						)}
						{(!isMobile || (isOpenChatMobile && isMobile)) && (
							<ChatViewCustom
								selectChannel={selectChannel}
								setShowProfile={setShowProfile}
							/>
						)}

						{showMembers && (
							<RightPanel
								listMember={selectChannel?.members}
								clickMemberHandler={(userId) => setShowProfile(userId)}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
