import SendBirdCall, {DirectCall, DirectCallOption} from "sendbird-calls";
import {resetCall, setCallAction} from "../callSlice";
export let callInstance: DirectCall;

export const initCallAction =(dispatch: any, userId: string) => {
    SendBirdCall.init(process.env.REACT_APP_SENDBIRD_ID || '')
    SendBirdCall.authenticate({userId}).then(res => {

        SendBirdCall.connectWebSocket().then(connect => {
            console.log('connesso chiamate')
        }).catch(err => console.log(err))
    })

    SendBirdCall.addListener('1', {
        onRinging: (call: DirectCall) => {
            callInstance= call
            dispatch(setCallAction({state: 'ring'}))
            callInstance.onEstablished = (call) => {
                console.log(call, 'established')
            };

            callInstance.onConnected = (call) => {
                console.log(call, 'connect')
            };

            callInstance.onEnded = (call) => {
                dispatch(resetCall())
            };

            callInstance.onRemoteAudioSettingsChanged = (call) => {
            debugger;
            };

            callInstance.onRemoteVideoSettingsChanged = (call) => {
            debugger;
            };


            // if (this.isBusy()) {
            //     call.end();
            // } else if (!call.endResult) {
            //     if (this.onRinging) {
            //         this.onRinging(call);
            //     }
            // }
        }
    });
}

export const acceptCall = () => {
    const acceptParams: {callOption: DirectCallOption} = {
        callOption: {
           localMediaView: document?.getElementById('local_video_element_id') as HTMLMediaElement,
            remoteMediaView: document?.getElementById('remote_video_element_id') as HTMLMediaElement,
            audioEnabled: true,
            videoEnabled: true
        }
    };

    callInstance && callInstance.accept(acceptParams);
}

export const declineCall = () => {
    callInstance && callInstance.end();
}

export const sendCallAction = (userId: string, dispatch: any) => {
    const dialParams: any = {
        userId,
        isVideoCall: true,
        callOption: {
            localMediaView: document.getElementById('local_video_element_id'),
            remoteMediaView: document.getElementById('remote_video_element_id'),
            audioEnabled: true,
            videoEnabled: true
        }
    };


   SendBirdCall.dial(dialParams, (call, error) => {
        if (error) {
            debugger;
            // Dialing failed.
        }
        if(call){
           callInstance = call;
            callInstance.onEstablished = (call) => {
                console.log(call, 'established')
            };

            callInstance.onConnected = (call) => {
                console.log(call, 'connect')
            };

            callInstance.onEnded = (call) => {
                dispatch(resetCall())
            };

            callInstance.onRemoteAudioSettingsChanged = (call) => {
                debugger;
            };

            callInstance.onRemoteVideoSettingsChanged = (call) => {
                debugger;
            };
       }

        // Dialing succeeded.
    });
}
