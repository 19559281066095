import React, { useState } from "react";
import Icon from "./../../../../components/shared/icon/Icon";

const ItemAccordion = ({
   children
}: {
	children: React.ReactNode;
}) => {
	const [fullHeight, setFullHeight] = useState(false);

	return (
			<>
				<div
					className={`text ${fullHeight? 'open': 'closed'}`}>
					{children}
				</div>

					<Icon
						icon="expand-down"
						size="8px"
						className={`expand ${
							!fullHeight ? "" : "rotateIcon"
						}`}
						clickAction={() => setFullHeight(!fullHeight)}
					/>
			</>


	);
};

export default ItemAccordion;
