import React, { useEffect } from "react";
import { date_ggmmyyyy_hhmm } from "../../../../../components/shared/utils/date";
import { addFavouriteLive } from "../../sections/favorites/favoriteAPI";
import iconBag from "./../../../../../assets/img/icon/bag.svg";
import iconQna from "./../../../../../assets/img/menuTool/msg.svg";
import { Col, Row } from "react-bootstrap";
import BackBtn from "../../../../../components/shared/backBtn/BackBtn";
import { saveLogAction } from "../../../../../app/auth/authSlice";
import { useDispatch } from "react-redux";
import { IframeResizeDevelopment } from "../iframe/IframeResizeDevelopment";

export function LiveCardActionType({
	data,
	goBack,
	openQna,
}: {
	data: any;
	goBack?: () => void;
	openQna?: () => void;
}) {
	const live = data.module.live;
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(saveLogAction({ id: live.id, type: "App\\Models\\Live" }));
	}, []);
	const setFavourite = () => {
		addFavouriteLive(live.id).then((res) => console.log(res));
	};
	return (
		<div className="live-content h-100">
			{goBack && (
				<Row className="justify-content-end">
					<Col xs="auto">
						<BackBtn onClick={goBack} />
					</Col>
				</Row>
			)}
			<div className="d-flex">
				<h1>
					<span className="mr-3">{live?.name}</span>|
					<span className="ml-3">
						{live?.start && (
							<>
								<span className="mr-3">
									Inizio {date_ggmmyyyy_hhmm(live?.start)}
								</span>{" "}
								-
							</>
						)}
						{live?.end && (
							<span className="ml-3">Fine {date_ggmmyyyy_hhmm(live?.end)}</span>
						)}
					</span>
				</h1>
				{!goBack && (
					<div className="ml-auto d-flex align-items-center">
						{!!data?.module.live.qa && (
							<span className="pointer mr-3" onClick={openQna}>
								<div className="qna-icon">
									<span className="primary">?</span>
								</div>
							</span>
						)}

						<span className="pointer" onClick={setFavourite}>
							<img src={iconBag} width="20" />
						</span>
					</div>
				)}
			</div>
			<h2>{live?.description}</h2>
			<div
				style={{
					width: "100%",
					height: "calc(100% - 45px)",
				}}>
				<IframeResizeDevelopment
					path={live.join_url}
					development={live.development}
				/>
				{/*<iframe*/}
				{/*	allow="camera; microphone; fullscreen"*/}
				{/*	src={live.join_url}*/}
				{/*	width="100%"*/}
				{/*	height="100%"*/}
				{/*	></iframe>*/}
			</div>
		</div>
	);
}
