import {store} from "../app/store";
import {destroyAfterLogout} from "../app/auth/authSlice";
const status401Interceptor =  (err: any) => {
    const dispatch = store.dispatch;
    if(err.response && err.response.status === 401){
        console.log('processo di logout');
        dispatch(destroyAfterLogout())
    }
    return Promise.reject(err);
}

export default status401Interceptor;
