import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../../../components/shared/design/form/buttons/button/Button";
import FormElements from "../../../../../components/shared/utils/forms/FormElements";
import { FormElementModelNew } from "../../../../../components/shared/utils/forms/models/FormElementModel";
import { createStructureForm } from "../../../../../components/shared/utils/forms/useStructureForm";
import { useTranslation } from "react-i18next";

const SearchRow = ({
	form,
	refreshSearch,
}: {
	form: any;
	refreshSearch: () => void;
}) => {
	const { t } = useTranslation();
	const structureForm: FormElementModelNew[] = createStructureForm([
		{
			typeElement: "input",
			label: t("card.userCall.search"),
			name: "search",
			dataElement: {
				type: "text",
				display: "inlineForm",
				classNameGroup: "inputBordered",
				buttonIcon: "search",
			},
			col: { xs: "12", md: "6" },
		},
		{
			typeElement: "select",
			label: t("card.userCall.sort"),
			name: "sort",
			options: [
				{ label: "Nome", value: "name" },
				{ label: "Cognome", value: "surname" },
			],
			dataElement: {
				display: "inlineForm",
				menuPlacement: "bottom",
				className: "inputBordered",
			},
			col: { xs: "12", md: "6" },
		},
	]);

	return (
		<div className="searchRow">
			<Row className="w-100">
				<Col xs="12" md="6">
					<FormElements
						data={structureForm}
						errors={form.errors}
						isSubmit={false}
						dataForm={form.dataForm}
						changeValue={form.changeValue}
					/>
				</Col>
				<Col xs="12" md="6">
					<Button className="btn-md mr-3 accent">
						{t("card.userCall.advancedSearch")}
					</Button>
					<Button className="btn-md complement" onClick={refreshSearch}>
						{t("card.userCall.refresh")}
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default SearchRow;
