import React from "react";
import { mergeClass } from "../../../../functions/function";
import { ButtonProps as BtnProps } from "react-bootstrap/Button";
import Button from "../button/Button";

interface ButtonProps extends BtnProps {
  form?: string;
}
const PrimaryButton = ({
  children,
  className,
  variant = "primary",
  type = "submit",
  form,
  ...rest
}: ButtonProps) => {
  return (
    <Button
      type={type}
      form={form}
      variant={variant}
      className={mergeClass("sh-primary", className)}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
