import {createStructureForm} from "../../../components/shared/utils/forms/useStructureForm";
import {FormElementModelNew} from "../../../components/shared/utils/forms/models/FormElementModel";
import {TFunction} from "i18next";

export const firstStepStructure: (t: TFunction) => FormElementModelNew[] = (t) => (createStructureForm([
    {
        typeElement: "input",
        label: "Email *",
        name: "email",
        dataElement: {
            type: "text",
        },
    },
    {
        typeElement: "input",
        label: "Password *",
        subLabel: t("registration.password_message"),
        name: "password",
        dataElement: {
            type: "password",
        },
    },
    {
        typeElement: "input",
        label: t("registration.password_confirmation") + '*',
        name: "password_confirmation",
        dataElement: {
            type: "password",
        },
    },
]));


export const firstStepValidation = (t: TFunction) => ({
    email: {
        required: {
            params: { name: "email" },
        },
        email: {
            params: { name: "email" },
        }
    },
    password: {
        required: {
            params: { name: "password" },
        },
        minLength: {
            params: { name: "password", min: 8 },
            moreData: {
                min: 8
            }
        },
        upperCharacter: {
            params: { name: "password" }
        },
        oneNumber: {
            params: { name: "password" }
        }
    },
    password_confirmation: {
        required: {
            params: { name: t("registration.password_confirmation") },
        },
        confirm: {
            moreData: {
                fieldCompare: "password",
            },
            params: { name: "password", fieldCompare: t("registration.password_confirmation") },
        },
    }
})
