import {firstStepStructure, firstStepValidation} from "./registerData";
import {StepModel} from "../../../models/Step";
import {Profile} from "../../../models/Profile";
import {TFunction} from "i18next";

export function useRegister(t: TFunction, profiles: Profile[] = [], steps: StepModel[]){
	const firstStep: StepModel = {formStructure: firstStepStructure(t), validations: firstStepValidation(t)}
	const fixedStep: StepModel = {
		formStructure: [{
			typeElement: "select",
			label: t("registration.role"),
			name: "profile_id",
			options: profiles.map(ele => ({value: ele.id.toString(), label: ele.name}))
		}],
		validations: {
			profile_id:{
				required: {
					params: {name: t("registration.profile")}
				}
			}
		},
		type: "check"
	}
	return {
		allStep : [firstStep, fixedStep, ...steps]
	}
}
