import React, { useEffect } from "react";
import ChatViewCustom from "./ChatViewCustom";
import Icon from "../../../../../components/shared/icon/Icon";
let timeout: any;
export default function MinifyChannel({
	selectChannel,
	setShowProfile,
	resetMinify,
  	reopenChat
}: {
	selectChannel: any;
	setShowProfile: (data: boolean | string) => void;
	resetMinify: () => void;
	reopenChat: () => void;
}) {
	const calcHeight = () => {
		const height = document.querySelector(".leftAlign")?.clientHeight || 0;
		const height2 =
			document.querySelector(".leftAlign .sendbird-app__conversation-wrap")
				?.clientHeight || 0;
		if (height2 > height) {
			(
				document.querySelector(
					".leftAlign .sendbird-app__conversation-wrap"
				) as any
			).style.height = height - 36 + "px";
		}
		timeout = setTimeout(() => {
			calcHeight();
		}, 200);
	};
	useEffect(() => {
		calcHeight();
		return () => {
			timeout && clearTimeout(timeout);
		};
	}, []);
	return (
		<div className="leftAlign">
			<div className="header-mini-chat">
				<Icon icon="maximize" clickAction={reopenChat} />
				<Icon icon="close" clickAction={resetMinify} />
			</div>
			<ChatViewCustom
				selectChannel={selectChannel}
				setShowProfile={setShowProfile}
			/>
		</div>
	);
}
