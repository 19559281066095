import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatUser } from "../../../../app/auth/authUtils";
import { User } from "../../../../app/auth/model/User";
import { selectIsMobile } from "../../../../app/generalSettingsSlice";
import CardDetail from "../../../../components/shared/design/card-detail/CardDetail";
import http from "../../../../components/shared/utils/http";
import CalendarRow from "./components/CalendarRow";
import ProfileDetail from "./components/ProfileDetail";
import {
	addCalendarMeetingCall,
	getCalendarMeetingCall,
} from "../sections/calendar/calendarAPI";
import PrimaryButton from "../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import ProfileSummary from "../sendbird/components/profile/ProfileSummary";
import { calcVh } from "../../../../components/shared/utils/vhUtils";

const CardAppointmentProfile = ({
	isMobile,
	fullHeighBox,
	profile,
	showProfile,
	closeHandler,
	callUserHandler,
	openChatUser,
	setFullHeightBox,
	calendarEvents,
	setModalActionInfo,
	calendarStatus	

}: {
	isMobile: boolean,
	fullHeighBox: "closed" | "open",
	profile: any,
	showProfile: string,
	closeHandler: () => void;
	callUserHandler: (data: any) => void;
	openChatUser: (userId: string) => void;
	setFullHeightBox: (data: "closed" | "open" ) => void;
	calendarEvents: any[];
	setModalActionInfo: (data: any) => void;
	calendarStatus: boolean;
}) => {
	const { t } = useTranslation();
	const top = calendarStatus ? `${isMobile ? "88px" : "88px"}` : "50%";
	const transform = calendarStatus ? "translateX(-50%)" : "translate(-50%, -50%)";
	const height = calendarStatus ? `calc( ${calcVh(100)} - ${isMobile ? "162px" : "190px"})` : `${isMobile
		? `calc( ${calcVh(100)} - 140px )`
		: `auto`
		}`;
	const margin = calendarStatus ? "auto" : `${isMobile
		? `18px auto`
		: `auto`
		}`
	const title = calendarStatus ? "appointment.title" : "profile.title"
	return (
		<div className="no-close-outside">		
			<CardDetail
				style={{
					position: "fixed",
					top,
					left: "50%",
					transform,
					height,
					width: "100%",
					maxWidth: "1100px",
					margin
				}}
				headerTitle={`${t(title)}`}
				closeHandler={closeHandler}>
				<div className="d-flex flex-column h-100">
					<div className={fullHeighBox === "open" ? "h-100" : ""}>
						<ProfileDetail
							profile={profile}
							showProfile={showProfile}
							closeHandler={closeHandler}
							fullHeighBox={fullHeighBox}
							setFullHeightBox={setFullHeightBox}
							callUserHandler={callUserHandler}
							openChatUser={openChatUser}
						/>
					</div>

					{(fullHeighBox === "closed" && calendarStatus) &&(			
						<div 
							className="h-100"
							style={{ marginTop: `${isMobile ? "12px" : "24px"}`}}>
							<CalendarRow
								events={calendarEvents}
								setModalActionInfo={setModalActionInfo}
							/>
						</div>
					)}
				</div>
			</CardDetail>
		</div>
	);
};

export default CardAppointmentProfile;
