import React from "react";
import { mergeClass, classByArray } from "../../../functions/function";
import { FormControl } from "react-bootstrap";
import MultiSelectCustom from "./components/MultiSelectCustom";
import IconAction from "./components/IconAction";
import { selectType } from "../../../utils/forms/models/FormElementModel";
import SelectReact from "react-select";

const Select: React.FC<selectType> = ({
	children,
	className,
	options = [],
	iconAction,
	customSelect,
	error = false,
	multiply = false,
	changeSelect = () => {},
	value,
	placeholder,
	disabled,
	clickAction = () => {},
	menuPlacement,
	...rest
}) => {
	const classMerge: string = mergeClass(
		"shared-select",
		classByArray([error ? " error" : "", customSelect ? "customSelect" : ""])
	);
	return (
		<div style={{ width: "100%" }}>
			{multiply ? (
				<MultiSelectCustom
					disabled={disabled}
					options={options}
					value={value}
					error={error}
					{...rest}
					changeSelect={changeSelect}
				/>
			) : (
				<>
					<div className="d-flex">
						<SelectReact
							className={`shared-select-react w-100 ${className}`}
							placeholder={placeholder || "Seleziona"}
							value={options.find((ele) => ele.value === value) || null}
							options={options}
							menuPlacement={menuPlacement}
							onChange={(data) => changeSelect(data?.value)}></SelectReact>
						{/*<FormControl*/}
						{/*  as="select"*/}
						{/*  value={value !== undefined && value !== "-1" ? value : "-1"}*/}
						{/*  disabled={disabled}*/}
						{/*  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>*/}
						{/*    changeSelect(*/}
						{/*      event.target.value !== "-1" ? event.target.value : undefined*/}
						{/*    )*/}
						{/*  }*/}
						{/*  className={mergeClass(classMerge, className)}*/}
						{/*  {...rest}*/}
						{/*>*/}
						{/*  {options.map((opt, index) => (*/}
						{/*    <option key={opt.value} value={opt.value}>*/}
						{/*      {opt.label}*/}
						{/*    </option>*/}
						{/*  ))}*/}
						{/*</FormControl>*/}
						{iconAction && <IconAction onClick={clickAction}></IconAction>}
					</div>
				</>
			)}
		</div>
	);
};

export default Select;
