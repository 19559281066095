import React from "react";
import Icon from "../../../../../components/shared/icon/Icon";
import { User } from "../../../../../app/auth/model/User";
import { useDispatch, useSelector } from "react-redux";
import { selectIsMobile } from "../../../../../app/generalSettingsSlice";
import { selectOpenChatMobile, setOpenChatMobile } from "../../../chatSlice";
import {selectExcludedFeatures} from "../../../../../app/auth/authSlice";

export default function HeaderChat({
	setShowUsers,
	setShowProfile,
	setShowChat,
	setShowMembers,
	setMinusChat,
	selectChannel,
	userLogged,
	userLoggedBirdId,
	showMembers,
	callUserHandler,
}: {
	userLoggedBirdId: string;
	userLogged?: User;
	selectChannel: any;
	setShowUsers: (data: boolean) => void;
	setMinusChat: () => void;
	setShowProfile: (data: boolean) => void;
	setShowMembers: (data: boolean) => void;
	setShowChat: (data: boolean) => void;
	showMembers: boolean;
	callUserHandler: (ele: any) => void;
}) {
	const dispatch = useDispatch();
	const isMobile = useSelector(selectIsMobile);
	const isOpenChatMobile = useSelector(selectOpenChatMobile);
	const excludedFeatures = useSelector(selectExcludedFeatures);

	const channelNameWhitUser =
		selectChannel &&
		selectChannel.members &&
		selectChannel.members
			.filter((ele: any) => ele.userId.toString() !== userLoggedBirdId)
			.map((c: any) => c.nickname || c.userId);
	return (
		<div className="header-chat ">
			<h4 className="">
				<div className="img" onClick={() => setShowProfile(true)}>
					<img src={userLogged?.avatar} alt="" />
				</div>
			</h4>
			<div style={{ flexBasis: "auto", flexGrow: 1 }}>
				{selectChannel && selectChannel.url && (
					<div className="title-chat">
						{selectChannel.type === "public" ? (
							selectChannel.name
						) : (
							<>
								<span>
									{`tu, ${channelNameWhitUser.slice(0, 3).join(", ")} `}
								</span>
								<sup>
									<b className="mb-3">
										{channelNameWhitUser.length - 3 > 0
											? `+${channelNameWhitUser.length - 3}`
											: ""}
									</b>
								</sup>
							</>
						)}
					</div>
				)}
			</div>
			<div className="d-flex pr-2">
				{(!isMobile || (!isOpenChatMobile && isMobile)) && !excludedFeatures?.includes("users_list") && (
					<Icon
						className="pr-2"
						icon="add_user_chat"
						clickAction={() => setShowUsers(true)}
					/>
				)}
				{!isMobile && (
					<Icon
						className="pr-2"
						icon="minus-under bordered"
						clickAction={setMinusChat}
					/>
				)}
				{selectChannel &&
					selectChannel?.type === "private" &&
					selectChannel.members.length === 2 && (
						<Icon
							className="pr-2"
							icon="video bordered"
							clickAction={() =>
								callUserHandler(
									selectChannel.members.find(
										(ele: any) => ele.userId !== userLoggedBirdId
									)
								)
							}
						/>
					)}
				{selectChannel && selectChannel?.type !== "public" && !isMobile && (
					<Icon
						className="pr-2"
						icon="info"
						clickAction={() => setShowMembers(!showMembers)}
					/>
				)}
				{(!isMobile || (isOpenChatMobile && isMobile)) && (
					<Icon
						icon="close bordered"
						className="pr-2"
						clickAction={() =>
							isMobile ? dispatch(setOpenChatMobile(false)) : setShowChat(false)
						}
					/>
				)}
			</div>
		</div>
	);
}
