import React, {useEffect, useState} from "react";
import { Carousel } from "react-bootstrap";
import { GridCardActionTypeImage } from "../../../../features/auth/authHome/cardActionTypes/media/GridCardActionTypeImage";
import Icon from "../../../icon/Icon";
let stop: boolean= false;
let timeout: any;
const ControlledCarousel = ({
	slides,
	interval,
}: {
	slides: any[];
	interval: number | null;
}) => {
	const [index, setIndex] = useState(0);
	const [auto, setAuto] = useState(true);

	const handleSelect = (selectedIndex: number, e: any) => {
		setIndex(selectedIndex);
	};
	const moreElements: boolean = slides.length && slides.length > 1 && slides[0];
	const setNextStep = () => {
		if(!stop){
			setAuto(true)
			setIndex(index+1)
		}
	}
	useEffect(() => {
		if(slides.length >= 1){
				stop = false;
				timeout && clearTimeout(timeout);
				timeout = setTimeout(() => {
					index < slides.length ? setNextStep(): setIndex(0)
				}, index < slides.length ? 5000 : 1000)
		}
	}, [index])
	const typeContents: any = (data: any) => {
		return {
			video: <video src={data.path} controls></video>,
			image: (
				<GridCardActionTypeImage
					path={data.path}
					onClick={() => null}
					className="pointer"
				/>
			),
			url: <iframe src={data.path}></iframe>,
		};
	};
	const tot: number = moreElements ?  slides.length +1 : slides.length;
	const prevByButton = () => {
		if(index > 0){
			setAuto(false)
			setIndex(index - 1)
		}
	}
	const nextByButton = () => {
		if(index < (slides.length -1)){
			setAuto(false)
			setIndex(index + 1)
		}
	}
	return (
		<div className="carousel-shared">

			<div className={["prev-slide", (index > 0 ? 'enabled' : 'disabled')].join(' ')}
				 onClick={prevByButton}>
				<Icon icon="next-left" />
			</div>
			<div className="slideshow">
				<div
					className="slideshowSlider"
					style={{
						transition: (index > 0 || !auto) ? "ease 1000ms" : undefined,
						transform: `translateX(${-index * (100 / tot)}%)`,
						width: `${tot * 100}%`,
					}}>
					{slides.map((content, index) => (
						<div className="slide" key={index} onClick={() => stop = true}>
							{typeContents(content)[content.type]}
						</div>
					))}
					{moreElements && <div className="slide">
						{typeContents(slides[0])[slides[0].type]}
					</div>}
				</div>
			</div>
			<div className={["next-slide", (index < (slides.length -1) ? 'enabled' : 'disabled')].join(' ')}
				 onClick={nextByButton}>
				<Icon icon="next-right" />
			</div>
		</div>
	);
};

export default ControlledCarousel;
