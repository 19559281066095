import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {saveLogAction} from "../../../../../app/auth/authSlice";
import {IframeCustom} from "./IframeCustom";

export function IframeCardActionType({ data }: { data: any }) {
	const contents = data?.module?.contents || [];
	const dispatch = useDispatch();

	useEffect(() => {
		contents && contents.length && dispatch(saveLogAction({id: contents[0].id, type: "App\\Models\\Content"}))
	}, [contents])
	// [contents?.find((ele: any) => ele?.path?.indexOf('youtube.com') !== -1) ? 'maxHeight' : 'height']: "100%",

	return (
		<div className="h-100">
			{contents.map((content: any) => {
				return (
					<IframeCustom content={content} />
				);
			})}
		</div>
	);
}
