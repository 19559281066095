import React from "react";
import { mergeClass } from "../../../functions/function";
import { LabelProps } from "../models/FormModule";

const Label = ({ label, children, className, ...rest }: LabelProps) => {
  return (
    <label className={mergeClass("shared-label", className)} {...rest} dangerouslySetInnerHTML={{__html: label}}>
    </label>
  );
};

export default Label;
