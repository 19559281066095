export const dateformat_ggMMMyyyy = (date: string | number) =>
	new Date(date).toLocaleString("it-IT", {
		day: "2-digit",
		month: "short",
		year: "numeric",
	});

export const localdateString = (date: string | number) => {
	return new Date(date).toLocaleDateString("it-IT");
};

export const diffDate = (date1: any, date2: any) => {
	const d1 = new Date(date1).getTime();
	const d2 = new Date(date2).getTime();
	return  d1 - d2;
}

export const calcDiffDateToday = (date: any) => {
	return  diffDate(date, new Date());
}

export const calcDiffDaysHoursMinutesToday = (date: any) => {
	const distance = calcDiffDateToday(date);
	return {
		days: Math.floor(distance / (1000 * 60 * 60 * 24)),
		hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
		minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
	}
}

export const date_ggmmyyyy_hhmm = (date: string | number) => {
	const dateNow = new Date(date);
	return (
		(dateNow.getDate() < 10 ? "0" : "") +
		dateNow.getDate() +
		"/" +
		(dateNow.getMonth() + 1 < 10 ? "0" : "") +
		(dateNow.getMonth() + 1) +
		"/" +
		dateNow.getFullYear() +
		" " +
		dateNow.getHours() +
		":" +
		(dateNow.getMinutes() < 10 ? "0" : "") +
		dateNow.getMinutes()
	);
};
