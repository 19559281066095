import {arrayObjSort} from "../../../../../components/shared/utils/sortArray";

export function useFilterUsers(users: any[], search?: string, sort: string = 'name'){
    const searchInclude = (string: string, search: string) => {
        return string.toLowerCase().trim().includes(search.toLowerCase().trim())
    }
    const filterUsers = arrayObjSort(users.filter(
        (ele) =>
            !search ||
            searchInclude(`${ele.name} ${ele.surname}`, search) ||
            searchInclude(`${ele.surname} ${ele.name}`, search)
    ), sort);
    return {
        filterUsers
    }
}
