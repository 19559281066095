import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import BackBtn from "../../../../../components/shared/backBtn/BackBtn";
import ItemContentsListCardActionType from "../ItemContentsListCardActionType";
import { addFavouriteArticle } from "../../sections/favorites/favoriteAPI";
import {useTranslation} from "react-i18next";

export function ArticlegridCardActionType({
	articles,
	copyClipboard,
	removefavorite,
}: {
	articles: any;
	copyClipboard: () => void;
	removefavorite?: (content: any) => void;
}) {
	const {t} = useTranslation();
	const [fullScreenItem, setFullScreen] = useState<any>(undefined);

	const setFavourite = (content: any) => {
		addFavouriteArticle(content.id).then((res) => console.log(res));
	};

	const setFullScreenHandler = (fullScreenItemId: string | number | undefined) => {
		setFullScreen(articles.filter((ele: any) => ele.id === fullScreenItemId))
	}
	return (
		<>
			{articles?.length>0 ? <div
				className="container-fluid"
				style={{ height: "calc(100% - 50px)", overflowY: "auto", WebkitOverflowScrolling:'touch'}}>
				{fullScreenItem && (
					<Row className="justify-content-end">
						<Col xs="auto">
							<BackBtn
								onClick={() => {
									setFullScreen(undefined);
								}}
							/>
						</Col>
					</Row>
				)}
				{(fullScreenItem? fullScreenItem : articles)?.map((article: any) => {
					return (
						<ItemContentsListCardActionType
							setFavourite={setFavourite}
							removefavorite={removefavorite}
							content={{
								title: article.title,
								html: article.content,
								id: article.id,
							}}
							isFullScreen={!!fullScreenItem}
							setPathShow={setFullScreenHandler}
							copyClipboard={copyClipboard}
						/>
					);
				})}
			</div> : <div>{t('no_content')}</div>}

		</>

	);
}
