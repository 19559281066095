import React from "react";
import { useDispatch } from "react-redux";
import Icon from "../../../../../components/shared/icon/Icon";
import ChannelPreview from "./ChannelPreview";
import {useTranslation} from "react-i18next";

export default function PersonalRooms({
	channels,
	userLogged,
	setChannelPrivate,
	selectChannel,
	chatActive,
	setChatActive,
}: {
	channels: any[];
	userLogged: any;
	setChannelPrivate: (channel: any) => void;
	selectChannel?: any;
	chatActive: string | undefined;
	setChatActive: (data: string | undefined) => void;
}) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	return (
		<>
			<div className="rooms">
				<h5>{t('chat.chatPersonal')}</h5>
				<div
					className="content-item"
					style={{
						maxHeight: !chatActive || chatActive === "personal" ? "2000px" : "0px",
					}}>
					{channels &&
						channels.map((ele) => (
							<ChannelPreview
								setChannel={(channel) =>
									dispatch(setChannelPrivate(channel))
								}
								key={ele.url}
								channel={ele}
								userLogged={userLogged}
								isActive={!!selectChannel && selectChannel.url === ele.url}
							/>
						))}
				</div>
				{!chatActive|| chatActive !== "personal" ? (
					<Icon icon="down" clickAction={() => setChatActive("personal")} />
				) : (
					<Icon
						icon="down"
						style={{ transform: "rotate(180deg)" }}
						clickAction={() => setChatActive(undefined)}
					/>
				)}
			</div>
		</>
	);
}
