import React, { useRef, useState } from "react";

interface IGridCardImageModel extends React.HtmlHTMLAttributes<HTMLDivElement> {
	path: string;
}
export function GridCardActionTypeVideo({
	path,
	...rest
}: IGridCardImageModel) {
	return (
		<div className="pointer d-flex" style={{ position: "relative" }} {...rest}>
			<div className="w-100 h-100" style={{ position: "absolute" }}></div>
			<video src={path} controls></video>
		</div>
	);
}
