import React, {useEffect} from "react";
import {saveLogAction} from "../../../../../app/auth/authSlice";
import {useDispatch} from "react-redux";

export function ArticleCardActionType({data}:{data:any}) {
	const articles = data?.module?.articles || [];
	const dispatch = useDispatch();
	useEffect(() => {
		articles && articles.length && dispatch(saveLogAction({id: articles[0].id, type: "App\\Models\\Article"}))
	}, [articles])
	return (
		<div>
			{articles.map((article:any) => {
				
				return <div className="article-list">
					<h1>{article.title}</h1>
					<div className="text" dangerouslySetInnerHTML={{__html: article.content}} style={{ }}></div>
				</div>
			})}
		</div>
	);
}
