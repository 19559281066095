import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectSettingEvent} from "../../../../public/event/eventSlice";
import http from "../../../../../components/shared/utils/http";
import { LiveCardActionType } from "../live/LiveCardActionType";

export function SelectedLive({
	data,
	goBack
}: {
	data: any;
	goBack: () => void
}) {
	const event = useSelector(selectSettingEvent)
	const [live, setLive] = useState<any>()
	useEffect(() => {
		http.get(`events/${event.id}/modules/${data.id}`).then(res => setLive(res))
	}, [])
	return (
		<>
			{live && <LiveCardActionType data={live} goBack={goBack} />}
		</>
	);
}
