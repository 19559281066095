import React from "react";
import {  useSelector } from "react-redux";
import {selectIsMobile} from "../../../../app/generalSettingsSlice";
import {MenuModel} from "./model/MenuModel";
import {MenuDesk} from "./desktop/MenuDesk";
import {MenuMobile} from "./mobile/MenuMobile";

export function Menu({setCurrentArea, toggleChat, theme, isShowChat, toggleTool} : MenuModel) {
	const isMobile = useSelector(selectIsMobile);
	return (
		<>
			{
				isMobile? <MenuMobile  theme={theme} /> : <MenuDesk theme={theme} setCurrentArea={setCurrentArea} toggleChat={toggleChat} isShowChat={isShowChat} toggleTool={toggleTool} />
			}
		</>
	);
}
