export default {
	name: "nome",
	surname: "cognome",
	no_content: "Nessun Contenuto",
	login: {
		register: "Registrati",
		login: "Login",
		alredyRegistered: "Gia registrato?",
		alreadyAccount: "Possiedi già un account?",
		signIn: "Accedi",
	},
	registration: {
		role: "Ruolo *",
		profile: "Profilo",
		password_confirmation: "Conferma Password",
		password_message:
			"La password deve rispettare i seguenti requisiti: almeno 8 caratteri, almeno una lettera maiuscola, almeno un numero.",
	},
	chat: {
		participants: "Partecipanti",
		chatPersonal: "Chat personali",
	},
	profile: {
		title: "Profilo",
		updateImgProfile: "Aggiorna immagine profilo",
	},
	appointment: {
		title: "Appuntamenti",
		titleList: "Lista rappresentanti",
	},
	posters: {
		title: "Poster",
		addModule: "Aggiungi modulo",
		createdBy: "Creato da",
		on: "il",
		form: {
			title: "Titolo",
			subheading: "Sottotitolo",
			abstract: "Testo",
			file_path: "File",
		},
	},
	card: {
		title: "Titolo",
		auth: {
			register: {
				title: 'Registrati per accedere al servizio'
			}
		},
		calendar: {
			event: "Calendario evento",
			personal: "Calendario personale",
		},
		favorites: {
			title: "Preferiti",
			tabs: {
				favourite_articles: "Articoli",
				favourite_contents: "File",
				favourite_media: "Contenuti",
				favourite_lives: "Lives",
			},
		},
		userCall: {
			title: "Lista Utenti",
			call: "Videochiama ",
			contact: "Contatta ",
			loading: "Caricamento in corso",
			noUser: "Nessun utente in lista",
			search: "cerca",
			sort: "ordina",
			advancedSearch: "Ricerca avanzata",
			refresh: "Resetta",
		},
		qna: {
			title: 'Live domande e risposte',
			question: "Domanda",
			questionSended: "Domanda inviata",
		},
	},
	actions: {
		copyClipboard: "URL contenuto copiato",
		cancel: "Annulla",
		save: "Salva",
		uploadFile: "Carica file",
		send: "Invia",
	},
	errors: {
		fe: {
			required: "Il campo {{name}} è obbligatorio",
			email: "Il campo {{name}} non contiene un'e-mail valida",
			confirm: "Il campo {{fieldCompare}} deve essere uguale al campo {{name}}",
			minLength: "Il campo {{name}} deve essere almeno di {{min}} caratteri",
			regex: "Il campo {{name}} deve rispettare la seguente regex: {{regex}}",
			length: "Il campo {{name}} deve essere di {{moreData.length}} caratteri",
			isNumber: "Il campo {{name}} deve essere un numero",
			upperCharacter:
				"Il campo {{name}} deve avere almeno una lettera maiuscola",
			oneNumber: "Il campo {{name}} deve contenere almeno un numero",
			specialCharacter:
				"Il campo {{name}} deve contenere almeno un carattere speciale tra @#!~$%^&*()-+/:.,<>?|",
		},
	},
};
