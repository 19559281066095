import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  MessageInitialState,
  MessageModelState,
  MessageState
} from "./MessageModelState";
import {AppThunk} from "../store";
import {messageBroadcast} from "./messageAPI";

let interval: any;

const initialState: MessageInitialState = {
  messages: []
}

export const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessages: (state, action: PayloadAction<MessageState[]>) => {
      state.messages = [...state.messages.filter(ele => action.payload.find(el => ele.opening_time !== el.opening_time)), ...action.payload]
    },
    removeMessage: (state, action: PayloadAction<MessageState>) => {
      state.messages = state.messages.filter(ele =>  ele.opening_time !== action.payload.id)
    },
    resetMessages: (state) => {
      state.messages = []
    }
  }
})

export const {
  addMessages,
  removeMessage,
  resetMessages
} = messageSlice.actions



export const initMessageBroadcast = (): AppThunk => (dispatch, getState) => {
  dispatch(callMessagesList());

  interval && clearInterval(interval);
  interval = setInterval(() => {
    dispatch(callMessagesList())
  }, 60000)

}

export const callMessagesList = (): AppThunk => (dispatch, getState) => {
    const eventId = getState().event.settings?.id;
    const userId = getState().auth.user?.id
    eventId && userId && dispatch(addMessageBroadcast(eventId))
}

export const addMessageBroadcast = (eventId: string | number): AppThunk => (dispatch, getState) => {
  messageBroadcast(eventId).then(res => {
    if(res.broadcast_messages && res.broadcast_messages.length){
      dispatch(addMessages(res.broadcast_messages));
    }
  })
}

export const selectMessages = (state: MessageModelState) => state.messages.messages

export default messageSlice.reducer
