export const inputTranslate: {[key:string]: any} = {
    text: {
        typeElement: 'input',
        dataElement: {
            type: 'text'
        }
    },
    file: {
        typeElement: 'file',
        dataElement: {
            type: 'file'
        }
    },
    select: {
        typeElement: 'select',
        dataElement: { }
    },
    checkbox: {
        typeElement: 'switch',
        dataElement: {
            type: "checkbox",
            display: "inlineFormReverse"
        }
    }
}


export const findTypeInput = (type: string) : any => {
    return inputTranslate[type] || inputTranslate.text
}
