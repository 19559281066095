import React from "react";
import { ITheme } from "../../../../model/AreaModel";
import { IMenuItem } from "../../../../model/MenuModel";

const ToolItem = ({
	menuItem,
	theme,
	clickElement,
	noIcon,
}: {
	menuItem: IMenuItem;
	theme: ITheme;
	clickElement?: () => void;
	noIcon?: boolean;
}) => {
	return (
		<div
			className={`tool-item ${theme} ${menuItem.hide ? "noIcon" : ""}`}
			onClick={() => !menuItem.hide && clickElement && clickElement()}>
			{!menuItem.hide && (
				<>
					<img src={menuItem.icon[theme]} width="42" height="42" />
					<span>{menuItem.label}</span>
				</>
			)}
		</div>
	);
};

export default ToolItem;
