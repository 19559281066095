import React from "react";
import { ITheme } from "../../../../model/AreaModel";
import { IMenuItem } from "../../../../model/MenuModel";

const MenuItem = ({
	menuItem,
	theme,
	clickVoice
}: {
	menuItem: IMenuItem;
	theme: ITheme;
	clickVoice: () => void
}) => {
	return (
		<div className="menu-item" onClick={clickVoice}>
			<img src={menuItem.icon[theme]} width="32" height="32"/>
			<span>{menuItem.label}</span>
		</div>
	);
};

export default MenuItem;
