import React, { useRef, useState} from "react";
import {AreaSingleMap} from "./AreaSingleMap";
import {AreaSubComponentModel} from "../model/AreaComponentModel";


export function AreaDesktop({ area, clickArea }: AreaSubComponentModel) {
	const imgRef = useRef<HTMLImageElement>(null);
	const [loadImg, setLoadImg] = useState<boolean>(false)
	return <>{
		area &&
			<>
				<div className="area-container">
					<img ref={imgRef} src={area.bg_path} alt="" onLoad={() => setLoadImg(true)} useMap="#areaMap"/>
					{loadImg && <map name="areaMap">
						{area.bg_json?.spots?.map(ar => <AreaSingleMap clickArea={clickArea} key={ar.id} imgRef={imgRef} ar={ar}/>)}
					</map>}
				</div>
			</>
	}</>;
}
