import React from "react";
import { mergeClass } from "../../functions/function";
interface LabelProps extends React.HTMLAttributes<HTMLOrSVGElement> {}
const HeadingH2 = ({ children, className, ...rest }: LabelProps) => {
  return (
    <h2 className={mergeClass("heading-2", className)} {...rest}>
      {children}
    </h2>
  );
};

export default HeadingH2;
