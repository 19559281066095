import {store} from "../app/store";
import {addMessageSpeed} from "../app/errors/errorSlice";

export default (err: any) => {
    const dispatch = store.dispatch;
    if(err && err.response && err.response.data && err.response.data.message && !err.response.config.url.includes('sendbird.com')){
        dispatch(addMessageSpeed(err.response.data.message));
    }
    return Promise.reject(err);
}
