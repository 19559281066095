import React, {useEffect, useState} from "react";
import iconBag from "./../../../../assets/img/icon/bag.svg";
import iconNoBag from "./../../../../assets/img/icon/noBag.svg";
import iconShare from "./../../../../assets/img/icon/share.svg";
import Icon from "./../../../../components/shared/icon/Icon";
import { Col, Row } from "react-bootstrap";
import {saveLogAction} from "../../../../app/auth/authSlice";
import {useDispatch} from "react-redux";

const ItemContentsListCardActionType = ({
	content,
	setPathShow,
	isFullScreen,
	copyClipboard,
	setFavourite,
	removefavorite,
}: {
	content: {
		title: string;
		description?: string;
		path?: string;
		type?: string;
		html?: any;
		id?: number;
	};
	copyClipboard: () => void;
	setPathShow?: (path: string | number | undefined, content: any) => void;
	isFullScreen?: boolean;
	setFavourite: (content: any) => void;
	removefavorite?: (content: any) => void;
}) => {
	const [fullHeightText, setFullHeightText] = useState("closed");
	const dispatch = useDispatch();
	let icon = "";
	if (content.type === "url") {
		icon = process.env.REACT_APP_URL_BACKEND + "/img/icons/link.svg";
	} else if (content.type === "file" || content.type === "document") {
		icon = process.env.REACT_APP_URL_BACKEND + "/img/icons/file.svg";
	} else {
		icon = process.env.REACT_APP_URL_BACKEND + "/img/icons/file.svg";
	}

	useEffect(() => {
		isFullScreen && content && content.id && dispatch(saveLogAction({id: content.id, type: "App\\Models\\Article"}))
	}, [isFullScreen, content])

	const copyClipboardHandler = () => {
		copyClipboard && copyClipboard();
	};
	return (
		<Row>
			<Col className="item-list">
				<div className="d-flex justify-content-between mb-3 w-100">
					<div className="d-flex">
						<img
							src={icon}
							alt=""
							style={{ width: "20px", marginRight: "10px" }}
						/>
						<div
							className={`title ${content.id || content.path ? "pointer" : ""}`}
							onClick={() => {
								setPathShow &&
									setPathShow(content.path ? content.path : content.id, content);
							}}>
							{content.title}
						</div>
					</div>
					<div className="btn-tool">
						<div>
							{!removefavorite ? (
								<img
									src={iconBag}
									width="20"
									onClick={() => setFavourite(content)}
								/>
							) : (
								<img
									src={iconNoBag}
									width="20"
									onClick={() => removefavorite && removefavorite(content)}
								/>
							)}
						</div>
						{!removefavorite && (
							<div onClick={copyClipboardHandler}>
								<img src={iconShare} width="20" />
							</div>
						)}
					</div>
				</div>
				<div
					className={`text ${fullHeightText} ${
						isFullScreen ? "open" : "closed"
					}`}>
					{content.html ? (
						<div dangerouslySetInnerHTML={{ __html: content.html }}></div>
					) : (
						content.description
					)}
				</div>

				{!isFullScreen && (
					<Icon
						icon="expand-down"
						size="8px"
						className={`expand ${
							fullHeightText === "closed" ? "" : "rotateIcon"
						}`}
						clickAction={() =>
							fullHeightText === "closed"
								? setFullHeightText("open")
								: setFullHeightText("closed")
						}
					/>
				)}
			</Col>
		</Row>
	);
};

export default ItemContentsListCardActionType;
