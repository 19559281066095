import React from "react";

const CardRounded = ({
	className = '',
	styleCard,
	children,
}: {
	className?: string;
	styleCard?: React.CSSProperties;
	children: React.ReactNode;
}) => {
	return (
		<div className={`shared-card-panel ${className}`} style={styleCard}>
			{children}
		</div>
	);
};

export default CardRounded;
