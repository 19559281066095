import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import it from "./lang/it";
import en from "./lang/en";

export const dropdownSelectableLangs = [
	{ label: "IT", value: "it" },
	{ label: "EN", value: "en" },
];

i18n.use(initReactI18next)
    .init({
	resources: {
		en: {
			translation: en,
		},
		it: {
			translation: it,
		},
	},
	lng: "it",
	fallbackLng: "it",
	interpolation: {
		escapeValue: false,
	},
});
export default i18n;
