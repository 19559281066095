import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../../../../app/store";
import {getFavouritesCall, removeFavouriteArticle, removeFavouriteContent, removeFavouriteLive} from "./favoriteAPI";




export interface FavouritesState{
  // favourites: { favourite_articles: any[], favourite_contents: any[], favourite_lives: any[] },
  favourites: any,
}

const initialState: FavouritesState = {
  favourites : {}
};

export const favoriteSlice = createSlice({
  name: "favourite",
  initialState,
  reducers: {
    setFavourites: (state, action: PayloadAction<any>) => {
      state.favourites =  action.payload;
    },
    removeFavourite: (state, action: PayloadAction<string>) => {
      state.favourites =  Object.keys(state.favourites).reduce((acc: any, ele) => {
        acc[ele] = state.favourites[ele]?.filter((ele: any) => ele.id !== action.payload)
        return acc;
      }, {})
    }
  },
});

export const {
  setFavourites,
  removeFavourite
} = favoriteSlice.actions;

export const setFavouriteAction = (call: any= {}): AppThunk => (dispatch, getState) => {
  const eventId = getState().event.settings?.id;
  getFavouritesCall(eventId).then(res => {
    dispatch(setFavourites(Object.keys(res).reduce((acc:any, ele) => {
      if(ele === 'favourite_contents'){
        acc[ele] = res[ele].filter((el: any) => el.type !== 'image' && el.type !== 'video' && el.type !== 'media' && el.type !== 'url')
        acc.favourite_media = res[ele].filter((el: any) => el.type === 'image' || el.type === 'video' || el.type === 'media' || el.type === 'url')
      } else {
        acc[ele] = res[ele]
      }
      return acc;
    }, {})))
  })


};


export const removeFavoriteArticleAction = (id: string): AppThunk => (dispatch, getState) => {
  removeFavouriteArticle(id).then(res => dispatch(removeFavourite(id)))
};
export const removeFavoriteContentAction = (id: string): AppThunk => (dispatch, getState) => {
  removeFavouriteContent(id).then(res => dispatch(removeFavourite(id)))
};
export const removeFavoriteLiveAction = (id: string): AppThunk => (dispatch, getState) => {
  removeFavouriteLive(id).then(res => dispatch(removeFavourite(id)))
};

export const selectFavourites = (state: RootState) => state.favourite.favourites;

export default favoriteSlice.reducer;
