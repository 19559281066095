import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../components/shared/design/form/buttons/secondary-button/SecondaryButton";
import FormElements from "../../../../components/shared/utils/forms/FormElements";
import { FormElementModelNew } from "../../../../components/shared/utils/forms/models/FormElementModel";
import { UseFormSubmitModel } from "../../../../components/shared/utils/forms/models/UseFormModel";
import { useForm } from "../../../../components/shared/utils/forms/useForm";
import { createStructureForm } from "../../../../components/shared/utils/forms/useStructureForm";
import http from "../../../../components/shared/utils/http";

export default function AddModule({
	cancelHandler,
	saveHandler,
	eventId,
}: any) {
	const { t } = useTranslation();
	const validations = {
		title: {
			required: {
				params: { name: t("posters.form.title") },
			},
		},
		file_path: {
			required: {
				params: { name: t("posters.form.file_path") },
			},
		},
	};
	const structureForm: FormElementModelNew[] = createStructureForm([
		{
			typeElement: "input",
			label: t("posters.form.title"),
			name: "title",
			dataElement: {
				type: "text",
				classNameGroup: "",
			},
			col: { xs: "12", md: "6" },
		},
		{
			typeElement: "input",
			label: t("posters.form.subheading"),
			name: "subheading",
			dataElement: {
				type: "text",
				classNameGroup: "",
			},
			col: { xs: "12", md: "6" },
		},
		{
			typeElement: "textarea",
			label: t("posters.form.abstract"),
			name: "abstract",
			dataElement: {
				type: "text",
				classNameGroup: "",
			},
			col: { xs: "12" },
		},
		{
			typeElement: "file",
			label: t("posters.form.file_path"),
			name: "file_path",
			dataElement: {
				type: "text",
				classNameGroup: "",
				accept: ".pdf,.jpg,.jpeg,.bmp,.png"
			},
			col: { xs: "auto" },
		},
	]);

	const submitHandler: UseFormSubmitModel = (event, data) => {
		console.log(data);
		let formData = new FormData();
		Object.keys(data).forEach((ele) => {
			data[ele] && data[ele].file
				? formData.append(ele, data[ele].file, data[ele].file.name)
				: formData.append(ele, data[ele]);
		});

		http.post(`events/${eventId}/posters`, formData).then(
			(res) => {
				// saveHandler(res.poster);
				cancelHandler();
			},
			(err) => {
				console.log(err);
			}
		);
	};

	const { isSubmit, errors, submitAction, changeValue, dataForm } = useForm(
		submitHandler,
		validations
	);

	return (
		<Row>
			<Col>
				<Form onSubmit={submitAction}>
					<FormElements
						isSubmit={isSubmit}
						data={structureForm}
						errors={errors}
						changeValue={changeValue}
						dataForm={dataForm}
					/>
					<Row className="justify-content-end">
						<Col xs="auto" className="d-flex">
							<SecondaryButton
								size="sm"
								className="mr-3"
								onClick={cancelHandler}>
								{t("actions.cancel")}
							</SecondaryButton>
							<PrimaryButton size="sm">{t("actions.save")}</PrimaryButton>
						</Col>
					</Row>
				</Form>
			</Col>
		</Row>
	);
}
