import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import iconNoBag from "./../../../../../assets/img/icon/noBag.svg";
import calendar from "./../../../../../assets/img/menuTool/calendar.svg";
import iconBag from "../../../../../assets/img/icon/bag.svg";
import ItemAccordion from "../ItemAccordion";
import { date_ggmmyyyy_hhmm } from "../../../../../components/shared/utils/date";

const ItemLive = ({
	content,
	removefavorite,
	setFavourite,
	setSelectedLive,
}: {
	content: {
		name: string;
		end: string;
		start: string;
		id: number;
		lives: { description: string; start: string; end: string }[];
	};
	removefavorite?: (content: any) => void;
	setFavourite: (content: any) => void;
	setSelectedLive: () => void;
}) => {
	return (
		<Row>
			<Col className="item-list">
				<div className="d-flex justify-content-between mb-3 w-100">
					<div>
						<img
							src={calendar}
							alt=""
							style={{ width: "20px", marginRight: "10px" }}
						/>
						<span className={`title pointer mr-4`} onClick={setSelectedLive}>
							{content.name}
						</span>
						<span className="mr-3">
							inizio il {date_ggmmyyyy_hhmm(content.lives[0].start)}
						</span>
						<span className="mr-3"> fine il {date_ggmmyyyy_hhmm(content.lives[0].end)}</span>
					</div>
					<div className="btn-tool">
						<div>
							{removefavorite ? (
								<img
									src={iconNoBag}
									width="20"
									onClick={() => removefavorite && removefavorite(content)}
								/>
							) : (
								<img
									src={iconBag}
									width="20"
									onClick={() => setFavourite(content)}
								/>
							)}
						</div>
					</div>
				</div>
				{!!content.lives.length && !!content.lives[0]?.description && (
					<ItemAccordion>{content.lives[0].description}</ItemAccordion>
				)}
			</Col>
		</Row>
	);
};

export default ItemLive;
