import {store} from "../app/store";
import {addMessageSpeed} from "../app/errors/errorSlice";

export default (config: any) => {
    const dispatch = store.dispatch;
    if(config && config.data && config.data.message && !config.config.url.includes('sendbird.com')){
        dispatch(addMessageSpeed(config.data.message, true));
    }
    return config;
}
