import React, { useEffect } from "react";
import { FormElementModelNew } from "../../../../../components/shared/utils/forms/models/FormElementModel";
import { createStructureForm } from "../../../../../components/shared/utils/forms/useStructureForm";
import { UseFormSubmitModel } from "../../../../../components/shared/utils/forms/models/UseFormModel";
import { useForm } from "../../../../../components/shared/utils/forms/useForm";
import FormElements from "../../../../../components/shared/utils/forms/FormElements";
import {useDispatch, useSelector} from "react-redux";
import {selectLoadingChat} from "../../../chatSlice";
import { getListUserChat } from "../../SendBirdChat";
import CardDetail from "../../../../../components/shared/design/card-detail/CardDetail";
import PrimaryButton from "../../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import { useTranslation } from "react-i18next";
import SearchRow from "./SearchRow";
import { Col, Row } from "react-bootstrap";
import {useFilterUsers} from "./useFilterUsers";
import {selectUsersChatWithInfo} from "../../../chatSelector";
import {setEventUsersAction} from "../../../../public/event/eventSlice";

export default function ListUsers({
	sdkInstance,
	createChannel,
	userLoggedId,
	closeHandler,
}: {
	sdkInstance: any;
	userLoggedId?: string;
	createChannel: (users: string[]) => void;
	closeHandler: () => void;
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const loading = useSelector(selectLoadingChat)
	const users = useSelector(selectUsersChatWithInfo);
	useEffect(() => {
		dispatch(setEventUsersAction())
		getListUserChat();
	}, [sdkInstance]);
	const createChannelHandler = (usersFilter: string[]) => {
		createChannel(usersFilter);

	};

	const submitHandler: UseFormSubmitModel = (event, data) => {
		data && data.users && data.users.length && createChannelHandler(data.users);
	};

	const initForm = { sort: "name" };
	const form = useForm(submitHandler, {}, initForm);
	const refreshSearch = () => {
		form.setData(initForm);
	};
	const {filterUsers} = useFilterUsers(users, form.dataFormatted.search, form.dataFormatted.sort);
	const structureForm: FormElementModelNew[] = createStructureForm([
		{
			typeElement: "checkbox",
			label: "",
			name: "users",
			options: filterUsers.map((us: any) => ({ label:  `${us.name} ${us.surname}`, value: us.userId })),
		},
	]);
	return (
		<div className="list-user-chat">
			<CardDetail headerTitle="Lista utenti" closeHandler={closeHandler}>
				<SearchRow form={form} refreshSearch={refreshSearch} />
				<form
					name="loginForm"
					id="loginForm"
					className="form-list-user"
					onSubmit={form.submitAction}>
					{!!users && !!users.length ? (
						<div className="content">
							{<FormElements
								data={structureForm}
								errors={form.errors}
								changeValue={form.changeValue}
								dataForm={form.dataForm}
							/>}
							<Row className="justify-content-end">
								<Col xs="auto">
									<PrimaryButton type="submit" size="sm">
										Invia
									</PrimaryButton>
								</Col>
							</Row>
						</div>
					) : (
						<div className="d-flex justify-content-center p-5">
							<span>{loading ? t('card.userCall.loading') : t('card.userCall.noUser')}</span>
						</div>

					)}
				</form>
			</CardDetail>
		</div>
	);
}
