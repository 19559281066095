import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabsPane from "../../../../../components/shared/design/tabs/Tabs";
import { ArticlegridCardActionType } from "../../cardActionTypes/article_grid/ArticlegridCardActionType";
import { ContentsListCardActionType } from "../../cardActionTypes/contents/ContentsListCardActionType";
import {
	removeFavoriteArticleAction,
	removeFavoriteContentAction, removeFavoriteLiveAction,
	selectFavourites,
	setFavouriteAction,
} from "./favoriteSlice";
import { useTranslation } from "react-i18next";
import { GridCardMediaList } from "../../cardActionTypes/media/GridCardMediaList";
import { LivelistCardActionType } from "../../cardActionTypes/live_list/LivelistCardActionType";

export function Favorites({
	showGeneral = false,
	closeHandler,
}: {
	showGeneral?: boolean;
	closeHandler: () => void;
	changeArea?: (id: number) => void;
}) {
	const favourites: any = useSelector(selectFavourites);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setFavouriteAction());
	}, []);

	const [tabSelected, setTabSelected] = useState<string>("favourite_articles");

	const onSelect = (keyTab: string) => {
		setTabSelected(keyTab);
	};

	const tabsName: any = {
		favourite_articles: t('card.favorites.tabs.favourite_articles'),
		favourite_contents: t('card.favorites.tabs.favourite_contents'),
		favourite_media: t('card.favorites.tabs.favourite_media'),
		// favourite_lives: t('card.favorites.tabs.favourite_lives'),
	};

	const tabsList = Object.keys(favourites).map((ele) => {
		return {
			keyTab: ele,
			title: tabsName[ele],
		};
	});

	const removeFavoriteArticle = (content: any) => {
		dispatch(removeFavoriteArticleAction(content.id));
	};
	const removeFavouriteContent = (content: any) => {
		dispatch(removeFavoriteContentAction(content.id));
	};
	const removeFavouriteLive = (content: any) => {
		dispatch(removeFavoriteLiveAction(content.id));
	};

	const dataComponent: any = {
		favourite_articles: (
			<ArticlegridCardActionType
				articles={favourites.favourite_articles}
				copyClipboard={() => null}
				removefavorite={removeFavoriteArticle}
			/>
		),
		favourite_contents: (
			<ContentsListCardActionType
				contents={favourites.favourite_contents}
				copyClipboard={() => null}
				removefavorite={removeFavouriteContent}
			/>
		),
		favourite_media: (
			<GridCardMediaList
				contents={favourites.favourite_media}
				copyClipboard={() => {}}
				removefavorite={removeFavouriteContent}
			/>
		),
		// favourite_lives: (
		// 	<LivelistCardActionType
		// 		data={favourites.favourite_lives}
		// 		removefavorite={removeFavouriteLive}
		// 	/>
		// ),
	};

	return (
		<div className="h-100">
			<TabsPane
				listTabs={tabsList}
				keyActiveTab={tabSelected}
				onSelect={onSelect}
			/>
			{dataComponent[tabSelected]}
		</div>
	);
}
