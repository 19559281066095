import React from "react";
import { User } from "../../../../../../app/auth/model/User";
import Icon from "../../../../../../components/shared/icon/Icon";


const ProfileSummary = ({
	profile,
	callUserHandler,
 	openChatUser,
	closeHandler,
	showProfile,
	setEditMode
}: {
	profile: User;
	showProfile: string | boolean;
	closeHandler: () => void;
	callUserHandler: (data: any) => void
	openChatUser: (userId: string) => void
	setEditMode: () => void
	
}) => {
	return (
			
			<div className="d-flex justify-content-between w-100">
				<h3 className="mr-3">
					{profile.name} {profile.surname}
				</h3>
				<div className="contact-btn">
					{typeof showProfile === "string" &&  <>
						<Icon icon="videocall" size="20px"
							  clickAction={() => {
								closeHandler && closeHandler()
								callUserHandler && callUserHandler({userId: profile.idChat})
							}} />
						<Icon icon="msg" size="24px" clickAction={() => {
							closeHandler && closeHandler()
							openChatUser(profile.idChat)
						}} />
					</>}
					{typeof showProfile !== "string" && profile.role !== 'guest' && (
						<>
							<Icon
								icon="app"
								size="20px"
								clickAction={setEditMode}
							/>
						</>
					)}
				</div>
			</div>
	);
};

export default ProfileSummary;
