import React from "react";
import { IMenuItem } from "../../model/MenuModel";
import { MenuAreasModelMobile } from "../desktop/menuApp/model/MenuAppModel";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedView } from "../../../../../app/mobileSlice";
import { selectCurrentArea } from "../../area/areaSlice";
import Icon from "../../../../../components/shared/icon/Icon";
import { mergeClass } from "../../../../../components/shared/functions/function";

const MenuAreasMobile = ({
	theme,
	setCurrentArea,
	menuItems,
	bigBanner,
	openBigBannerModule,
}: MenuAreasModelMobile) => {
	const dispatch = useDispatch();
	const currentArea = useSelector(selectCurrentArea);
	const setCurrentAreaHandler = (ele: IMenuItem) => {
		setCurrentArea(ele?.id);
		dispatch(resetSelectedView());
	};
	const closeAction = () => {
		dispatch(resetSelectedView());
	};
	return (
		<div className="menu-areas-content">
			<div className="d-flex justify-content-end mb-3">
				<Icon
					icon="close-x"
					size="14px"
					colorStyle="#4D4D4D"
					clickAction={closeAction}
				/>
			</div>
			<div className="w-100">
				{menuItems.map((ele: IMenuItem, i: number) => (
					<div
						className={[
							"menu-areas-item",
							...(currentArea === ele.id ? ["active"] : []),
						].join(" ")}
						key={i}
						onClick={() => setCurrentAreaHandler(ele)}>
						<span>{ele.label}</span>
					</div>
				))}
			</div>
			{bigBanner && bigBanner.path && (
				<div className="mt-5 text-center" onClick={openBigBannerModule}>
					<img
						src={bigBanner.path}
						style={{ maxWidth: "220px", maxHeight: "60px" }}
					/>
				</div>
			)}
		</div>
	);
};

export default MenuAreasMobile;
