import React from "react";
import { FormControl } from "react-bootstrap";
import { mergeClass } from "../../../functions/function";
import { TextAreaType } from "../../../utils/forms/models/FormElementModel";

const TextArea: React.FC<TextAreaType> = ({
	children,
	className,
	error = false,
	classNameGroup,
	...rest
}) => {
	const classMerge: string = mergeClass(
		"shared-textArea",
		error ? " error" : ""
	);

	return (
		<FormControl
			className={`${classMerge} ${classNameGroup}`}
			as="textarea"
			rows={3}
			{...rest}
		/>
	);
};

export default TextArea;
