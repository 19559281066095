import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../components/shared/icon/Icon";

export default function ProfileRow({
	user,
	showProfileHandler,
	closeHandler,
	callUserHandler,
	openChatUser,
}: {
	user: any;
	closeHandler: () => void;
	showProfileHandler?: (userId: string) => void;
	callUserHandler: (data: any) => void;
	openChatUser: (userId: string) => void;
}) {
	const { t } = useTranslation();

	return (
		<div className="profile-row" key={user.user_id}>
			<div
				className="item-name pointer"
				onClick={() => showProfileHandler && showProfileHandler(user.user_id)}>
				<img className="thumb-user" src={user.avatar} />
				<div className="ml-3">
					<b>
						{user.name} {user.surname}
						<span className="ml-1 pointer">
							<a className="primary" href={`mailto:${user.email}`}>
								<Icon icon="mail" className="d-inline" />
							</a>
						</span>
					</b>
				</div>
			</div>
			<div className="btn-action">
				<>
					<span
						onClick={() => {
							closeHandler && closeHandler();
							callUserHandler && callUserHandler({ userId: user.idChat });
						}}
						className="mr-3">
						<Icon
							icon="videocall"
							className="p-0 pointer"
							size="20px"
							colorStyle="#81358A"
							title={`${t("card.userCall.call")} ${user.name} ${user.surname}`}
						/>
					</span>
					<span
						onClick={() => {
							closeHandler && closeHandler();
							openChatUser(user.idChat);
						}}
						className="mr-3">
						<Icon
							icon="msg"
							className="p-0 pointer"
							size="24px"
							title={`${t("card.userCall.contact")} ${user.name} ${
								user.surname
							}`}
						/>
					</span>
				</>
			</div>
		</div>
	);
}
