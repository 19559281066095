import React from "react";
import Icon from "../../../../../components/shared/icon/Icon";
import ItemRoom from "./ItemRoom";

export default function ListRooms({
	roomsChannel,
	setPublicChannel,
	selectChannel,
	chatActive,
	setChatActive,
}: {
	roomsChannel: any[];
	setPublicChannel: (channel: any) => void;
	selectChannel?: any;
	chatActive: string | undefined;
	setChatActive: (data: string | undefined) => void;
}) {
	return (
		<div className="rooms">
			<h5>Rooms</h5>
			<div className="content-item" style={{maxHeight: (!chatActive || chatActive === "room") ? '2000px' : '0px'}}>
				<div className="list-rooms">
					{roomsChannel &&
						
						roomsChannel.map((ele) => (
							<ItemRoom
								key={ele.name}
								item={ele}
								clickHandler={() => setPublicChannel(ele)}
								isActive={!!selectChannel && selectChannel.url === ele.url}
							/>
						))}
				</div>
				{!chatActive || chatActive !== "room" ? (
					<Icon icon="down" clickAction={() => setChatActive("room")} />
				) : (
					<Icon
						icon="down"
						style={{ transform: "rotate(180deg)" }}
						clickAction={() => setChatActive(undefined)}
					/>
				)}
			</div>
		</div>
	);
}
