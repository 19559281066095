import React, { useState } from "react";
import BackBtn from "../../../../components/shared/backBtn/BackBtn";
import ItemPoster from "./ItemPoster";

export default function ListPoster({ list, showPoster, setShowPoster }: any) {
	return (
		<div className="d-flex flex-column h-100">
			{!showPoster &&
				list.map((ele: any) => (
					<ItemPoster
						key={ele.id}
						poster={ele}
						clickPosterHandler={(poster: string) => setShowPoster(poster)}
					/>
				))}
			{showPoster && (
				<div className="h-100">
					<div className="d-flex justify-content-end">
						<BackBtn onClick={() => setShowPoster(undefined)} />
					</div>

					{/* <iframe src={showPoster} width="100%" height="100%"></iframe> */}
					<div className="d-flex justify-content-center">	
						<img src={showPoster}
							style={{ maxWidth: "100%"}}
						></img>
					</div>
				</div>
			)}
		</div>
	);
}
