import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsMobile } from "../../../../../app/generalSettingsSlice";
import Icon from "../../../../../components/shared/icon/Icon";
import { objDeleteProperties } from "../../../../../components/shared/utils/objUtils";
import { calcVh } from "../../../../../components/shared/utils/vhUtils";
import ElementProfileDetail from "./ElementProfileDetail";

export default function ProfileDetail({
	profile,
	showProfile,
	closeHandler,
	fullHeighBox,
	setFullHeightBox,
	callUserHandler,
	openChatUser,
}: {
	profile: any;
	showProfile?: string | boolean;
	callUserHandler: (data: any) => void;
	openChatUser: (userId: string) => void;
	closeHandler: () => void;
	fullHeighBox: "open" | "closed";
	setFullHeightBox: (state: "open" | "closed") => void;
}) {
	const isMobile = useSelector(selectIsMobile);
	const profileRef = useRef<HTMLDivElement>(null);

	const clickExpand = () => {
		fullHeighBox === "closed"
			? setFullHeightBox("open")
			: setFullHeightBox("closed");
		profileRef && profileRef?.current?.scrollTo(0, 0);
	};
	const detailProfile = Object.keys(
		objDeleteProperties(profile, ["name", "surname", "id", "avatar", "idChat"])
	);
	return (
		<div
			ref={profileRef}
			className={`profile ${fullHeighBox}`}
			style={{
				maxHeight: `${
					fullHeighBox === "open"
						? `calc( ${calcVh(100)} - ${isMobile ? "0px" : "310px"})`
						: `${isMobile ? "64px" : "164px"}`
				}`,
				overflowY: `${fullHeighBox === "open" ? "auto" : "hidden"}`,
				height: `${isMobile ? "100%" : "auto"}`,
			}}>
			<div className="left-panel">
				<div className="img mb-3 mt-0">
					<img src={profile.avatar} alt="" width="120px" height="120px" />
				</div>
				{isMobile && (
					<h4 className="">
						{profile.name} {profile.surname}
					</h4>
				)}
			</div>
			<div className="right-panel w-100 h-100 ">
				<div className="d-flex justify-content-between w-100 title-user">
					<h3 className="mr-3">
						{profile.name} {profile.surname}
					</h3>
					<div className="contact-btn">
								<Icon
									icon="videocall"
									size="20px"
									clickAction={() => {
										closeHandler && closeHandler();
										callUserHandler &&
											callUserHandler({ userId: profile.idChat });
									}}
								/>
								<Icon
									icon="msg"
									size="24px"
									clickAction={() => {
										closeHandler && closeHandler();
										openChatUser(profile.idChat);
									}}
								/>
					</div>
				</div>

				<div className="info-detail">
					{detailProfile.map((d) => (
						<div>
							<ElementProfileDetail d={d} value={profile[d]} />
						</div>
					))}
				</div>
			</div>
			{((detailProfile && detailProfile.length > 3) ||
				(isMobile && detailProfile.length > 0)) && (
				<div className="expand-icon">
					<Icon
						icon="expand-down"
						size="8px"
						className={`expand ${
							fullHeighBox === "closed" ? "" : "rotateIcon"
						}`}
						clickAction={clickExpand}
					/>
				</div>
			)}
		</div>
	);
}
