import React from "react";
import {Channel, OpenChannel} from "sendbird-uikit";
import RenderUserProfileCustom from "./RenderUserProfileCustom";


export default function ChatViewCustom({
	selectChannel,
	setShowProfile
}: {
	selectChannel: any;
	setShowProfile: (data: boolean | string) => void,
}) {

	return (
		<div className="sendbird-app__conversation-wrap">
			{selectChannel?.type === "public" ? (
				<OpenChannel
					renderChannelTitle={() => <div></div>}
					fetchingParticipants={false}
					useMessageGrouping={false} // To determine whether to use message grouping,
					channelUrl={selectChannel.url} // pass your channel URL here.
				/>
			) : (
				<>
					<Channel
						renderChatHeader={() => <div></div>}
						renderUserProfile={(props) => <RenderUserProfileCustom setShowProfile={setShowProfile}  props={props} />}
						channelUrl={selectChannel?.url || ""}
					/>
				</>
			)}
		</div>
	);
}
