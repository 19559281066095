import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import {Profile, ProfileKeys} from "../../../models/Profile";
import {StepModel} from "../../../models/Step";
import {findTypeInput} from "../../../data/inputTranslate";
import {register} from "./registerAPI";
import { setInitWithToken} from "../../../app/auth/authSlice";
import {isHTML} from "../../../components/shared/utils/htmlUtils";



export interface RegisterState {
  steps: StepModel[];
  currentStep: number;
  resetForm: number;
  profiles: Profile[];
  currentProfile?: any;
}

const initialState: RegisterState = {
  steps: [],
  currentStep: 0,
  resetForm: 0,
  profiles: []
};



export const registerSlice = createSlice({
  name: 'register',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSteps: (state, action: PayloadAction<StepModel[]>) => {
      state.steps = action.payload;
    },
    resetForm: (state) => {
      state.resetForm = state.resetForm +1;
    },
    resetSteps: (state) => {
      state.steps = [];
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setProfiles: (state, action: PayloadAction<Profile[]>) => {
      state.profiles = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

});

export const { setSteps, setCurrentStep, setProfiles, resetSteps, resetForm } = registerSlice.actions;

const getFormStructure = (ele: ProfileKeys) => {
  const data =({
    typeElement: findTypeInput(ele.type).typeElement,
    label: ele.label + (ele.required? ' *' : ''),
    name: ele.name,
    options: ele.options?.map((e) => ({ label: e.text, value: e.value })),
    dataElement: { ...findTypeInput(ele.type).dataElement },
    col: { xs: 12 },
  })
  return data;
}

const getValidation = (validations: any = {}, ele: ProfileKeys) => {
  if(ele.required){
    validations[ele.name] = {
      required:{
        params: { name: isHTML(ele.label) ? ' ' : ele.label },
      }
    }
  }

  return validations
}

export const setStepsAction = (profile_id : string): AppThunk => (dispatch, getState) => {
  if(!profile_id){
    dispatch(setSteps([]))
  }else {
    const profile = getState().register.profiles?.find(ele => ele.id === parseInt(profile_id))
    if(profile){
      dispatch(setSteps(profile.profile_keys.reduce((arr: StepModel[], element) => {
        if(arr.length){
          arr[arr.length-1].formStructure = [...(arr[arr.length-1].formStructure || []), getFormStructure(element)];
          arr[arr.length-1].validations = getValidation(arr[arr.length-1].validations, element)
        } else {
          arr.push({
            formStructure: [getFormStructure(element)],
            validations: getValidation({}, element)
          })
        }
        return arr;
      }, [])))
    }
  }



};

export const resetRegisterAction = (): AppThunk => (dispatch, getState) => {
  dispatch(resetSteps())
  dispatch(resetForm())
};

export const registerAction = (data: any, callback?: () => void, callbackSuccess?: () => void): AppThunk => (dispatch, getState) => {
  let formData = new FormData();
  Object.keys(data).forEach(ele => {
    data[ele] && data[ele].file ?
        formData.append(ele, data[ele].file, data[ele].file.name) :
        formData.append(ele, data[ele])
  })

  register(formData).then(res => {
    if(res.token){
      callbackSuccess && callbackSuccess();
      dispatch(setInitWithToken(res));
    }else {
      callback && callback();
    }

  })

};

export const setProfilesAction = (profiles: Profile[]): AppThunk => (dispatch, getState) => {
  // if(profiles.length){
  //   dispatch(addFixedSteps({
  //     formStructure: [{
  //       typeElement: "select",
  //       label: "Ruolo",
  //       name: "profile_id",
  //       options: profiles.map(ele => ({value: ele.id.toString(), label: ele.name}))
  //     }],
  //     validations: {
  //       profile_id:{
  //         required: {
  //           params: {name: 'Profilo'}
  //         }
  //       }
  //     },
  //     type: "check"
  //   }))
  // }
  dispatch(setProfiles(profiles))
};


export const selectSteps = (state: RootState) => state.register.steps;
export const selectProfiles = (state: RootState) => state.register.profiles;
export const selectResetForm = (state: RootState) => state.register.resetForm;
export const selectCurrentStep = (state: any) => state.register.currentStep;


export default registerSlice.reducer;
