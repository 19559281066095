import React from "react";
import "sendbird-uikit/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { selectCall, setCall } from "../../callSlice";
import { acceptCall, callInstance, declineCall } from "../SendBirdCallAction";
import RingCall from "./components/RingCall";
import PrimaryButton from "../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import Icon from "../../../../components/shared/icon/Icon";

export function CallView() {
	const dispatch = useDispatch();
	const call = useSelector(selectCall);
	const callData = callInstance;
	const acceptCallHandler = () => {
		dispatch(setCall({ state: "video" }));
		setTimeout(() => {
			acceptCall();
		}, 100);
	};
	const declineCallHandler = () => {
		declineCall();
		//dispatch(resetCall())
	};

	return (
		<>
			{call && call.state === "ring" && (
				<RingCall
					caller={callData?.caller?.nickname}
					declineCallHandler={declineCallHandler}
					acceptCallHandler={acceptCallHandler}
				/>
			)}
			{call && call.state === "video" && (
				<div className="call-container">
					<>
						<video id="local_video_element_id" autoPlay muted></video>
						<video id="remote_video_element_id" autoPlay></video>
						<div className="btn-toolbar">
							<PrimaryButton onClick={declineCall} className="btn-item">
								<Icon icon="close_call" size="32px" title="Chiudi chiamata" />
							</PrimaryButton>
						</div>
					</>
				</div>
			)}
		</>
	);
}
