import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectIsMobile } from "../../../../app/generalSettingsSlice";
import CardDetail from "../../../../components/shared/design/card-detail/CardDetail";
import http from "../../../../components/shared/utils/http";
import { calcVh } from "../../../../components/shared/utils/vhUtils";
import ProfileRow from "./components/ProfileRow";
import {createUserIdChat} from "../../../../components/shared/utils/chatUtils";

const ListAppointment = ({
	closeHandler,
	showListAppointment,
	setShowProfile,
	callUserHandler,
	 openChatUser,
	eventId,
}: {
	eventId: number;
	closeHandler: () => void;
	setShowProfile?: (id: string) => void;
	callUserHandler: (data: any) => void;
	openChatUser: (userId: string) => void;
	showListAppointment: string;
}) => {
	const { t } = useTranslation();
	const isMobile = useSelector(selectIsMobile);
	const [list, setList] = useState<any[]>([]);
	useEffect(() => {
		http.get(showListAppointment).then((res) => {
			setList(res.map((ele: any) => ({...ele, idChat: createUserIdChat(ele.user_id, eventId)})));
		});
	}, [showListAppointment]);

	return (
		<div className="no-close-outside">
			<CardDetail
				style={{
					position: "fixed",
					top: `${isMobile ? "88px" : "88px"}`,
					left: "50%",
					transform: "translateX(-50%)",
					height: `calc( ${calcVh(100)} - ${isMobile ? "162px" : "190px"})`,
					width: "100%",
					maxWidth: "1100px",
					margin: "auto",
				}}
				headerTitle={`${t("appointment.titleList")}`}
				closeHandler={closeHandler}>
				<div className="d-flex flex-column h-100">
					{list.map((r) => (
						<ProfileRow
							user={r}
							closeHandler={closeHandler}
							callUserHandler={callUserHandler}
							openChatUser={openChatUser}
							showProfileHandler={(userId) =>
								setShowProfile && setShowProfile(userId)
							}
						/>
					))}
				</div>
			</CardDetail>
		</div>
	);
};

export default ListAppointment;
