import React from "react";
import { Navbar } from "react-bootstrap";
import { IMenuItem } from "../../../model/MenuModel";
import MenuItem from "./components/MenuItem";
import { MenuAreasModel} from "./model/MenuAppModel";

const MenuAreasDesk = ({ theme, setCurrentArea, bigBanner, openBigBannerModule, menuItems }: MenuAreasModel) => {
	return (
		<Navbar bg={theme} expand="lg" fixed="bottom" className="menu-app">
			{menuItems.map((ele: IMenuItem, i: number) => (
				<MenuItem clickVoice={() => {setCurrentArea(ele?.id)}} menuItem={ele} theme={theme} key={i} />
			))}
			{
				bigBanner && bigBanner.path && <div className={bigBanner.module ? 'pointer' : ''} onClick={openBigBannerModule}>
					<img
						src={bigBanner.path}
						style={{maxWidth: '220px', maxHeight: "60px"}}
					/>
				</div>
			}
		</Navbar>
	);
};

export default MenuAreasDesk;
