export const vhUtils = () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	window.addEventListener("resize", () => {
		// We execute the same script as before
		vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	});
};
export const calcVh = (dim = 100) => {
	return `calc(var(--vh, 1vh) * ${dim})`;
};
