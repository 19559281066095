import React from "react";
import {
	listVoiceType,
	selectSelectedViewMobile,
	setSelectedView,
} from "../../../../../app/mobileSlice";
import { useDispatch, useSelector } from "react-redux";
import { ITheme } from "../../model/AreaModel";
import dock from "../../../../../assets/img/menuTool/dock.svg";
import calendar from "../../../../../assets/img/menuTool/calendar.svg";
import suitcase from "../../../../../assets/img/menuTool/suitcase.svg";
import msg from "../../../../../assets/img/menuTool/msg.svg";
import { selectExcludedFeatures } from "../../../../../app/auth/authSlice";
import { Col, Row } from "react-bootstrap";
import { selectSettingEvent } from "../../../../public/event/eventSlice";

export function MenuMobile({ theme }: { theme: ITheme }) {
	const setting = useSelector(selectSettingEvent);
	const selectedView = useSelector(selectSelectedViewMobile);
	const excludedFeatures = useSelector(selectExcludedFeatures);
	const listMenuMobile: any = {
		dock: {
			name: "dock",
			label: "dock",
			classNames: [],
			ico: dock,
		},
		calendar: {
			name: "calendar",
			label: "calendar",
			classNames: ["no-close-outside"],
			hide:setting.autologin_guest,
			ico: calendar,
		},
		favourite: {
			name: "favourite",
			label: "favourite",
			classNames: ["no-close-outside"],
			hide:setting.autologin_guest,
			ico: suitcase,
		},
		chat: {
			name: "chat",
			label: "chat",
			classNames: ["no-close-outside"],
			hide: excludedFeatures?.includes("chat") ||setting.autologin_guest,
			ico: msg,
		},
	};
	const dispatch = useDispatch();

	return (
		<>
			<div className="menu-container">
				<Row className="w-100">
					{Object.keys(listMenuMobile).map((ele) => (
						<>
							{!listMenuMobile[ele].hide && (
								<Col
									key={ele}
									className={[
										"menu-item",
										...(listMenuMobile[ele].classNames || []),
										...(listMenuMobile[ele]?.name === selectedView
											? ["active"]
											: []),
									].join(" ")}
									onClick={() =>
										!listMenuMobile[ele].hide &&
										dispatch(setSelectedView(ele as listVoiceType))
									}>
									<div className="label-menu">
										<img
											src={listMenuMobile[ele]?.ico}
											alt={listMenuMobile[ele]?.label}
										/>
										<span>{listMenuMobile[ele]?.label}</span>
									</div>
								</Col>
							)}
						</>
					))}{" "}
				</Row>
			</div>
		</>
	);
}
