import { IMenuItem } from "../../../model/MenuModel";
import group from "../../../../../../assets/img/menuTool/group.svg";
import calendar from "../../../../../../assets/img/menuTool/calendar.svg";
import suitcase from "../../../../../../assets/img/menuTool/suitcase.svg";

export const optionMenu = {
	suitcase: 'suitcase',
	callUsers: 'callUsers',
	calendar: 'calendar'
}
export const suitecaseData: IMenuItem =  {
	name: optionMenu.suitcase,
	icon: {
		light: suitcase,
		dark: suitcase,
	},
	label: "",
}
export const calluserData: IMenuItem =  {
	name: optionMenu.callUsers,
	icon: {
		light: group,
		dark: group,
	},
	label: "",
}
export const calendarData: IMenuItem = {
	name: optionMenu.calendar,
	icon: {
		light: calendar,
		dark: calendar,
	},
	label: "",
}
