import React, {useEffect} from "react";
import {
	SendBirdProvider
} from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import {useDispatch} from "react-redux";
import CustomSendBird from "./components/CustomSendBird";
import {initSendBirdChat, removeListerSendBird} from "../SendBirdChat";
import {initCallAction} from "../SendBirdCallAction";

export function SendBirdCustom({
	nickname,
	userId,
	theme,
	showChat,
	setShowChat,
    eventId,
   profileUrl,
	callUserHandler,
	setShowProfile
}: {
	nickname: string;
	userId: any;
	showChat: boolean;
	setShowChat: (val: boolean) => void;
	theme: "light" | "dark";
	eventId: any;
	profileUrl?: string
	callUserHandler: (ele: any) => void;
	setShowProfile: (data: boolean | string) => void;
}) {

	const dispatch = useDispatch();

	useEffect(()=>{
		initCallAction(dispatch, userId);
		initSendBirdChat(dispatch, eventId, userId);
		return () => {
			removeListerSendBird()
		}
	}, [])
	return (
			<SendBirdProvider
				renderUserProfile={() => null}
				nickname={nickname}
				userId={`${userId}`}
				profileUrl={profileUrl}
				theme={theme}
				appId={process.env.REACT_APP_SENDBIRD_ID || ''}>
					<CustomSendBird setShowProfile={setShowProfile} showChat={showChat} setShowChat={setShowChat} callUserHandler={callUserHandler}/>
			</SendBirdProvider>
	);
}
