import React from "react";
import { useTranslation } from "react-i18next";
import FormGroup from "../../design/form/form-group/FormGroup";
import FormControlCustom from "./FormControlCustom";
import { FormGroupCustomModel } from "./models/FormElementModel";

const FormGroupCustom = ({
  isSubmit,
  errors,
  label,
  subLabel,
  display,
  name,
  onBlur,
  classInputGroup,
  ...rest
}: FormGroupCustomModel) => {
  const { t } = useTranslation();
  return (
    <FormGroup
      display={display}
      classInputGroup={classInputGroup}
      label={{ label: label || "" }}
      subLabel={subLabel}
      error={{
        isVisible: isSubmit && !!errors && !!errors[name],
        message: t("errors." + errors[name]?.code, errors[name]?.params),
      }}
    >
      <FormControlCustom
        isSubmit={isSubmit}
        errors={errors}
        name={name}
        onBlur={onBlur}
        {...rest}
      />
    </FormGroup>
  );
};

export default FormGroupCustom;
