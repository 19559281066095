import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import { objWithProperties } from "../../components/shared/utils/objUtils";
import { getChatActive, setChatActive } from "./chatAPI";
import { GroupChannel } from "sendbird";

export interface ChannelSummary {
	url: string;
	name?: string;
	members?: any;
	type: string;
}
export interface ChannelCustom extends ChannelSummary {
	memberCount?: number;
	joinedMemberCount?: number;
	unreadMessageCount?: number;
	data?: string;
}

export interface UserChat {
	connectionStatus: string;
	friendDiscoveryKey: any;
	friendName: any;
	isActive: boolean;
	lastSeenAt: number;
	metadata: any;
	nickname: string;
	plainProfileUrl: string;
	requireAuth: boolean;
	userId: string;
}

export interface ChatState {
	users: UserChat[];
	loggedUsers: UserChat[];
	notify: number | Number;
	activeRooms: any[];
	channels: ChannelCustom[];
	roomsChannels: ChannelCustom[];
	standChannels: ChannelCustom[];
	selectedChannel?: ChannelSummary;
	lastSelectedChannel?: ChannelSummary;
	openUserChat?: string;
	minifyChannel?: ChannelSummary;
	publicChannel: any;
	openChatMobile: boolean;
	loading: boolean;
}

const initialState: ChatState = {
	notify: 0,
	activeRooms: [],
	users: [],
	loggedUsers: [],
	channels: [],
	roomsChannels: [],
	standChannels: [],
	selectedChannel: undefined,
	publicChannel: undefined,
	openChatMobile: false,
	loading: false
};

export const chatSlice = createSlice({
	name: "chat",
	initialState,
	reducers: {
		setNotify: (state, action: PayloadAction<number | Number>) => {
			state.notify = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setUsersChat: (state, action: PayloadAction<UserChat[]>) => {
			state.users = action.payload;
		},
		setOpenUserChat: (state, action: PayloadAction<string>) => {
			state.openUserChat = action.payload;
		},
		resetOpenUserChat: (state) => {
			state.openUserChat = undefined;
		},
		setLoggedUsersChat: (state, action: PayloadAction<UserChat[]>) => {
			state.loggedUsers = action.payload;
		},
		setActiveRooms: (state, action: PayloadAction<any[]>) => {
			state.activeRooms = action.payload;
		},
		addActiveRooms: (state, action: PayloadAction<any>) => {
			state.activeRooms = [...state.activeRooms, action.payload];
		},
		setPublicChannel: (state, action: PayloadAction<any[]>) => {
			state.publicChannel = action.payload || [];
		},
		setRoomsChannels: (state, action: PayloadAction<any[]>) => {
			state.roomsChannels = action.payload || [];
		},
		setStandChannels: (state, action: PayloadAction<any[]>) => {
			state.standChannels = action.payload || [];
		},
		setChannels: (state, action: PayloadAction<any[]>) => {
			state.channels = action.payload;
		},
		setSelectedChannel: (state, action: PayloadAction<any>) => {
			state.selectedChannel = action.payload;
		},
		setSelectedLastChannel: (state, action: PayloadAction<any>) => {
			state.lastSelectedChannel = action.payload;
		},
		setMinifyChannel: (state, action: PayloadAction<any>) => {
			state.minifyChannel = action.payload;
		},
		addUpdateChannel: (state, action: PayloadAction<any>) => {
			state.channels = [
				action.payload,
				...state.channels.filter((ele) => ele.url !== action.payload.url),
			];
		},
		setOpenChatMobile: (state, action: PayloadAction<boolean>) => {
			state.openChatMobile = action.payload;
		},
	},
});

export const {
	setNotify,
	setChannels,
	addUpdateChannel,
	setPublicChannel,
	setRoomsChannels,
	setStandChannels,
	setSelectedChannel,
	setSelectedLastChannel,
	setUsersChat,
	setLoggedUsersChat,
	setMinifyChannel,
	setActiveRooms,
	addActiveRooms,
	setOpenUserChat,
	setLoading,
	resetOpenUserChat,
	setOpenChatMobile,
} = chatSlice.actions;

const getChannelInfo = (channel: any) => {
	return {
		...objWithProperties(channel, [
			"coverUrl",
			"memberCount",
			"joinedMemberCount",
			"customType",
			"data",
			"unreadMessageCount",
			"name",
			"url",
		]),
		...(channel?.members?.length
			? { members: channel.members.map((m: any) => getMemberInfo(m)) }
			: {}),
	};
};

const getMemberInfo = (member: any) => {
	return objWithProperties(member, [
		"connectionStatus",
		"friendDiscoveryKey",
		"isActive",
		"lastSeenAt",
		"nickname",
		"state",
		"userId",
		"plainProfileUrl",
		"profileUrl",
	]);
};

const getChannelInfoByArray = (channels: any[]) => {
	return channels.map((ele) => getChannelInfo(ele));
};

export const splitPublicChannel =
	(channels: any[] = []): AppThunk =>
	(dispatch) => {
		dispatch(
			setRoomsChannels(
				getChannelInfoByArray(
					channels.filter((ele) => ele.customType.includes("room"))
				)
			)
		);
		dispatch(
			setPublicChannel(
				getChannelInfo(
					channels.find((ele) => ele.customType.includes("public"))
				)
			)
		);
		dispatch(
			setStandChannels(
				getChannelInfoByArray(
					channels.filter((ele) => ele.customType.includes("stand"))
				)
			)
		);
	};

export const setChannelsAction =
	(channels: any[] = []): AppThunk =>
	(dispatch) => {
		dispatch(setChannels(getChannelInfoByArray(channels)));
	};
export const setMinifyChannelAction = (): AppThunk => (dispatch, getState) => {
	const selectedChannel = getState().chat.selectedChannel;
	selectedChannel && dispatch(setMinifyChannel(selectedChannel));
};
export const addUpdateChannelAction =
	(channel: any): AppThunk =>
	(dispatch) => {
		dispatch(addUpdateChannel(getChannelInfo(channel)));
	};

export const createNewChatAction =
	(sdkInstance: any, users: string[], callback?: () => void): AppThunk =>
	(dispatch, getState) => {
		const channels = getState().chat.channels;
		const channel = channels.find(
			(ele) =>
				ele.memberCount === users.length + 1 &&
				users.reduce(
					(acc, e) => ele.members.map((el: any) => el.userId).includes(e),
					true
				)
		);
		if (channel) {
			dispatch(setChannelPrivate(channel));
			callback && callback();
		} else {
			sdkInstance.GroupChannel.createChannelWithUserIds(
				users,
				function (groupChannel: GroupChannel, error: any) {
					if (error) {
						// Handle error.
					}
					dispatch(addUpdateChannelAction(groupChannel));
					dispatch(setChannelPrivate(groupChannel));
					callback && callback();

					// A group channel of the specified users is successfully created.
					// Through the "groupChannel" parameter of the callback function,
					// you can get the group channel's data from the result object that Sendbird server has passed to the callback function.
					// const channelUrl = groupChannel.channelUrl;
				}
			);
		}
	};

export const setSelectedChannelAction =
	(channel: any, mobileChat: boolean = true): AppThunk =>
	(dispatch) => {
		dispatch(setSelectedChannel(channel));
		mobileChat && dispatch(setOpenChatMobile(true));
	};

export const setChannelPublic =
	(channel: any): AppThunk =>
	(dispatch) => {
		dispatch(
			setSelectedChannelAction({
				url: channel.url,
				name: channel.name,
				type: "public",
			})
		);
	};
export const setChannelPrivate =
	(channel: any, mobileChat: boolean = true): AppThunk =>
	(dispatch) => {
	dispatch(
			setSelectedChannelAction({
				url: channel.url,
				members: channel.members.map((m: any) => getMemberInfo(m)),
				type: "private",
			}, mobileChat)
		);
	};

export const resetCloseSelectedChannel =
	(): AppThunk => (dispatch, getState) => {
		const selectedChannel = getState().chat.selectedChannel;
		dispatch(setSelectedLastChannel(selectedChannel));
		dispatch(setSelectedChannelAction(undefined, false));
	};
export const initSelectedChannel = (): AppThunk => (dispatch, getState) => {
	const selectedChannel = getState().chat.lastSelectedChannel;
	if (selectedChannel) {
		dispatch(setSelectedChannelAction(selectedChannel, false));
	}
};

export const activeOpenChatAction =
	(callback: () => void): AppThunk =>
	(dispatch, getState) => {
		const area = getState().area.currentArea;
		const rooms = getState().chat.roomsChannels;
		const stands = getState().chat.standChannels;
		const eventId = getState().event.settings?.id;
		const userId = getState().auth.user?.id;
		const selectedRoom = [...rooms, ...stands].find(
			(ele) => ele.data?.toString() === area?.toString()
		);

		if (area) {
			setChatActive(eventId, area, userId).then((res) => {
				dispatch(addActiveRooms({ id: area }));
				dispatch(
					setSelectedLastChannel({
						url: selectedRoom?.url,
						name: selectedRoom?.name,
						type: "public",
					})
				);
				callback && callback();
			});
		}
	};

export const getChatActiveAction = (): AppThunk => (dispatch, getState) => {
	const userId = getState().auth.user?.id;
	const eventId = getState().event.settings?.id;
	getChatActive(eventId, userId).then((res) => {
		dispatch(setActiveRooms(res.chat_areas));
	});
};

export const setUsersChatAction =
	(users: UserChat[] = []): AppThunk =>
	(dispatch, getState) => {
		const loggedUser = getState().auth.user;
		const event = getState().event.settings;
		const filterUsers = users
			.filter(
				(ele) =>
					ele.userId.toString().split("___")[1] &&
					ele.userId.toString().split("___")[1] === event?.id.toString() &&
					ele.userId.toString() !==
						`${loggedUser?.id.toString()}___${event?.id}`
			)
			.map((us) => getMemberInfo(us));
		dispatch(setUsersChat(filterUsers));
		dispatch(
			setLoggedUsersChat(
				filterUsers.filter((ele: any) => ele.connectionStatus === "online")
			)
		);
	};

export const selectNotify = (state: RootState) => state.chat.notify;
export const selectUsersChat = (state: RootState) => state.chat.users;
export const selectLoggedUsersChat = (state: RootState) =>
	state.chat.loggedUsers;
export const selectChannels = (state: RootState) => state.chat.channels;
export const selectPublicChannel = (state: RootState) =>
	state.chat.publicChannel;
export const selectRoomsChannelsAll = (state: RootState) =>
	state.chat.roomsChannels;
export const selectStandChannelsAll = (state: RootState) =>
	state.chat.standChannels;
export const selectSelectedChannel = (state: RootState) =>
	state.chat.selectedChannel;
export const selectMinifyChannel = (state: RootState) =>
	state.chat.minifyChannel;
export const selectOpenUserChat = (state: RootState) => state.chat.openUserChat;
export const selectLoadingChat = (state: RootState) => state.chat.loading;
export const selectActiveRooms = (state: RootState) => state.chat.activeRooms;
export const selectOpenChatMobile = (state: RootState) => state.chat.openChatMobile;
export const selectRoomsChannels = createSelector(
	selectRoomsChannelsAll,
	selectActiveRooms,
	(rooms, actives) => {
		return rooms.filter((ele) =>
			actives.find((el) => el.id.toString() === ele.data)
		);
	}
);
export const selectStandChannels = createSelector(
	selectStandChannelsAll,
	selectActiveRooms,
	(stands, actives) => {
		return stands.filter((ele) =>
			actives.find((el) => el.id.toString() === ele.data)
		);
	}
);
export default chatSlice.reducer;
