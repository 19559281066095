import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import { AuthState, DockArea } from "./model/Auth";
import { User } from "./model/User";
import { logoutCall, updateProfile } from "./authAPI";
import {
	addArea,
	addAreasAction,
	setCurrentAreaAction,
	setInitAreaId,
} from "../../features/auth/authHome/area/areaSlice";
import http from "../../components/shared/utils/http";
import {
	getObjectByArray,
	objWithProperties,
} from "../../components/shared/utils/objUtils";
import { formatUser } from "./authUtils";
import { getChatActiveAction } from "../../features/auth/chatSlice";
import { saveLog } from "../services/analiticsAPI";
import { setLanguage } from "../../features/public/event/eventSlice";
import { getParameter } from "../../components/shared/utils/urlUtils";
import { resetMessages } from "../messages/messageSlice";

const initialState: Partial<AuthState> = {
	user: undefined,
	profileBaseData: undefined,
	token: undefined,
	isLogged: undefined,
	bigBanner: undefined,
	excluded_features: [],
	permissions: [],
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setIsLogged: (state, action: PayloadAction<boolean>) => {
			state.isLogged = action.payload;
		},
		setUser: (state, action: PayloadAction<User>) => {
			state.user = action.payload;
		},
		setExcludedFeatures: (state, action: PayloadAction<string[]>) => {
			state.excluded_features = action.payload;
		},
		resetExcludedFeatures: (state) => {
			state.excluded_features = [];
		},
		setProfileBaseData: (state, action: PayloadAction<any>) => {
			state.profileBaseData = action.payload;
		},
		setBigBanner: (state, action: PayloadAction<any>) => {
			state.bigBanner = action.payload;
		},
		setPrivacy: (state, action: PayloadAction<string | undefined>) => {
			state.privacy = action.payload;
		},
		destroyUser: (state) => {
			state.user = undefined;
		},
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		destroyToken: (state) => {
			state.token = undefined;
		},
		setPermissions: (state, action: PayloadAction<string[]>) => {
			state.permissions = action.payload;
		},
	},
});

export const {
	setUser,
	destroyUser,
	setToken,
	destroyToken,
	setPermissions,
	setPrivacy,
	setBigBanner,
	setProfileBaseData,
	setExcludedFeatures,
	resetExcludedFeatures,
	setIsLogged,
} = authSlice.actions;
export const autoLogin = (): AppThunk => (dispatch) => {
	getParameter("token") &&
		localStorage.setItem("token", getParameter("token") as string);
	let token = localStorage.getItem("token");
	if (token) {
		dispatch(setLoggedToken(token));
	} else {
		dispatch(setIsLogged(false));
	}
};

export const setRefreshData =
	(event_id: number): AppThunk =>
	(dispatch) => {
		if (localStorage.getItem("token")) {
			http.post(`auth/refresh`, { event_id }).then((res) => {
				dispatch(setInitDataLogin(res));
			});
		}
	};

export const setInitWithToken =
	(res: any): AppThunk =>
	(dispatch) => {
		localStorage.setItem("token", res.token);
		dispatch(setLoggedToken(res.token));
		dispatch(setInitDataLogin(res));
	};

export const setInitDataLogin =
	(res: any): AppThunk =>
	(dispatch, getState) => {
		const user = formatUser(res);
		const eventId = getState().event.settings?.id;
		const defaultAreaId = getState().event.defaultArea;
		dispatch(
			setProfileBaseData({
				...getObjectByArray(res.profile_values),
				email: user.email,
			})
		);
		dispatch(setUser({ ...user, idChat: `${user?.id}___${eventId}` }));
		dispatch(setLanguage(res.lang));
		dispatch(setPrivacy(res["privacy_" + res.lang]));
		dispatch(setExcludedFeatures(res.excluded_features));
		saveLog({
			type: "session",
			event_id: eventId,
			user_id: user.id,
			data: JSON.stringify(
				objWithProperties(navigator, [
					"platform",
					"userAgent",
					"userAgentData",
					"language",
					"languages",
				])
			),
		}).then((res) => {
			console.log(res);
		});
		dispatch(addArea(res.area));
		dispatch(setInitAreaId(res.area.id));
		dispatch(addAreasAction(res.area.next_areas));
		dispatch(
			setBigBanner({ path: res.big_banner_path, module: res.big_banner_module })
		);
		dispatch(
			setCurrentAreaAction(defaultAreaId ? +defaultAreaId : res.area.id)
		);
		dispatch(getChatActiveAction());
	};

export const setUserAndPermissions =
	(user: User): AppThunk =>
	(dispatch) => {
		// dispatch(setPermissions(user.permissions));
		dispatch(setUser(user));
	};

export const updateProfileAction =
	(data: any, callback?: (user: User) => void): AppThunk =>
	(dispatch, getState) => {
		const eventId = getState().event.settings?.id;
		const userId = getState().auth.user?.id;
		// dispatch(setPermissions(user.permissions));
		eventId &&
			userId &&
			updateProfile(eventId, userId, data).then((res) => {
				dispatch(setUser({ ...getState().auth.user, ...data }));
				dispatch(
					setProfileBaseData({ ...getState().auth.profileBaseData, ...data })
				);
				callback && callback({ ...getState().auth.user, ...data });
			});
	};

export const setLoggedToken =
	(token: string): AppThunk =>
	(dispatch) => {
		dispatch(setToken(token));
		dispatch(setIsLogged(true));
	};

export const setLoggedTokenUser =
	(token: string, user: User): AppThunk =>
	(dispatch) => {
		dispatch(setLoggedToken(token));
		dispatch(setUserAndPermissions(user));
	};

export const logoutAction = (): AppThunk => (dispatch, getState) => {
	const token = getState().auth.token;
	logoutCall(token).then((res) => {
		dispatch(destroyAfterLogout());
	});
};

export const destroyAfterLogout = (): AppThunk => (dispatch, getState) => {
	localStorage.removeItem("token");
	dispatch(destroyUser());
	dispatch(setPermissions([]));
	dispatch(destroyToken());
	dispatch(setIsLogged(false));
	dispatch(resetMessages());
	dispatch(resetExcludedFeatures());
};

export const saveLogAction =
	({ id, type }: { id: string | number; type: string }): AppThunk =>
	(dispatch, getState) => {
		const eventId = getState().event.settings?.id;
		const userId = getState().auth.user?.id;
		saveLog({
			type: "action",
			analyticable_id: id,
			analyticable_type: type,
			user_id: userId,
			event_id: eventId,
		}).then((res) => {});
	};

export const setUserAvatar =
	(avatar: string): AppThunk =>
	(dispatch, getState) => {
		const user = getState().auth.user;
		user && dispatch(setUser({ ...user, avatar }));
	};

export const selectUser = (state: RootState) => state.auth.user;
export const selectProfileBaseData = (state: RootState) =>
	state.auth.profileBaseData;
export const selectBigBanner = (state: RootState) => state.auth.bigBanner;
export const selectToken = (state: RootState) => state.auth.token;
export const selectIsLogged = (state: RootState) => state.auth.isLogged;
export const selectExcludedFeatures = (state: RootState) =>
	state.auth.excluded_features;
export const selectPrivacy = (state: RootState) => state.auth.privacy;
export const selectUserPermissions = (state: RootState) =>
	state.auth.permissions;

export default authSlice.reducer;
