import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../../../app/store";
import { IArea } from "../model/AreaModel";
import {saveLog} from "../../../../app/services/analiticsAPI";
import {saveLogAction} from "../../../../app/auth/authSlice";


export interface AreaState{
  areas: IArea[]
  currentArea?: number
  activeElementArea?: any
  initAreaId?: number
  historyArea: number[]
}

const initialState: AreaState = {
  areas: [],
  historyArea: [],
  activeElementArea: undefined
};

export const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    addAreas: (state, action: PayloadAction<IArea[]>) => {
      state.areas = [...state.areas, ...action.payload];
    },
    addArea: (state, action: PayloadAction<IArea>) => {
      state.areas = [...state.areas, action.payload];
    },
    setInitAreaId: (state, action: PayloadAction<number>) => {
      state.initAreaId =  action.payload;
    },
    setCurrentArea: (state, action: PayloadAction<number> ) => {
      state.currentArea = action.payload;
    },
    setActiveElementArea: (state, action: PayloadAction<any> ) => {
      state.activeElementArea = action.payload;
    },
    setHistoryArea: (state, action: PayloadAction<number[]> ) => {
      state.historyArea = action.payload;
    },
    addHistoryArea: (state, action: PayloadAction<number> ) => {
      state.historyArea = [...state.historyArea, action.payload];
    },
    removeHistoryArea: (state, action: PayloadAction<number> ) => {
      state.historyArea = state.historyArea.filter(ele => ele !== action.payload);
    },
    removeLastHistoryArea: (state ) => {
      state.historyArea = state.historyArea.filter((ele, index) => index < (state.historyArea.length -2));
    },
    resetHistoryArea: (state, action: PayloadAction<number[]> ) => {
      state.historyArea = [];
    },
    resetActiveElementArea: (state ) => {
      state.activeElementArea = undefined;
    }
  },
});

export const {
  addArea,
  addAreas,
  setCurrentArea,
  setActiveElementArea,
  setInitAreaId,
  resetActiveElementArea,
  setHistoryArea,
  removeHistoryArea,
  addHistoryArea,
  removeLastHistoryArea,
  resetHistoryArea
} = areaSlice.actions;
export const setCurrentAreaAction = (id: number): AppThunk => (dispatch, getState) => {
  dispatch(setCurrentArea(id))
  dispatch(addHistoryArea(id));
  dispatch(saveLogAction({id, type: "App\\Models\\Area"}))
};
export const addAreasAction = (areas: IArea[]): AppThunk => (dispatch) => {
  dispatch(addAreas(areas))
};

export const clickHistoryPrev = (callback: () => void): AppThunk => (dispatch, getState) => {
  const currentArea = getState().area.currentArea;
  currentArea && dispatch(removeLastHistoryArea());
  callback && callback()
};

export const selectAreas = (state: RootState) => state.area.areas;
export const selectHistoryArea = (state: RootState) => state.area.historyArea;
export const selectCurrentArea = (state: RootState) => state.area.currentArea;
export const selectActiveElementArea = (state: RootState) => state.area.activeElementArea;
export const selectInitAreaId = (state: RootState) => state.area.initAreaId;
export const selectArea = createSelector(selectAreas, selectCurrentArea, (areas, idArea) => {
  return areas.find(ele => ele.id === idArea)
})
export const selectDockAreas = createSelector(selectArea, (area) => {
  return area?.dock_areas?.dock_areas || []
})
//(state: RootState) => state.auth.dockAreas;
export const selectPrevArea = createSelector(selectHistoryArea, (history) => {
  return history.length > 1 && history[history.length-2]
})

export default areaSlice.reducer;
