import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatUser } from "../../../../app/auth/authUtils";
import { User } from "../../../../app/auth/model/User";
import { selectIsMobile } from "../../../../app/generalSettingsSlice";
import CardDetail from "../../../../components/shared/design/card-detail/CardDetail";
import http from "../../../../components/shared/utils/http";
import CalendarRow from "./components/CalendarRow";
import ProfileDetail from "./components/ProfileDetail";
import {
	addCalendarMeetingCall,
	getCalendarMeetingCall,
} from "../sections/calendar/calendarAPI";
import PrimaryButton from "../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import ProfileSummary from "../sendbird/components/profile/ProfileSummary";
import { calcVh } from "../../../../components/shared/utils/vhUtils";
import CardAppointmentProfile from "./CardAppointmentProfile";

const CardAppointment = ({
	user,
	closeHandler,
	showProfile,
	callUserHandler,
	openChatUser,
	eventId,
}: {
	eventId: number;
	user: User;
	closeHandler: () => void;
	callUserHandler: (data: any) => void;
	openChatUser: (userId: string) => void;
	showProfile: string;
}) => {
	const { t } = useTranslation();
	const [profile, setProfile] = useState<any>({});
	const [modalActionInfo, setModalActionInfo] = useState<any>(undefined);
	const isMobile = useSelector(selectIsMobile);
	const [calendarEvents, setCalendarEvents] = useState([]);
	const [calendarStatus, setCalendarStatus] = useState<boolean>();
	const [fullHeighBox, setFullHeightBox] = useState<"closed" | "open">(
		"closed"
	);

	const initCalendarCall = () => {
		getCalendarMeetingCall(eventId, showProfile).then((res) => {
			setCalendarEvents(res.meeting_slots);
			setCalendarStatus(!!res.active_calendar);
		});
	};
	useEffect(() => {
		http.get(`profiles/${eventId}/${showProfile}`).then((res) => {
			setProfile({ ...formatUser(res), idChat: `${showProfile}___${eventId}` });
			initCalendarCall();
		});
	}, [showProfile]);
	const setMeeting = (info: any) => {
		addCalendarMeetingCall(info.id, showProfile).then((res) => {
			initCalendarCall();
			setModalActionInfo(undefined);
		});
	};

	return (
		<div className="no-close-outside">
			{!!modalActionInfo && (
				<CardDetail
					style={{
						zIndex: 6,
					}}
					headerTitle="Scegli azione"
					closeHandler={() => setModalActionInfo(undefined)}>
					<p>
						Prenota appuntamento con {profile?.name} {profile?.surname}{" "}
					</p>
					<PrimaryButton
						className="mb-2"
						size="sm"
						onClick={() => setMeeting(modalActionInfo.event)}>
						Prendi appuntamento
					</PrimaryButton>
				</CardDetail>
			)}
			{calendarStatus != undefined && (
				<CardAppointmentProfile
					isMobile= {isMobile}
					fullHeighBox = {fullHeighBox}
					profile = {profile}
					showProfile = {showProfile}
					closeHandler = {closeHandler}
					callUserHandler = {callUserHandler}
					openChatUser = {openChatUser}
					setFullHeightBox = {setFullHeightBox}
					calendarEvents = {calendarEvents}
					setModalActionInfo = {setModalActionInfo}
					calendarStatus = {calendarStatus}
				/>
			)}
			
		</div>
	);
};

export default CardAppointment;
