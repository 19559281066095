import React, {useEffect, useState} from "react";
import { useForm } from "../../../../components/shared/utils/forms/useForm";
import { UseFormSubmitModel } from "../../../../components/shared/utils/forms/models/UseFormModel";
import FormElements from "../../../../components/shared/utils/forms/FormElements";
import { createStructureForm } from "../../../../components/shared/utils/forms/useStructureForm";
import { FormElementModelNew } from "../../../../components/shared/utils/forms/models/FormElementModel";
import {Col, Row} from "react-bootstrap";
import PrimaryButton from "../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import { useTranslation } from "react-i18next";
import * as validationTypeName from "../../../../components/shared/utils/forms/utils/validationType";
import {useSelector} from "react-redux";
import {selectCountdownLogin} from "../../event/eventSlice";
import {stringify} from "querystring";
import {calcDiffDaysHoursMinutesToday} from "../../../../components/shared/utils/date";
import {useCountDown} from "../../useCountDown";


const FormLogin = ({ disableLogin, loginHandler, allowGuest, loginGuest }: { disableLogin: boolean, loginHandler: (data: any) => void, allowGuest: boolean, loginGuest: () => void }) => {
	const { t } = useTranslation();
	const countdown = useSelector(selectCountdownLogin);
	const {dateCountdown} = useCountDown(countdown)
	const data: FormElementModelNew[] = createStructureForm([
		{
			typeElement: "input",
			label: "Email",
			name: "email",
			dataElement: {
				placeholder: "email",
				type: "text",
			},
		},
		{
			typeElement: "input",
			label: "Password",
			name: "password",
			dataElement: {
				placeholder: "password",
				type: "password",
			},
		},
		
	]);

	const submitHandler: UseFormSubmitModel = (event, data) => {
		loginHandler(data);
	};
	const structureForm = createStructureForm(
		data?.map((ele) => ({
			typeElement: ele.typeElement,
			label: ele.label,
			name: ele.name,
			dataElement: { type: ele?.dataElement?.type },
			col: { xs: 12 },
		}))
	);

	const { isSubmit, errors, submitAction, changeValue, dataForm } =
		useForm(submitHandler, {
			email: {
				[validationTypeName.required]: {
					params: { name: "email" },
				},
				[validationTypeName.email]: {
					params: { name: "email" },
				}
			},
			password: {
				[validationTypeName.required]: {
					params: { name: "password" },
				}
			},});

	return (
		<>
			<form name="loginForm" id="loginForm" onSubmit={submitAction}>
				{!allowGuest && <FormElements
					data={structureForm}
					errors={errors}
					isSubmit={isSubmit}
					dataForm={dataForm}
					changeValue={changeValue}
				/>}
				<Row>
					<Col>
						{allowGuest ? <PrimaryButton
							disabled={disableLogin}
							className="my-2" onClick={loginGuest}>
								{t("login.login").toUpperCase()}
						</PrimaryButton> : <PrimaryButton
							disabled={disableLogin}
							className="my-2">
							{t("login.login").toUpperCase()}
						</PrimaryButton> }
						{dateCountdown && <div className="text-center">
							<h4 className="mt-4 text-counter opacity-50">{dateCountdown && dateCountdown}</h4>
						</div>}

					</Col>
				</Row>
			</form>

		</>
	);
};

export default FormLogin;
