import React from "react";
import { SpotModel } from "../../model/AreaModel";
import {AreaSingleMapTooltip} from "./AreaSingleMapTooltip";

export function AreaSingleMapText({
	dimension,
	ar,
	clickArea
}: {
	dimension: any
	ar: SpotModel;
	clickArea: (ar: any) => void;
}) {
	return (
		<AreaSingleMapTooltip ar={ar}>
			<div style={dimension.text.style} onClick={() => clickArea(ar)}>
				{dimension.text.text}
			</div>
		</AreaSingleMapTooltip>
	);
}
