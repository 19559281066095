import React, { useRef, useState } from "react";

interface IGridCardImageModel extends React.HtmlHTMLAttributes<HTMLDivElement> {
	path: string;
}
export function GridCardActionTypeImage({
	path,
	...rest
}: IGridCardImageModel) {
	const imgRef = useRef<HTMLImageElement>(null);
	const [style, setStyle] = useState<any>({});
	const loadImg = () => {
		setStyle({
			[(imgRef.current?.naturalWidth || 0) >
			(imgRef.current?.naturalHeight || 0)
				? "height"
				: "width"]: "100%",
		});
	};
	return (
		<div className="pointer">
			<img ref={imgRef} style={style} onLoad={loadImg} src={path} {...rest} />
		</div>
	);
}
