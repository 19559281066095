import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { date_ggmmyyyy_hhmm } from "../../../../components/shared/utils/date";
import { getObjectByArray } from "../../../../components/shared/utils/objUtils";

export default function ItemPoster({ poster, clickPosterHandler }: any) {
	const { t } = useTranslation();
	const icon = process.env.REACT_APP_URL_BACKEND + "/img/icons/link.svg";

	const user = getObjectByArray(
		poster.profile_values.filter(
			(ele: any) => ele.name && typeof ele.value === "string"
		)
	);

	return (
		<Row>
			<Col className="item-list">
				{/* <div className="d-flex justify-content-between  align-items-center mb-3 w-100"> */}
				<div>
					<div className="d-flex flex-column mb-3">
						<div className="d-flex">
							<img
								src={icon}
								alt=""
								style={{ width: "20px", marginRight: "10px" }}
							/>
							<div
								className={`title pointer`}
								onClick={() => clickPosterHandler(poster.file_path)}>
								{poster.title}
							</div>
						</div>
						<div>
							{poster.subheading}
						</div>
					</div>
					<div className="d-flex justify-content-end flex-wrap">
						<div>	
							<small>{t("posters.createdBy")}</small>
							<small className="ml-1">
								{user.name} {user.surname}
							</small>
						</div>
						<div>
							<small className="ml-2">{t("posters.on")}</small>
							<small className="ml-1">
								{date_ggmmyyyy_hhmm(poster.uploaded_at)}
							</small>
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
}
