import React from "react";

const ItemRoom = ({
	item,
	clickHandler,
	isActive,
}: {
	item: any;
	clickHandler: () => void;
	isActive: boolean;
}) => {
	return (
		<div
			className={`item-room ${isActive ? "active" : ""}`}
			onClick={clickHandler}>
			<img className="cover" src={item.coverUrl} />
			<span>{item.name}</span>
		</div>
	);
};

export default ItemRoom;
