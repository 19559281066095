import React from "react";
import { SpotModel } from "../../model/AreaModel";
import {AreaSingleMapTooltip} from "./AreaSingleMapTooltip";

export function AreaSingleMapArea({
	dimension,
	ar,
	clickArea
}: {
	dimension: any
	ar: SpotModel;
	clickArea: (ar: any) => void;
}) {
	const diff = dimension.backgroundWidth - window.innerWidth;
	const style: any = {
		position: "absolute",
		cursor: "pointer",
		bottom: (dimension.backgroundHeight - dimension.y - dimension.height) + 'px',
		left: (dimension.x - (diff? diff/2 : 0)) + 'px',
		height:dimension.height + 'px',
		width:dimension.width + 'px',
	}
	return (
		<>
			<area
				href="#"
				shape={ar.type}
				onClick={() => clickArea(ar)}
				coords={dimension?.coords}
				alt={ar.title}
				id={ar.id}
			/>
			<AreaSingleMapTooltip ar={ar}>
				<div style={style} onClick={() => clickArea(ar)}></div>
			</AreaSingleMapTooltip>
		</>
	);
}
