import React from "react";
import {useSelector} from "react-redux";
import {selectPrivacy} from "../../../app/auth/authSlice";
import {selectIsMobile} from "../../../app/generalSettingsSlice";
const Privacy = () => {
    const isMobile = useSelector(selectIsMobile)
    const stylePrivacy: any = {position: 'fixed',
        [isMobile? 'top': 'bottom']: 0,
        left: 0,
        zIndex: isMobile? 6000 : 1,
        fontSize: isMobile? '0.7em':'0.8em',
        ...(isMobile? {} : {borderTopRightRadius: '8px', background: 'white',}),

        padding: isMobile? '0.2rem 1.1rem' : '0.5rem 1rem'};
    const privacy = useSelector(selectPrivacy);
    return <>
        {privacy && <div style={stylePrivacy} dangerouslySetInnerHTML={{__html: privacy}} />}
    </>
}

export default Privacy;
