import React, { useEffect, useState } from "react";
import { User } from "../../../../../../app/auth/model/User";
import CardDetail from "../../../../../../components/shared/design/card-detail/CardDetail";
import { SettingsModel } from "../../../../../public/event/eventSlice";
import http from "../../../../../../components/shared/utils/http";
import { formatUser } from "../../../../../../app/auth/authUtils";
import { objDeleteProperties } from "../../../../../../components/shared/utils/objUtils";
import { useTranslation } from "react-i18next";
import EditProfileForm from "./EditProfileForm";
import ProfileImage from "./ProfileImage";
import ProfileSummary from "./ProfileSummary";
import ElementProfileDetail from "../../../appointment/components/ElementProfileDetail";
import { useDispatch, useSelector } from "react-redux";
import { selectIsMobile } from "../../../../../../app/generalSettingsSlice";
import { setUserAvatar } from "../../../../../../app/auth/authSlice";

const Profile = ({
	user,
	showProfile,
	closeHandler,
	callUserHandler,
	event,
	openChatUser,
}: {
	event: SettingsModel;
	user: User;
	showProfile: string | boolean;
	callUserHandler: (data: any) => void;
	openChatUser: (userId: string) => void;
	closeHandler: () => void;
}) => {
	const { t } = useTranslation();
	const [profile, setProfile] = useState<any>({});
	const [editMode, setEditMode] = useState(false);
	const isMobile = useSelector(selectIsMobile);
	const dispatch = useDispatch();
	useEffect(() => {
		if (typeof showProfile === "string") {
			const [user_id, event_id] = showProfile.split("___");
			http.get(`profiles/${event_id}/${user_id}`).then((res) => {
				setProfile({ ...formatUser(res), idChat: `${user_id}___${event_id}` });
			});
		} else {
			setProfile(user);
		}
	}, [showProfile, user]);
	const uploadAvatar = (data: any, callback: () => void) => {
		const [user_id, event_id] = user.idChat.split("___");
		let formData = new FormData();
		formData.append("avatar_path", data, data.name);
		http.post(`profiles/avatars/${event_id}/${user_id}`, formData).then(
			(res) => {
				dispatch(setUserAvatar(res.avatar));
				callback && callback();
			},
			(err) => {
				callback && callback();
			}
		);
	};
	return (
		<div className="center-div-custom no-close-outside">
			<CardDetail
				style={{
					width: "100%",
					maxWidth: "1100px",
					margin: "auto",
				}}
				headerTitle={t("profile.title")}
				closeHandler={closeHandler}>
				<div className="profile">
					<div className="left-panel">
						<ProfileImage
							avatar={profile.avatar}
							editMode={editMode}
							editImageHandler={uploadAvatar}
						/>
					</div>
					<div className="right-panel">
						<ProfileSummary
							profile={profile}
							showProfile={showProfile}
							closeHandler={closeHandler}
							callUserHandler={callUserHandler}
							openChatUser={openChatUser}
							setEditMode={() => setEditMode(true)}
						/>
						{!editMode && user.role !== 'guest' && (
							<div className=" info-detail">
								{Object.keys(
									objDeleteProperties(profile, [
										"name",
										"surname",
										"id",
										"avatar",
										"idChat",
										'role',
									])
								).map((d) => (
									<div>
										<ElementProfileDetail d={d} value={profile[d]} />
									</div>
								))}
							</div>
						)}
						{editMode && (
							<EditProfileForm
								setProfile={setProfile}
								cancelEdit={() => setEditMode(false)}
							/>
						)}
					</div>
				</div>
			</CardDetail>
		</div>
	);
};

export default Profile;
