import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardDetail from "../../../../../components/shared/design/card-detail/CardDetail";
import Icon from "../../../../../components/shared/icon/Icon";
import { getListUserChat } from "../../SendBirdChat";
import SearchRow from "./SearchRow";
import { useForm } from "../../../../../components/shared/utils/forms/useForm";
import { setEventUsersAction } from "../../../../public/event/eventSlice";
import { selectLoggedUsersChatWithInfo } from "../../../chatSelector";
import { useFilterUsers } from "./useFilterUsers";
import { useTranslation } from "react-i18next";
import { selectLoadingChat } from "../../../chatSlice";
import { calcVh } from "../../../../../components/shared/utils/vhUtils";
export default function ListUsersCall({
	closeHandler,
	callUserHandler,
	setShowProfile,
	openChatUser,
}: {
	closeHandler: () => void;
	callUserHandler: (ele: any) => void;
	setShowProfile: (data: boolean | string) => void;
	openChatUser: (userId: string) => void;
}) {
	const { t } = useTranslation();
	const loading = useSelector(selectLoadingChat);
	const users = useSelector(selectLoggedUsersChatWithInfo);
	const dispatch = useDispatch();
	// const [users, setUsers] = useState<any[]>([])
	useEffect(() => {
		dispatch(setEventUsersAction());
		getListUserChat();
	}, []);
	const initForm = { sort: "name" };
	const form = useForm(() => {}, {}, initForm);
	const refreshSearch = () => {
		form.setData(initForm);
	};
	const { filterUsers } = useFilterUsers(
		users,
		form.dataFormatted.search,
		form.dataFormatted.sort
	);
	return (
		<CardDetail
			headerTitle={t("card.userCall.title")}
			closeHandler={closeHandler}>
			<SearchRow form={form} refreshSearch={refreshSearch} />

			<div
				className="mt-3"
				style={{ overflow: "auto", maxHeight: `calc( ${calcVh(80)} - 120px)` }}>
				{users && users.length > 0 ? (
					filterUsers.map((ele: any) => (
						<div className="users-row" key={ele.userId}>
							<div className="item-name">
								<img
									className="thumb-user"
									style={{ cursor: !!setShowProfile ? "pointer" : undefined }}
									src={ele.profileUrl}
									onClick={() => setShowProfile(ele.userId)}
								/>
								<div className="ml-3">
									<b>
										{ele.name} {ele.surname}
									</b>
								</div>
							</div>
							<div className="btn-action">
								<span onClick={() => callUserHandler(ele)} className="mr-3">
									<Icon
										icon="videocall"
										className="p-0 pointer"
										size="20px"
										colorStyle="#81358A"
										title={`${t("card.userCall.call")} ${ele.nickname}`}
									/>
								</span>
								<span
									onClick={() => {
										closeHandler && closeHandler();
										openChatUser(ele.userId);
									}}
									className="mr-3">
									<Icon
										icon="msg"
										className="p-0 pointer"
										size="24px"
										title={`${t("card.userCall.contact")} ${ele.nickname}`}
									/>
								</span>
								{/* <span onClick={() => null}>
										<img src={logoIn} width="26" />
									</span> */}
							</div>
						</div>
					))
				) : (
					<div className="d-flex justify-content-center p-5">
						<span>
							{loading ? t("card.userCall.loading") : t("card.userCall.noUser")}
						</span>
					</div>
				)}
			</div>
		</CardDetail>
	);
}
