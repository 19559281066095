import React, { ReactNode, useEffect, useState } from "react";
import CardDetail from "../../../components/shared/design/card-detail/CardDetail";
import { useDispatch, useSelector } from "react-redux";
import {
	resetActiveElementArea,
	selectActiveElementArea,
	selectCurrentArea,
} from "./area/areaSlice";
import http from "../../../components/shared/utils/http";

import { ContentsListCardActionType } from "./cardActionTypes/contents/ContentsListCardActionType";
import { GridCardActionType } from "./cardActionTypes/media/GridCardActionType";
import { SliderCardActionType } from "./cardActionTypes/slider/SliderCardActionType";
import { IframeCardActionType } from "./cardActionTypes/iframe/IframeCardActionType";
import { ArticleCardActionType } from "./cardActionTypes/article/ArticleCardActionType";
import { ArticlegridCardActionType } from "./cardActionTypes/article_grid/ArticlegridCardActionType";
import { LiveCardActionType } from "./cardActionTypes/live/LiveCardActionType";
import { LivelistCardActionType } from "./cardActionTypes/live_list/LivelistCardActionType";

import { Slider } from "@material-ui/core";
import { addSuccessMessage } from "../../../app/errors/errorSlice";
import { getUrl } from "../../../components/shared/utils/urlUtils";
import { useTranslation } from "react-i18next";
import { selectIsMobile } from "../../../app/generalSettingsSlice";
import { calcVh } from "../../../components/shared/utils/vhUtils";
import { Col, Row } from "react-bootstrap";
import QnaComponent from "./cardActionTypes/qna/QnaComponent";

export function CardAction() {
	const { t } = useTranslation();
	const [showQna, setShowQna] = useState(true);
	const elementArea = useSelector(selectActiveElementArea);
	const currentArea = useSelector(selectCurrentArea);
	const isMobile = useSelector(selectIsMobile);

	const [data, setData] = useState<any>();
	const dispatch = useDispatch();
	const closeHandler = () => {
		setData(undefined);
		dispatch(resetActiveElementArea());
	};
	const copyClipboard = () => {
		navigator.clipboard.writeText(
			// `${window.location.origin}${window.location.pathname}?area_id=${currentArea}&module_id=${data.module.id}`
			process.env.REACT_APP_URL_SHARE + `?type=module&id=${data.module.id}`
		);
		dispatch(
			addSuccessMessage({
				title: t("actions.copyClipboard"),
			})
		);
	};
	const body: { [key: string]: ReactNode } = {
		list: (
			<ContentsListCardActionType
				copyClipboard={copyClipboard}
				contents={data?.module?.contents}
			/>
		),
		grid: <GridCardActionType data={data} copyClipboard={copyClipboard} />,
		slider: <SliderCardActionType data={data} />,
		iframe: <IframeCardActionType data={data} />,
		article: <ArticleCardActionType data={data} />,
		articles_grid: (
			<ArticlegridCardActionType
				copyClipboard={copyClipboard}
				articles={data?.module?.articles}
			/>
		),
		live: <LiveCardActionType data={data} openQna={() => setShowQna(true)} />,
		lives_list: <LivelistCardActionType data={data?.module?.live_modules} />,
	};

	useEffect(() => {
		if (elementArea) {
			http.get(elementArea.actions.link).then((res) => setData(res));
		}
	}, [elementArea]);
	const size = data?.module?.size;
	const style: any = {
		position: "fixed",
		top: "88px",
		left: "50%",
		transform: "translateX(-50%)",
		height: `calc( ${calcVh(100)} - ${isMobile ? "162px" : "190px"})`,
		width: "80%",
		maxWidth: "100%",
		margin: "auto",
		...(!isMobile && size === "medium-large"
			? {
					width: "100%",
					maxWidth: "1100px",
			  }
			: {}),
		...(!isMobile && size === "medium"
			? {
					height: `${calcVh(58)}`,
					maxWidth: "700px",
					top: `calc( ${calcVh(10)} + 90px)`,
			  }
			: {}),
		...(!isMobile && size === "aside"
			? {
					width: "300px",
					left: 0,
					transform: "none",
					margin: "15px",
			  }
			: {}),
	};

	return (
		<>
			{elementArea && data && (
				<>
					<CardDetail
						style={style}
						headerTitle={data?.module?.name || t("card.title")}
						closeHandler={closeHandler}>
						{data?.module?.type && body[data.module.type]}
					</CardDetail>
					{!!data?.module?.live?.qa && showQna && (
						<QnaComponent
							closeAction={() => setShowQna(false)}
							title={t("card.qna.title")}
							liveId={data?.module.live.id}
						/>
					)}
				</>
			)}
		</>
	);
}
