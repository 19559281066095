import {createSelector} from "@reduxjs/toolkit";
import {selectEventUsers} from "../public/event/eventSlice";
import {getUserByIdUserChat} from "../../components/shared/utils/chatUtils";
import {selectLoggedUsersChat, selectUsersChat} from "./chatSlice";

export const selectLoggedUsersChatWithInfo = createSelector(selectLoggedUsersChat, selectEventUsers, (chatUsers, usersEvent) => {
    return chatUsers
        .filter(ele => usersEvent.find((el : any) => el.user_id === getUserByIdUserChat(ele.userId)))
        .map(ele => {
            const user = usersEvent.find((el : any) => el.user_id === getUserByIdUserChat(ele.userId));
            return {...ele, ...(user? user : {})}
        });
});
export const selectUsersChatWithInfo = createSelector(selectUsersChat, selectEventUsers, (chatUsers, usersEvent) => {
    return usersEvent
        .filter((ele: any) => chatUsers.find((el : any) => ele.user_id === getUserByIdUserChat(el.userId)))
        .map((ele: any) => {
            const user = chatUsers.find((el : any) => ele.user_id === getUserByIdUserChat(el.userId)) ;
            return {...ele, ...(user? user : {})}
        });
});
