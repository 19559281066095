import React from "react";

export default function PublicChannel({
	channel,
	setPublicChannel,
	isActive,
}: {
	channel: any;
	setPublicChannel: (channel: any) => void;
	isActive?: boolean;
}) {
	return (
		<div className={`channel-name ${isActive ? "active" : ""}`}>
			{channel && (
				<>
					<img className="cover" src={channel.coverUrl} />
					<div className="title">
						<h5 onClick={() => setPublicChannel(channel)}>
							{channel.name}
						</h5>
						<small>{channel.participantCount} Utenti attivi</small>
					</div>
				</>
			)}
		</div>
	);
}
