import React from "react";
import { MsgProps } from "../models/MsgProps";
import MsgPropsStyle from "../msg/models/MsgProps";
import Msg from "../msg/Msg";

const styleSuccess: MsgPropsStyle = {
	style: { color: "#000", bgColor: "#63b8cb" },
	icon: { color: "#000", ico: "" },
};

const MsgSuccess = ({
	className,
	title,
	body,
	showToast = true,
	closeAction,
	...rest
}: MsgProps) => {
	return (
		<Msg
			showToast={showToast}
			className="shared-toastSuccess"
			toastStyle={styleSuccess}
			title={title}
			body={body}
			closeAction={() => closeAction && closeAction()}></Msg>
	);
};

export default MsgSuccess;
