import React, {useEffect, useState} from "react";
import Select from "../../../../components/shared/design/form/select/Select";
import {useSelector} from "react-redux";
import {selectAreas, selectCurrentArea} from "../area/areaSlice";


const SelectNextArea = ({
	changeArea
}: {
	changeArea: (id: number) => void
}) => {
	const [value, setValue] = useState<any>('');
	const areas = useSelector(selectAreas)
	const currentArea = useSelector(selectCurrentArea);
	const changeValue = (val: any) => {
		setValue(val)
		changeArea(+val)
	}
	useEffect(() => {
		setValue(currentArea)
	}, [currentArea])
	return (
		<Select
			name="test"
			options={areas.map(ele => ({value: ele.id, label: ele.name}))}
			value={value}
			className="inputBordered"
			menuPlacement={"bottom"}
			changeSelect={changeValue}
		/>
	);
};

export default SelectNextArea;
