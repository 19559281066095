import React, { useEffect } from "react";
import "./sass/app.scss";

import { Event } from "./features/public/event/Event";
import { useDispatch } from "react-redux";
import { autoLogin } from "./app/auth/authSlice";
import { setIsMobileAction } from "./app/generalSettingsSlice";
import { vhUtils } from "./components/shared/utils/vhUtils";

function App() {
	const dispatch = useDispatch();
	useEffect(() => {
		if (
			/iPad|iPhone|iPod/.test(navigator.platform) ||
			(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
		) {
			window.addEventListener(
				"touchmove",
				function (event: any) {
					if (event.scale !== 1) {
						event.preventDefault();
					}
				},
				{ passive: false }
			);
		}
		vhUtils();
		dispatch(autoLogin());
		dispatch(setIsMobileAction());
	}, []);
	return (
		<div className="App">
			<Event />
		</div>
	);
}

export default App;
