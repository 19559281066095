import React from "react";

const HeaderTabCalendar = ({ tabs }: { tabs: any[] }) => {
	return (
		<div className="header-tab-calendar">
			{tabs.map((ele) => {
				return (
					<button className={ele.isActive ? "active" : ""} onClick={ele.action}>
						{ele.label}
					</button>
				);
			})}
		</div>
	);
};

export default HeaderTabCalendar;
