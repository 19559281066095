import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import BackBtn from "../../../../../components/shared/backBtn/BackBtn";
import ItemContentsListCardActionType from "../ItemContentsListCardActionType";
import {
	addFavouriteContent,
} from "../../sections/favorites/favoriteAPI";
import { useTranslation } from "react-i18next";
import {saveLogAction} from "../../../../../app/auth/authSlice";
import {useDispatch} from "react-redux";
import {IframeCustom} from "../iframe/IframeCustom";

export function ContentsListCardActionType({
	contents,
	copyClipboard,
	removefavorite,
}: {
	contents: any;
	copyClipboard: () => void;
	removefavorite?: (content: any) => void;
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [pathShow, setPathShow] = useState<any>(undefined);
	const setFavourite = (content: any) => {
		addFavouriteContent(content.id).then((res) => console.log(res));
	};
	return (
		<div className="container-fluid" style={{ height: "calc(100% - 50px)" }}>
			{!pathShow &&
				contents.map((content: any) => {
					return (
						<ItemContentsListCardActionType
							setFavourite={setFavourite}
							removefavorite={removefavorite}
							key={content.id}
							content={{
								...content,
								title: content.name,
							}}
							setPathShow={(path: any, content) => {
								dispatch(saveLogAction({id: content.id, type: "App\\Models\\Content"}))
								content.new_tab ? window.open(path) : setPathShow(content);
							}}
							copyClipboard={copyClipboard}
						/>
					);
				})}
			{pathShow && (
				<>
					<Row className="justify-content-end">
						<Col xs="auto">
							<BackBtn onClick={() => setPathShow(undefined)} />
						</Col>
					</Row>
					<IframeCustom content={pathShow} />
				</>
			)}
		</div>
	);
}
