import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { ITheme } from "../model/AreaModel";
import {
	default as menuDark,
	default as menuLight,
} from "./../../../../assets/img/menu/menu-dark.svg";
import iconShare from "./../../../../assets/img/icon/share.svg";
import goBack from "./../../../../assets/img/icon/goBack.svg";
import SelectNextArea from "./SelectNextArea";
import {useDispatch, useSelector} from "react-redux";
import { selectExcludedFeatures } from "../../../../app/auth/authSlice";
import {clickHistoryPrev, selectInitAreaId, selectPrevArea} from "../area/areaSlice";
import { selectSettingEvent } from "../../../public/event/eventSlice";

const NavbarApp = ({
	theme,
	logo,
	onLogout,
	setShowProfile,
	copyClipboard,
	changeArea,
}: {
	theme: ITheme;
	logo: string;
	onLogout: () => void;
	setShowProfile: (data: boolean) => void;
	copyClipboard: () => void;
	changeArea: (id: number) => void;
}) => {
	const iconMenu = {
		light: menuLight,
		dark: menuDark,
	};
	const setting = useSelector(selectSettingEvent);
	const prevHistory = useSelector(selectPrevArea);
	const dispatch = useDispatch();
	const excluded = useSelector(selectExcludedFeatures);
	const initAreaId = useSelector(selectInitAreaId);
	const setInitArea = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		initAreaId && changeArea(initAreaId);
	};
	const goBackAction = () => {
		if(prevHistory){
			const callback = () => {
				changeArea(prevHistory)
			}
			dispatch(clickHistoryPrev(callback));
		};
	};
	return (
		<Navbar expand="lg" fixed="top" className="navbar-app">
			<div>
				<Navbar.Brand
					href="#"
					onClick={setInitArea}
					className={`flap left ${theme}`}>
					<img
						height="42px"
						src={
							logo ||
							"https://dev-rest.purplebox.live/storage/logo_events/event_logo_placeholder.png"
						}
					/>
				</Navbar.Brand>
			</div>
			<div className="ml-auto">
				<div className="d-flex align-items-center">
					<Navbar.Collapse id="navbarScroll" className={`flap right ${theme}`}>
						<Nav
							className="d-flex align-items-center flex-row"
							style={{ maxHeight: "100px" }}
							navbarScroll>
							{/* <div style={{ width: "80px" }} className="mr-2">
									<LanguageSelector />
								</div> */}

							<img
								src={goBack}
								width="20"
								className="mr-3 pointer"
								onClick={goBackAction}
								aria-disabled={!prevHistory}
							/>
							{!excluded?.includes("areas_search") && (
								<div className="mr-3 hide-mobile" style={{ width: "150px" }}>
									<SelectNextArea changeArea={changeArea} />
								</div>
							)}
							<div className="mr-3" onClick={copyClipboard}>
								<img src={iconShare} width="20" className="pointer" />
							</div>
							{!setting.autologin_guest &&
								<NavDropdown
									alignRight
									title={<img src={iconMenu[theme]} width="28" />}
									id="navbarScrollingDropdown">
									{/* <NavDropdown.Divider /> */}
									<NavDropdown.Item onClick={() => setShowProfile(true)}>
										<span>Profile</span>
									</NavDropdown.Item>
									<NavDropdown.Item onClick={onLogout}>
										<span>Logout</span>
									</NavDropdown.Item>
								</NavDropdown>
							}
						</Nav>
					</Navbar.Collapse>
				</div>
			</div>
		</Navbar>
	);
};

export default NavbarApp;
