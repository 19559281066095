import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BackBtn from "../../../../../components/shared/backBtn/BackBtn";
import iconBag from "../../../../../assets/img/icon/bag.svg";
import iconNoBag from "../../../../../assets/img/icon/noBag.svg";
import iconShare from "../../../../../assets/img/icon/share.svg";
import { GridCardActionTypeIframe } from "./GridCardActionTypeIframe";
import { GridCardActionTypeImage } from "./GridCardActionTypeImage";
import { GridCardActionTypeVideo } from "./GridCardActionTypeVideo";
import { addFavouriteContent } from "../../sections/favorites/favoriteAPI";
import { saveLogAction } from "../../../../../app/auth/authSlice";
import { useDispatch } from "react-redux";

export function GridCardMediaList({
	contents,
	copyClipboard,
	removefavorite,
}: {
	contents: any[];
	copyClipboard: () => void;
	removefavorite?: (content: any) => void;
}) {
	const [contentItem, setContentItem] = useState<any>(undefined);
	const dispatch = useDispatch();
	useEffect(() => {
		contentItem &&
			dispatch(
				saveLogAction({ id: contentItem.id, type: "App\\Models\\Content" })
			);
	}, [contentItem]);
	const typeContents: any = (data: any) => {
		return {
			video: (
				<GridCardActionTypeVideo
					path={data.path}
					onClick={(e) => {
						e.preventDefault();
						setContentItem(data);
					}}
				/>
			),
			media: (
				<GridCardActionTypeVideo
					path={data.path}
					onClick={(e) => {
						e.preventDefault();
						setContentItem(data);
					}}
				/>
			),
			image: (
				<GridCardActionTypeImage
					path={data.path}
					onClick={() => setContentItem(data)}
				/>
			),
			url: (
				<GridCardActionTypeIframe
					path={data.path}
					onClick={(e) => {
						e.preventDefault();
						data?.new_tab ? window.open(data.path) : setContentItem(data);
					}}
				/>
			),
		};
	};
	const setFavourite = (content: any) => {
		addFavouriteContent(content.id).then((res) => console.log(res));
	};

	return (
		<>
			<div className="grid-container">
				{!contentItem &&
					contents &&
					contents.length > 0 &&
					contents.map((content: any) => {
						return (
							<div className="item-grid">
								<div className="element">
									{typeContents(content)[content.type]}
								</div>
								<div className="caption">
									<span
										onClick={() => setContentItem(content)}
										title={content?.name}>
										{content?.name}
									</span>
									<div className="btn-tool">
										<div>
											{!removefavorite ? (
												<img
													src={iconBag}
													width="16"
													onClick={() => setFavourite(content)}
												/>
											) : (
												<img
													src={iconNoBag}
													width="16"
													onClick={() =>
														removefavorite && removefavorite(content)
													}
												/>
											)}
										</div>
										{!removefavorite && (
											<div onClick={copyClipboard}>
												<img src={iconShare} width="20" />
											</div>
										)}
									</div>
								</div>
							</div>
						);
					})}
			</div>
			{contentItem && (
				<div
					className="content-grid-fullscreen"
					style={{ height: "calc(100% - 50px)" }}>
					<Row className="justify-content-between mb-3">
						<Col xs="2"></Col>
						<Col xs="auto">{contentItem.name}</Col>
						<Col xs="2" className="d-flex justify-content-end">
							<BackBtn onClick={() => setContentItem(undefined)} />
						</Col>
					</Row>
					<Row className="justify-content-center h-100-controlled">
						<Col
							xs="12"
							className="d-flex justify-content-center h-100-controlled">
							{typeContents(contentItem)[contentItem.type]}
						</Col>
					</Row>
				</div>
			)}
		</>
	);
}
