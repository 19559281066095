import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ItemLive from "./ItemLive";
import { SelectedLive } from "./SelectedLive";
import {
	addFavouriteArticle,
	addFavouriteLive,
} from "../../sections/favorites/favoriteAPI";

export function LivelistCardActionType({
	data,
	removefavorite,
}: {
	data: any[];
	removefavorite?: (content: any) => void;
}) {
	const { t } = useTranslation();
	const [selectedLive, setSelectedLive] = useState<any>();
	const setFavourite = (content: any) => {
		addFavouriteLive(content.id).then((res) => console.log(res));
	};
	return (
		<>
			{selectedLive ? (
				<SelectedLive
					data={selectedLive}
					goBack={() => setSelectedLive(undefined)}
				/>
			) : (
				<div>
					{data && data.length > 0 ? (
						data.map((ele) => (
							<ItemLive
								key={ele.id}
								setFavourite={setFavourite}
								setSelectedLive={() => setSelectedLive(ele)}
								content={ele}
								removefavorite={removefavorite}
							/>
						))
					) : (
						<div>{t("no_content")}</div>
					)}
				</div>
			)}
		</>
	);
}
