import React from "react";
import CardRounded from "../../../components/shared/cards/card/cardRounded/CardRounded";
import HeadingH2 from "../../../components/shared/headings/headingH2/HeadingH2";
import FormLogin from "./components/FormLogin";
import { classByArray } from "../../../components/shared/functions/function";
import { useTranslation } from "react-i18next";

export function Login({
	inAnimation,
	disableLogin,
	loginHandler,
	allowGuest,
	loginGuest,
	logo
}: {
	inAnimation: boolean;
	disableLogin: boolean;
	loginHandler: (data: any) => void;
	allowGuest: boolean;
	loginGuest: () => void;
	logo: string;
}) {
	const { t } = useTranslation();

	const classList = classByArray([
		"login-box",
		...(inAnimation ? ["in"] : []),
		...(disableLogin ? ["disabled"] : []),
		...(allowGuest ? ["login-guest"] : [])
	]);

	return (
		<>	
			{allowGuest &&
				<div className={classList}>
					<CardRounded>
						<div
							style={
								disableLogin ? { pointerEvents: "none" } : {}
							}>
							<HeadingH2 className="login-title">
								{allowGuest ? <img src={logo} alt="logo" className="logo" /> : t("login.alredyRegistered")}
							</HeadingH2>
							<FormLogin
								allowGuest={allowGuest}
								disableLogin={disableLogin}
								loginHandler={loginHandler}
								loginGuest={loginGuest}
							/>

						</div>
					</CardRounded>
				</div>
			}
			<div className={classList}>
				<CardRounded>
					<div
						style={
							disableLogin ? { pointerEvents: "none" } : {}
						}>
						<HeadingH2 className="login-title">
							{t("login.alredyRegistered")}
						</HeadingH2>
						<FormLogin
							allowGuest={false}
							disableLogin={disableLogin}
							loginHandler={loginHandler}
							loginGuest={loginGuest}
						/>

					</div>
				</CardRounded>
			</div>
		</>
	);
}
