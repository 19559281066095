import React from "react";
import {useTranslation} from "react-i18next";

const RightPanel = ({
	listMember,
	clickMemberHandler,
}: {
	listMember: any[];
	clickMemberHandler: (userId: string) => void;
}) => {
	const {t} = useTranslation();
	return (
		<div className="right-panel">
			<div className="panel">
				<h5>{t('chat.participants')}</h5>
				{listMember &&
					listMember.length &&
					listMember.map((ele) => (
						<p
							className="item-member"
							onClick={() => clickMemberHandler(ele.userId)}>
							<img src={ele.profileUrl} /> {/* manca immagine */}
							<span>{ele.nickname}</span>
						</p>
					))}
			</div>
		</div>
	);
};

export default RightPanel;
