import React, {useEffect, useRef, useState} from "react";

export function IframeResizeDevelopment({ development, path, title= 'iframe' }: { development?: string; path: string, title?: string }) {
	const [iframe_style, setIframeStyle] = useState<React.CSSProperties>({
		height: "100%",
		width: "100%",
		margin: "auto",
		border: "none"
	});

	const contentIframeRef = useRef<HTMLDivElement>(null);
	const calcSize = (target?: HTMLElement | null) => {
		if(target && development && development !== 'full-width'){
			const [width, height] = development.split(':')
			const style: React.CSSProperties = {
				aspectRatio: `${width}/${height}`,
				[((+width)/(+height)) < (target.clientWidth/target.clientHeight)? 'height': 'width']: '100%'
			}
			setIframeStyle(style)
		}
	}
	const loadContent = (event: any) => {
		calcSize(event.target)
	}

	useEffect(() => {
		window.addEventListener('resize', () => calcSize(contentIframeRef?.current))
	}, [])

	return (
			<div className="iframe-container"  ref={contentIframeRef} onLoad={loadContent}>
				<iframe
					allow="camera; microphone; fullscreen"
					title={title}
					src={path}
					style={iframe_style}></iframe>
			</div>
	);
}
