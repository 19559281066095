import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from "./store";

export interface GeneralSettingsModel{
    isMobile: boolean;
}



const initialState: GeneralSettingsModel = {
    isMobile: false
};



export const generalSettingsSlice = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    }
  }
});

export const { setIsMobile } = generalSettingsSlice.actions;


export const setIsMobileAction = (): AppThunk => (dispatch) => {
    dispatch(setIsMobile(window.matchMedia("(max-width: 992px)").matches))
    window.addEventListener('resize', () => {
        dispatch(setIsMobile(window.matchMedia("(max-width: 992px)").matches))
    })
};


export const selectIsMobile = (state: RootState) => state.generalSettings.isMobile;

export default generalSettingsSlice.reducer;
