import {useEffect, useState} from "react";
import {calcDiffDaysHoursMinutesToday} from "../../components/shared/utils/date";

let timeout: any;
export function useCountDown(countdown?: boolean | string){
    const [dateCountdown, setDateCountdown] = useState<any>();
    useEffect(() => {
        countdown && setTimeoutCount()
        return () => {
            timeout && clearTimeout(timeout);
        }
    }, [countdown])
    const setTimeoutCount = () => {
        timeout && clearTimeout(timeout);
        calcCountDown()
        timeout = setTimeout(() => {
            calcCountDown()
        }, 60000)
    }
    const calcCountDown = () => {
        const calc = calcDiffDaysHoursMinutesToday(countdown)
        setDateCountdown(`${calc.days? calc.days + 'd ' : ''}${calc.hours? calc.hours + 'h ' : ''}${calc.minutes? calc.minutes + 'm ' : ''}`)
    }
    return {
        dateCountdown
    }
}
