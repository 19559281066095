import React from "react";
import PopoverCustom from "../../../../../components/shared/tooltip/PopoverCustom";
import { SpotModel } from "../../model/AreaModel";

export function AreaSingleMapTooltip({
	ar,
	children,
}: {
	children: React.ReactNode;
	ar: SpotModel;
}) {

	return (
		<>
			{!!ar.tooltip?.enable_tooltip &&
			!!ar.tooltip_content?.squares_settings?.containers.length &&
			!!ar.tooltip_content.squares_settings.containers[0]?.settings?.elements
				.length &&
			!!ar.tooltip_content.squares_settings.containers[0].settings.elements[0]
				?.options?.heading?.text ? (
				<PopoverCustom
					tooltipBodyTemplate={
						ar.tooltip_content.squares_settings.containers[0].settings
							.elements[0].options.heading.text
					}
					tooltipStyle={ar?.tooltip_style}
						
					>
					{children}
				</PopoverCustom>
			) : (
				children
			)}
		</>
	);
}
