import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from "./store";

export interface menuVoiceMobile{
    label: string;
    ico: string;
}
export interface MenuMobileModel{
    dock: menuVoiceMobile,
    calendar: menuVoiceMobile,
    favourite: menuVoiceMobile,
    chat: menuVoiceMobile
}

export type listVoiceType = keyof MenuMobileModel | 'area'

export interface MobileModel{
    selectedView: listVoiceType
}



const initialState: MobileModel = {
    selectedView: 'area'
};



export const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setSelectedView: (state, action: PayloadAction<listVoiceType>) => {
      state.selectedView = action.payload;
    },
    resetSelectedView: (state) => {
      state.selectedView = 'area'
    }
  }
});

export const { setSelectedView, resetSelectedView } = mobileSlice.actions;




export const selectSelectedViewMobile = (state: RootState) => state.mobile.selectedView;

export default mobileSlice.reducer;
