import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LanguageSelector from "../../components/shared/languageSelector/LanguageSelector";
import { SettingsModel } from "./event/eventSlice";
import { loginAction } from "./login/loginSlice";
import { pages } from "./publicData";
import PublicHome from "./PublicHome/PublicHome";
import PublicRegisterContainer from "./PublicHome/PublicRegisterContainer";

interface ISwitch extends SettingsModel {}

export const SwitchPage = (settings: ISwitch) => {
	const [currentPage, setCurrentPage] = useState<string>(pages.base);
	const [inAnimation, setInAnimation] = useState<boolean>(false);
	const dispatch = useDispatch();

	const styleBg = {
		backgroundImage: `url(${settings.login_bg_path})`,
		transition: "opacity 0.8s",
	};
	const goBase = () => {
		setCurrentPage(pages.base);
	};

	useEffect(() => {
		setTimeout(() => {
			setInAnimation(true);
		}, 200);
	}, []);

	const delayUnmountAnimation = (route: string, data?: any, callbackReset?: () => void, guest: boolean = false) => {
		if (route === pages.login) {
			setInAnimation(false);
			setTimeout(() => {
				dispatch(loginAction(data, guest,() =>{
					setInAnimation(true)
					callbackReset && callbackReset()
				}));
			}, 400);
		} else {
			route === pages.registration && setCurrentPage(route);
		}
	};




	return (
		<div
			className={`login-container ${inAnimation ? "fadeIn" : "fadeOut"}`}
			style={styleBg}>
			<div
				style={{
					width: "80px",
					position: "fixed",
					zIndex: 100,
					top: "20px",
					left: "30px",
				}}
				className="mr-auto">
				<LanguageSelector />
			</div>
			{currentPage === pages.base && (
				<PublicHome
					{...settings}
					setCurrentPage={delayUnmountAnimation}
				/>
			)}
			{currentPage === pages.registration && (
				<PublicRegisterContainer {...settings} goBase={goBase} />
			)}
		</div>
	);
};

export default SwitchPage;
