import React from "react";
import Icon from "../icon/Icon";

const BackBtn = ({ label = "Indietro", ...rest }) => {
	return (
		<div className="pointer">
			<Icon icon="arrow_back" label={label} {...rest}></Icon>
		</div>
	);
};

export default BackBtn;
