import React, { useState } from "react";
import { optionMenu } from "./menu/desktop/menuTool/menuData";
import CardDetail from "../../../components/shared/design/card-detail/CardDetail";
import { Calendar } from "./sections/calendar/Calendar";
import HeaderTabCalendar from "./component/HeaderTabCalendar";
import { useTranslation } from "react-i18next";
import { Favorites } from "./sections/favorites/Favorites";
import { selectIsMobile } from "../../../app/generalSettingsSlice";
import { useSelector } from "react-redux";
import { calcVh } from "../../../components/shared/utils/vhUtils";

export function CardInfo({
	type,
	closeAction,
	changeArea,
	openProfile,
}: {
	type: string;
	closeAction: (data: string) => void;
	changeArea?: (id: number) => void;
	openProfile: (id: string) => void;
}) {
	const { t } = useTranslation();
	const closeHandler = () => {
		closeAction && closeAction(type);
	};
	const [showGeneral, setShowGeneral] = useState<boolean>();
	const isMobile = useSelector(selectIsMobile);

	const headerTabs = [
		{
			name: "c-event",
			label: t("card.calendar.event"),
			isActive: !showGeneral,
			action: () => setShowGeneral(false),
		},
		{
			name: "c-personal",
			label: t("card.calendar.personal"),
			isActive: showGeneral,
			action: () => setShowGeneral(true),
		},
	];

	const data = {
		[optionMenu.calendar]: (
			<Calendar
				showGeneral={!showGeneral}
				changeArea={changeArea}
				closeHandler={closeHandler}
				openProfile={openProfile}
			/>
		),
		[optionMenu.suitcase]: <Favorites closeHandler={closeHandler}></Favorites>,
	};

	const dataHeader = {
		[optionMenu.calendar]: <HeaderTabCalendar tabs={headerTabs} />,
		[optionMenu.suitcase]: <h5>{t("card.favorites.title")}</h5>,
	};
	return (
		<CardDetail
			style={{
				position: "fixed",
				top: "88px",
				left: "50%",
				transform: "translateX(-50%)",
				height: `calc( ${calcVh(100)} - ${isMobile ? "162px" : "190px"})`,
				width: "100%",
				maxWidth: "1100px",
				margin: "auto",
			}}
			headerTemplate={dataHeader[type]}
			closeHandler={closeHandler}>
			{data[type]}
		</CardDetail>
	);
}
