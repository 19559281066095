import React  from "react";
import CardDetail from "../../../../../components/shared/design/card-detail/CardDetail";
import { useTranslation } from "react-i18next";
import Register from "../../../../public/register/Register";
import {useSelector} from "react-redux";
import {selectSettingEvent} from "../../../../public/event/eventSlice";
export default function AuthRegister({
	closeHandler
}: {
	closeHandler: () => void;
}) {
	const { t } = useTranslation();
	const setting = useSelector(selectSettingEvent);
	return (
		<CardDetail
			headerTitle={t("card.auth.register.title")}
			closeHandler={closeHandler}>
			<Register goLogin={() => {}} successCallback={closeHandler} changeProfileId={() => console.log('change')} profiles={setting.profiles}/>
		</CardDetail>
	);
}
