import React from "react";
import Sendbird from "sendbird";
import { User } from "../../../../../app/auth/model/User";

export default function ChannelPreview({
	channel,
	userLogged,
	setChannel,
	isActive,
}: {
	channel: Sendbird.GroupChannel;
	userLogged: string;
	setChannel: (channel: Sendbird.GroupChannel) => void;
	isActive: boolean;
}) {
	const members: any[] = channel.members
		.filter(
			(ele) => ele.userId.toString() !== userLogged
		);

	const coverUrl = members && members.length === 1 ? members[0].profileUrl : channel.coverUrl;
	return (
		<>
			<div className="list-rooms">
				<div
					className={`item-room ${isActive ? "active" : ""}`}
					onClick={() => setChannel(channel)}>
					<div className="position-relative">
						<img className="cover" src={coverUrl} />
						{!!channel.unreadMessageCount && (
							<div className="notify">{channel.unreadMessageCount}</div>
						)}
					</div>
					<div className="d-flex flex-column overflow-hidden">
						<span className="text-ellipse">
							{
								members.map((c: any) => c.nickname || c.userId)
								.join(", ")}
						</span>
						<span className="state">{members.find(c => c.connectionStatus === 'online')? 'connesso' : 'non connesso'}</span>
					</div>
				</div>
			</div>
		</>
	);
}
