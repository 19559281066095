export const separator = '___';

export const createUserIdChat = (userId: number | string, eventId: number) => {
  return  userId + separator + eventId
}

export const getDataByIdUserChat = (userIdChat: string) => {
  const [userId, eventId] = userIdChat.toString().split('___');
  return {userId, eventId}
}

export const getEventByIdUserChat = (userIdChat: string) => {
  return +getDataByIdUserChat(userIdChat).eventId
}

export const getUserByIdUserChat = (userIdChat: string) => {
  return +getDataByIdUserChat(userIdChat).userId
}
