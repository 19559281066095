import React from "react";


export default function ElementProfileDetail({
	d,
	value
}: {
	d: string;
	value: string;
}) {
	return (
		<>
			<h6>{d}: </h6>
			<span>
				{value && !value.startsWith('http') && d !== 'email' && value}
				{value && value.startsWith('http') && <a href={value} target="_blank">{value}</a>}
				{value && d === 'email' && <a  target="_blank" href={'mailto:' + value}>{value}</a>}
			</span>
		</>

	);
}
