import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { mergeClass } from "../../../functions/function";
import { inputType } from "../../../utils/forms/models/FormElementModel";
import PrimaryButton from "../buttons/primary-button/PrimaryButton";

const InputFile: React.FC<inputType & { value?: any }> = ({
	children,
	className,
	icon,
	firstLabel,
	type,
	error = false,
	value = "",
	name,
	onChangeAction = () => {},
	buttonIcon = "",
	buttonIconClick,
	...rest
}) => {
	const { t } = useTranslation();
	const classMerge: string = mergeClass(
		"shared-input file",
		error ? " error" : ""
	);
	const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChangeAction &&
			onChangeAction(
				{
					file: event?.target?.files && event?.target?.files[0],
					value: event.target.value,
				},
				event
			);
	};
	return (
		<InputGroup className="sharedInputGroup">
			<FormControl
				id={"input-file_" + name}
				type={"file"}
				className={mergeClass(classMerge, className)}
				onChange={changeInput}
				value={value?.value || ""}
				{...rest}
			/>
			<div className="shared-input">
				<PrimaryButton type="button" size="sm" className="btn-upload">
					<label htmlFor={"input-file_" + name} className="mb-0">
						{t("actions.uploadFile")}
					</label>
				</PrimaryButton>
				{value?.file?.name}
			</div>
		</InputGroup>
	);
};

export default InputFile;
