import React, {RefObject, useEffect, useRef, useState} from "react";

const InputFileUpdate = ({
	editImageHandler,
	 inputRef
}: {
	editImageHandler: (data: any, callback: () => void) => void;
	inputRef: RefObject<HTMLInputElement>;
}) => {
	const callback = () => {
		inputRef?.current?.value && (inputRef.current.value = "");
	}
	const onUploadFiles = (event: any) => {
		event?.target?.files[0] && editImageHandler(event.target.files[0], callback)
	}

	return (
		<>
			<input ref={inputRef}
				   style={{display: 'none'}}
				   type="file"
				   accept="image/*"
				   name="file-upload"
				   onChange={onUploadFiles} />
		</>
	);
};

export default InputFileUpdate;
