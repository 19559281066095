import { createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../app/store';
import {getParameter, getUrl} from "../../../components/shared/utils/urlUtils";
import {eventSetting, eventUsers} from "./eventAPI";
import {resetForm, setProfilesAction} from "../register/registerSlice";
import {Profile} from "../../../models/Profile";
import { loginAction } from "../login/loginSlice";

export interface SettingsModel{
    id: number;
    lang: string;
    login: number;
    login_bg_path: string;
    logo_path: string;
    description_it: any;
    description_en: any;
    name: string;
    profiles: Profile[];
    registration: number;
    allow_guest: number;
}

export interface EventState {
  settings: SettingsModel | undefined;
  init?: Boolean;
  users: any[];
  language: string;
  languages: string[];
  defaultArea?: string,
  defaultModule?: string;
}

const initialState: EventState = {
    settings: undefined,
    language: 'it',
    users: [],
    languages: []
};



export const eventSlice = createSlice({
  name: 'event',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setEventSettings: (state, action: PayloadAction<any>) => {
      state.settings = action.payload;
    },
    setEventInit: (state, action: PayloadAction<Boolean>) => {
      state.init = action.payload;
    },
      setDefaultArea:(state, action: PayloadAction<string | undefined>) => {
          state.defaultArea = action.payload;
      },
      setDefaultModule:(state, action: PayloadAction<string | undefined>) => {
          state.defaultModule = action.payload;
      },
      setLanguage: (state, action: PayloadAction<string>) => {
          state.language = action.payload;
      },
      setLanguages: (state, action: PayloadAction<string[]>) => {
          state.languages = action.payload;
      },
      setEventUsers: (state, action: PayloadAction<any[]>) => {
          state.users = action.payload;
      },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

});

export const { setEventInit, setEventSettings, setLanguage, setLanguages, setDefaultArea, setDefaultModule, setEventUsers } = eventSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSettingsEvent = (state: RootState) => state.event.settings;

export const setEvent = (): AppThunk => (dispatch) => {
  const value = getUrl();
    eventSetting(value).then(
        (res) => {
            dispatch(setDefaultDataAction())
            document.title= res.name
            const languages = res?.lang?.split(',') || ['it'];
            const favicon = document?.querySelector('link[rel=icon]')
            if(favicon)
                (favicon as any).href=res.logo_path;
            dispatch(setProfilesAction(res.profiles))
            dispatch(setEventSettings(res))
            // dispatch(setEventSettings({...res, registration: 0, registration_start: res.live_start}))
            dispatch(setLanguages(languages))
            dispatch(setLanguage(languages[0] || 'it'))
            if (res.autologin_guest){
                dispatch(loginAction({lang: navigator.language}, true))
            }
            dispatch(setEventInit(true));
        },
        (err) => {
            dispatch(setEventInit(false))
        }
    );
};

export const setLanguageAction = (lng: string): AppThunk => (dispatch) => {
    dispatch(setLanguage(lng));
    dispatch(resetForm())
};

export const setDefaultDataAction = (): AppThunk => (dispatch) => {
    const area_id = getParameter('area_id');
    const module_id = getParameter('module_id');
    area_id && dispatch(setDefaultArea(area_id))
    module_id && dispatch(setDefaultModule(module_id))
};

export const setEventUsersAction = (): AppThunk => (dispatch, getState) => {
    const eventId = getState().event.settings?.id
    eventUsers(eventId as number).then(res => {
        dispatch(setEventUsers(res.users))
    })
};
export const selectInitEvent = (state: any) => state.event.init;
export const selectSettingEvent = (state: any) => state.event.settings;
export const selectLanguage = (state: any) => state.event.language;
export const selectDefaultArea = (state: any) => state.event.defaultArea;
export const selectDefaultModule = (state: any) => state.event.defaultModule;
export const selectLanguages = (state: any) => state.event.languages;
export const selectEventUsers = (state: any) => state.event.users;
export const selectLanguagesOption = createSelector(selectLanguages, (languages) => languages.map((ele: string) => ({label: ele.toUpperCase(), value: ele})))
export const selectCountdownRegister = createSelector(selectSettingEvent, (event) => !event?.registration && event?.registration_countdown && event?.registration_start)
export const selectCountdownLogin = createSelector(selectSettingEvent, (event) => !event?.login && event?.login_countdown && event?.live_start)
export default eventSlice.reducer;
