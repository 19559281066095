import React from "react";

import {GridCardMediaList} from "./GridCardMediaList";

export function GridCardActionType({
	data,
	copyClipboard,
}: {
	data: any;
	copyClipboard: () => void;
}) {
	const contents = data?.module?.contents || [];

	return (
		<GridCardMediaList contents={contents} copyClipboard={copyClipboard} />
	);
}
