import React, {useEffect} from "react";


export default function RenderUserProfileCustom({
	props,
	setShowProfile
}: {
	props: any;
	setShowProfile: (data: boolean | string) => void;
}) {
	useEffect(() => {
		setShowProfile(props.user.userId)
	}, [])
	return null;
}
