export default {
	name: "name",
	surname: "surname",
	no_content: "No Content",
	login: {
		register: "Sign Up",
		login: "Login",
		alredyRegistered: "Already registered?",
		alreadyAccount: "You already have an account?",
		signIn: "Sign In",
	},
	registration: {
		role: "Role *",
		profile: "Profile",
		password_confirmation: "Confirm Password",
		password_message:
			"The password must meet the following requirements: at least 8 characters, at least one capital letter, at least one number.",
	},
	chat: {
		participants: "Participants",
		chatPersonal: "Personal chats",
	},
	profile: {
		title: "Profile",
		updateImgProfile: "Update profile image",
	},
	appointment: {
		title: "Appointments",
		titleList: "Representatives list",
	},
	posters: {
		title: "Poster",
		addModule: "Add module",
		createdBy: "Created by",
		on: "on",
		form: {
			title: "Title",
			subheading: "Subheading",
			abstract: "Text",
			file_path: "File",
		},
	},
	card: {
		title: "Title",
		auth: {
			register: {
				title: 'register to access the service'
			}
		},
		calendar: {
			event: "Event calendar",
			personal: "Personal calendar",
		},
		favorites: {
			title: "Favorites",
			tabs: {
				favourite_articles: "Articles",
				favourite_contents: "Files",
				favourite_media: "Contents",
				favourite_lives: "Lives",
			},
		},
		userCall: {
			title: "User list",
			call: "video call ",
			contact: "contact ",
			noUser: "No users",
			search: "search",
			sort: "sort",
			advancedSearch: "Advanced Search",
			refresh: "Refresh",
		},
		qna: {
			title: 'Live answer and question',
			question: "Domanda",
			questionSended:'Question sended',
		},
	},
	actions: {
		copyClipboard: "Copied content URL",
		cancel: "Annulla",
		save: "Salva",
		uploadFile: "Upload File",
		send: "Send",
	},
	errors: {
		fe: {
			required: "The field  {{name}} is required",
			email: "The field {{name}} is not valid",
			confirm: "The field {{fieldCompare}} must be equal to the field {{name}}",
			minLength: "The field  {{name}} must be at least {{min}} characters",
			regex: "The field {{name}} must respect the following regex: {{regex}} ",
			length: "The field {{name}} must be {{moreData.length}} characters ",
			isNumber: "The field {{name}} must be a number ",
			upperCharacter:
				"The field {{name}} must have at least one capital letter ",
			oneNumber: "The field {{name}} must contain at least one number ",
			specialCharacter:
				"The field {{name}} must contain at least one special character between	@#!~$%^&*()-+/:.,<>?|",
		},
	},
};
