import React, {useEffect, useRef, useState} from "react";
import Icon from "../../icon/Icon";
import {useSelector} from "react-redux";
import {selectIsMobile} from "../../../../app/generalSettingsSlice";

export interface ICardDetail extends React.HtmlHTMLAttributes<HTMLDivElement> {
	headerTemplate?: React.ReactNode;
	headerTitle?: string;
	children: React.ReactNode;
	closeHandler: () => void;
}

const CardDetail = ({
	headerTemplate,
	headerTitle,
	children,
	closeHandler,
	style = {},
	...rest
}: ICardDetail) => {
	const [inAnimation, setInAnimation] = useState<boolean>(false);
	const closeAction = () => {
		setInAnimation(false);
		setTimeout(() => {
			closeHandler && closeHandler()
		}, 200)
	}
	const isMobile = useSelector(selectIsMobile);
	const cardRef = useRef<HTMLDivElement>(null)
	const handleClickOutside = (event: MouseEvent) => {
		// @ts-ignore
		if(!isMobile && !cardRef.current?.contains(event?.target as any) && !([...(document.querySelectorAll('.no-close-outside') || [])]?.find(ele => ele.contains(event?.target as any )))){
			closeAction()
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside);
	})
	useEffect(() => {
		setTimeout(() => {
			setInAnimation(true)
		}, 100);
	}, [])

	const listClass = ['card-detail', (inAnimation ? "fadeIn" : "fadeOut")]
	return (
		<div
			ref={cardRef}
			style={{ transition: "opacity 0.8s", ...style }}
			 className={listClass.join(' ')} {...rest}>
			<div className="header-detail">
				{headerTemplate ? (
					headerTemplate
				) : (
					<h5>{headerTitle}</h5>
				)}
				<Icon icon="close-x" size="14px" colorStyle="#4D4D4D" clickAction={closeAction} />
			</div>

			<div className="body-detail">{children}</div>
		</div>
	);
};

export default CardDetail;
