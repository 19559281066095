import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { ITabsPane } from "./models/tabsModels";

const TabsPane = ({ keyActiveTab, listTabs, onSelect }: ITabsPane) => {
  return (
    <>
      <Tabs
        className="shared-tabs"
        activeKey={keyActiveTab}
        onSelect={onSelect}
      >
        {listTabs.map((ele, key) => {
          return <Tab eventKey={ele.keyTab} title={ele.title} key={key}></Tab>;
        })}
      </Tabs>
    </>
  );
};

export default TabsPane;
