import { createSlice} from '@reduxjs/toolkit';
import {  AppThunk } from '../../../app/store';
import {loginCall, guestLoginCall} from "./loginAPI";
import { setInitWithToken } from "../../../app/auth/authSlice";





const initialState: any = {

};



export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

});

export const {} = loginSlice.actions;



export const loginAction = (data: any, guest:boolean = false, callbackError?: () => void): AppThunk => (dispatch, getState) => {
  const request = {...data, event_id: getState().event?.settings?.id};
  const service = guest ? guestLoginCall : loginCall;
  service(request).then(res => {
    if(res.token)
      dispatch(setInitWithToken(res))
    else
      callbackError && callbackError()
  }).catch(err => callbackError && callbackError());
};







export default loginSlice.reducer;
