import React from "react";

interface IGridCardImageModel extends React.HtmlHTMLAttributes<HTMLDivElement> {
	path: string;
}
export function GridCardActionTypeIframe({
	path,
	...rest
}: IGridCardImageModel) {
	return (
		<div
			className="pointer d-flex w-100"
			style={{ position: "relative" }}
			{...rest}>
			<div className="w-100 h-100" style={{ position: "absolute" }}></div>
			<iframe src={path}></iframe>
		</div>
	);
}
