import {getObjectByArray} from "../../components/shared/utils/objUtils";

export const formatUser = (res: any) => {
    const user = getObjectByArray(res.profile_values.filter((ele: any) => ele.name && typeof ele.value === 'string'));
    user.avatar = res.avatar;
    user.email = res.email;
    user.id = res.user_id;
    user.role = res.role;
    return user;
}
