import React from "react";
import CardRounded from "../../../../../components/shared/cards/card/cardRounded/CardRounded";
import PrimaryButton from "../../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../../components/shared/design/form/buttons/secondary-button/SecondaryButton";
import Icon from "../../../../../components/shared/icon/Icon";

const RingCall = ({
	declineCallHandler,
	acceptCallHandler,
	caller,
}: {
	declineCallHandler: () => void;
	acceptCallHandler: () => void;
	caller: string;
}) => {
	return (
		<CardRounded className="ring-call" styleCard={{ padding: "30px" }}>
			<div className="d-flex flex-column text-center mb-3">
				<b>{caller}</b> ti sta chiamando...
			</div>
			<div className="d-flex justify-content-center">
				<SecondaryButton
					onClick={declineCallHandler}
					className="d-flex align-items-center mr-3">
					<Icon icon="close_call" size="32px" title="Rifiuta" />
				</SecondaryButton>
				<PrimaryButton
					onClick={acceptCallHandler}
					className=" d-flex align-items-center">
					<Icon icon="join_call" size="32px" title="Rispondi" />
				</PrimaryButton>
			</div>
		</CardRounded>
	);
};

export default RingCall;
