import React, {useState} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../components/icon/Icon";
import PrimaryButton from "../../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../../components/shared/design/form/buttons/secondary-button/SecondaryButton";
import FormElements from "../../../../../components/shared/utils/forms/FormElements";
import { FormElementModelNew } from "../../../../../components/shared/utils/forms/models/FormElementModel";
import { UseFormSubmitModel } from "../../../../../components/shared/utils/forms/models/UseFormModel";
import { useForm } from "../../../../../components/shared/utils/forms/useForm";
import { createStructureForm } from "../../../../../components/shared/utils/forms/useStructureForm";
import http from "../../../../../components/shared/utils/http";
import {useSelector} from "react-redux";
import {selectLanguage} from "../../../../public/event/eventSlice";

export default function QnaComponent({ title, closeAction, liveId }: any) {
	const { t } = useTranslation();
	const [showEndCall, setShowEndCall] = useState<string | undefined>();
	const lang = useSelector(selectLanguage);
	const validations = {
		question: {
			required: {
				params: { name: t("card.qna.question") },
			},
		}
	};

	const structureForm: FormElementModelNew[] = createStructureForm([
		{
			typeElement: "textarea",
			label: t("card.qna.question"),
			name: "question",
			dataElement: {
				type: "text",
				classNameGroup: "inputBordered",
			},
			col: { xs: "12" },
		},
	]);
	const submitHandler: UseFormSubmitModel = (event, data) => {
		http.post(`qa/${liveId}`, data).then(res => {
			setShowEndCall(res[lang] || '');
			setTimeout(() => {
				closeAction()
			}, 5000)
		});
	};
	const { isSubmit, errors, submitAction, changeValue, dataForm } = useForm(
		submitHandler,
		validations
	);
	return (
		<div className="qna-box no-close-outside">
			<div className="header">
				<h5 className="mr-3">{title}</h5>
				<Icon
					icon="close-x"
					size="14px"
					colorStyle="#4D4D4D"
					clickAction={closeAction}
				/>
			</div>
			<div className="body">
				{
					<>
						{showEndCall?
							<div className="text-center mt-5">
								<span>{showEndCall}</span>
							</div> :
							<Form onSubmit={submitAction}>
								<FormElements
									isSubmit={isSubmit}
									data={structureForm}
									errors={errors}
									changeValue={changeValue}
									dataForm={dataForm}
								/>
								<Row className="justify-content-end mt-3">
									<Col xs="auto">
										<SecondaryButton type="submit" size="sm" onClick={closeAction}>
											{t("actions.cancel")}
										</SecondaryButton>
									</Col>
									<Col xs="auto">
										<PrimaryButton type="submit" size="sm">
											{t("actions.send")}
										</PrimaryButton>
									</Col>
								</Row>
							</Form>
						}
					</>
				}

			</div>
		</div>
	);
}
