import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectIsMobile } from "../../../../app/generalSettingsSlice";
import CardDetail from "../../../../components/shared/design/card-detail/CardDetail";
import PrimaryButton from "../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import http from "../../../../components/shared/utils/http";
import { calcVh } from "../../../../components/shared/utils/vhUtils";
import AddModule from "./AddModule";
import ListPoster from "./ListPoster";

const ListAppointment = ({
	closeHandler,
	showPosters,
	eventId,
}: {
	eventId: number;
	closeHandler: () => void;
	showPosters: string;
}) => {
	const { t } = useTranslation();
	const isMobile = useSelector(selectIsMobile);
	const [list, setList] = useState<any[]>([]);
	useEffect(() => {
		http.get(showPosters).then((res) => {
			setList(res.posters);
		});
	}, [showPosters]);
	const [addModule, setAddModule] = useState<any>(undefined);
	const [showPoster, setShowPoster] = useState<string | undefined>(undefined);

	return (
		<div className="no-close-outside">
			<CardDetail
				style={{
					position: "fixed",
					top: `${isMobile ? "88px" : "88px"}`,
					left: "50%",
					transform: "translateX(-50%)",
					height: `calc( ${calcVh(100)} - ${isMobile ? "162px" : "190px"})`,
					// width: "100%",
					// maxWidth: "1100px",
					width: "80%",
					maxWidth: "100%",
					margin: "auto",
				}}
				headerTitle={`${t("posters.title")}`}
				closeHandler={closeHandler}>
				<div className="h-100">
					{addModule ? (
						<>
							<AddModule
								cancelHandler={() => setAddModule(false)}
								saveHandler={(newPoster: any) => setList([...list, newPoster])}
								eventId={eventId}
							/>
						</>
					) : (
						<>
							{!showPoster && (
								<Row className="justify-content-end">
									<Col xs="auto">
										<PrimaryButton size="sm" onClick={() => setAddModule(true)}>
											{t("posters.addModule")}
										</PrimaryButton>
									</Col>
								</Row>
							)}
							<ListPoster
								list={list}
								showPoster={showPoster}
								setShowPoster={setShowPoster}
							/>
						</>
					)}
				</div>
			</CardDetail>
		</div>
	);
};

export default ListAppointment;
