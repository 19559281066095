import React from "react";
import { InputGroup } from "react-bootstrap";
import { mergeClass } from "../../../functions/function";
import { radioType } from "../../../utils/forms/models/FormElementModel";

const Radio: React.FC<radioType> = ({
  children,
  className,
  value = "",
  name,
  error = false,
  checked = false,
  label,
  ...rest
}) => {
  const classMerge: string = mergeClass("shared-radio", error ? " error" : "");

  return (
    <InputGroup className={classMerge}>
      <InputGroup.Radio
        checked={checked}
        className="radio"
        value={value}
        name={name}
        {...rest}
      ></InputGroup.Radio>
      {label && <span className="ml-2">{label}</span>}
    </InputGroup>
  );
};

export default Radio;
