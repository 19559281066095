import React from "react";
import { AreaSubComponentModel } from "../model/AreaComponentModel";
import { MobileTemplateName } from "../areaData";

export function AreaMobile({ area, clickArea }: AreaSubComponentModel) {
	const mobileExclude = area?.mobile_exclude || [];
	return (
		<div className="area-content">
			{area && (
				<>
					<div className="container">
						{area.bg_path &&
							area.mobile_template === MobileTemplateName.room_image && (
								<div className="mb-2 text-center w-100">
									<img style={{ maxWidth: "100%" }} src={area.bg_path} />
								</div>
							)}

						{area.logo_path &&
						area.mobile_template === MobileTemplateName.with_logo ? (
							<div className="mb-2 text-center w-100">
								<img style={{ maxWidth: "100%" }} src={area.logo_path} />
							</div>
						) : (
							<div className="item-area active">{area.name}</div>
						)}
						{([...area.bg_json?.spots] || []).filter(ele => !mobileExclude.includes(ele.id)).reverse().map((ar: any) => (
							<div
								className="item-area"
								key={ar.id}
								onClick={() => clickArea(ar)}>
								{ar.title}
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
}
