import React, {useEffect, useState} from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CardRounded from "../../../../components/shared/cards/card/cardRounded/CardRounded";
import SecondaryButton from "../../../../components/shared/design/form/buttons/secondary-button/SecondaryButton";
import HeadingH2 from "../../../../components/shared/headings/headingH2/HeadingH2";
import {calcDiffDaysHoursMinutesToday} from "../../../../components/shared/utils/date";
import {useCountDown} from "../../useCountDown";

export function Registration({
	logo,
	registrationAction,
	inAnimation,
	countdown
}: {
	countdown?: string;
	logo: string;
	registrationAction: () => void;
	inAnimation: boolean;
}) {
	const { t } = useTranslation();
	const {dateCountdown} = useCountDown(countdown)
	return (
		<>
			<div className={`registration-box ${inAnimation ? "in" : ""}`}>
				<CardRounded>
					<HeadingH2 className="login-title">
						<img src={logo} alt="logo" className="logo" />
					</HeadingH2>
					<Row>
						<Col>
							<SecondaryButton disabled={dateCountdown} className="my-2" onClick={registrationAction}>
								{t("login.register").toUpperCase()}
							</SecondaryButton>
							{dateCountdown && <div className="text-center">
								<h4 className="mt-4 text-counter opacity-50"> {dateCountdown && dateCountdown}</h4>
							</div>}
						</Col>
					</Row>
				</CardRounded>
			</div>
		</>
	);
}
