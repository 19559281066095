import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import eventReducer from '../features/public/event/eventSlice';
import authReducer from './auth/authSlice';
import generalSettingsReducer from "./generalSettingsSlice";
import mobileReducer from "./mobileSlice";
import errorReducer from './errors/errorSlice';
import registerReducer from '../features/public/register/registerSlice';
import loginReducer from '../features/public/login/loginSlice';
import areaReducer from '../features/auth/authHome/area/areaSlice';
import chatReducer from '../features/auth/chatSlice';
import callReducer from '../features/auth/callSlice';
import calendarReducer from '../features/auth/authHome/sections/calendar/calendarSlice';
import favouriteReducer from '../features/auth/authHome/sections/favorites/favoriteSlice';
import messageReducer from "./messages/messageSlice";

export const store = configureStore({
  reducer: {
    event: eventReducer,
    auth: authReducer,
    errors: errorReducer,
    register: registerReducer,
    login: loginReducer,
    area: areaReducer,
    chat: chatReducer,
    call: callReducer,
    calendar: calendarReducer,
    favourite: favouriteReducer,
    generalSettings: generalSettingsReducer,
    mobile: mobileReducer,
    messages: messageReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
