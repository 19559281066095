import FullCalendar from "@fullcalendar/react";
import React, { useRef } from "react";
import listGridPlugin from "@fullcalendar/list"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default function CalendarRow({events, setModalActionInfo}: {events: any, setModalActionInfo: (info: any) =>void}) {
	const calendarRef = useRef<any>();

	return (
		<FullCalendar
			ref={calendarRef}
			plugins={[listGridPlugin, timeGridPlugin, interactionPlugin]}
			editable={true}
			headerToolbar={{
				left: "prev,next today",
				center: "title",
				right: "timeGridWeek,listWeek",
			}}
			height="100%"
			visibleRange={{ start: "2021-08-28", end: "2021-08-31" }}
			initialView="timeGridWeek"
			eventClick={(info: any) => setModalActionInfo(info)}
			events={events?.map((ele: any) => ({
				...ele,
				title: ele.name,
				classNames: [ele.category],
			}))}
		/>
	);
}
