import React from "react";
import ProfileImageUpdate from "./ProfileImageUpdate";

const ProfileImage = ({
	avatar,
	editMode,
	editImageHandler,
}: {
	avatar: string;
	editMode?: boolean;
	editImageHandler: (data: any, callback: () => void) => void;
}) => {
	return (
		<>
			<div className="img mb-3 mt-0">
				<img src={avatar} alt="" width="120px" height="120px" />
			</div>
			{editMode && (
				<ProfileImageUpdate editImageHandler={editImageHandler}/>
			)}
		</>
	);
};

export default React.memo(ProfileImage);
