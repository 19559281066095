import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addFavoriteEventCalendarAction, openModuleAction,
	removeFavoriteEventCalendarAction,
	selectCalendarEvents,
	selectCalendarUserEvents,
	setCalendarAction,
} from "./calendarSlice";
import FullCalendar from "@fullcalendar/react";
import listGridPlugin from "@fullcalendar/list"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import PrimaryButton from "../../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import CardDetail from "../../../../../components/shared/design/card-detail/CardDetail";

export function Calendar({ showGeneral = false, closeHandler, changeArea, openProfile }: { showGeneral?: boolean, openProfile: (id: string) => void,  closeHandler: () => void, changeArea?: (id: number) => void }) {
	const dispatch = useDispatch();
	const events = useSelector(selectCalendarEvents);
	const eventsUser = useSelector(selectCalendarUserEvents);
	const calendarRef = useRef<any>();
	useEffect(() => {
		dispatch(setCalendarAction());
	}, []);

	const [modalActionInfo, setModalActionInfo] = useState<any>(undefined);

	const modalActionClick = () => {
		!modalActionInfo.isPersonal
			? dispatch(addFavoriteEventCalendarAction(modalActionInfo.event.id))
			: dispatch(removeFavoriteEventCalendarAction(modalActionInfo.event.id));
		setModalActionInfo(undefined);
	};
	const openModuleClick = (info: any) => {
		setModalActionInfo(undefined);
		closeHandler();
		if(info.sel.category !== 'meeting'){
			changeArea && changeArea(+info.sel.area_id);
			dispatch(openModuleAction(info.sel.module_id))
		} else {
			if(info.sel.contact_user_id){
				openProfile && openProfile(info.sel.contact_user_id)
			}
		}
	};
	const setModalActionInfoHandler = (info: any) => {
		let sel = events.find((ele: any) => ele.id.toString() === info.event.id.toString())
		if(!sel){
			sel = eventsUser.find((ele: any) => ele.id.toString() === info.event.id.toString())
		}
		if(sel.category !== 'meeting' || sel.contact_user_id){			
			setModalActionInfo({...info, sel, isPersonal: !!eventsUser.find((ele: any) => ele.id.toString() === info.event.id.toString())})
		}
	}

	return (
		<>
			{!!modalActionInfo && (
				<CardDetail
					headerTitle="Scegli azione"
					closeHandler={() => setModalActionInfo(undefined)}>
					<p>Evento selezionato: {modalActionInfo.event.title} </p>
					<PrimaryButton
						className="mb-2"
						size="sm"
						onClick={() =>
							openModuleClick(modalActionInfo)
						}>
						{ modalActionInfo.sel.category !== 'meeting'? 'apri modulo' : "apri il profilo per iniziare l'appuntamento"}
					</PrimaryButton>
					{
						modalActionInfo.sel.category !== 'meeting' && <PrimaryButton size="sm" onClick={modalActionClick}>
							{!modalActionInfo.isPersonal
								? "aggiungi a calendario personale"
								: "rimuovi da calendario personale"}
						</PrimaryButton>
					}
				</CardDetail>
			)}

			<FullCalendar
				ref={calendarRef}
				plugins={[listGridPlugin, timeGridPlugin, interactionPlugin]}
				eventClick={setModalActionInfoHandler}
				editable={true}
				headerToolbar={{
					left: "prev,next today",
					center: "title",
					right: "timeGridWeek,listWeek",
				}}
				height="100%"
				visibleRange={{ start: "2021-08-28", end: "2021-08-31" }}
				initialView="listWeek"
				events={
					(showGeneral ? events : eventsUser)?.map((ele) => ({
						id: ele.id,
						title: ele.name,
						start: ele.start,
						end: ele.end,
						classNames: [ele.category],
					})) || []
				}
			/>
		</>
	);
}
