import React from "react";
import {AreaComponentModel} from "./model/AreaComponentModel";
import {useDispatch, useSelector} from "react-redux";
import {selectIsMobile} from "../../../../app/generalSettingsSlice";
import {AreaDesktop} from "./desktop/AreaDesktop";
import {AreaMobile} from "./mobile/AreaMobile";
import {setActiveElementArea} from "./areaSlice";
import {SpotModel} from "../model/AreaModel";

export function AreaHome({ area, changeArea, activeChat, activeCalendarProfile, activeRepresentativesList, activePosters }: AreaComponentModel) {
	const isMobile = useSelector(selectIsMobile)
	const dispatch = useDispatch();
	const clickArea = (ar: SpotModel) => {
		if (ar.actions.link.includes("areas")) {
			const arr = ar.actions.link.split("/");
			changeArea && changeArea(+arr[arr.length - 1]);
		} else if (ar.actions.link.includes("chat")) {
			activeChat && activeChat();
		} else if (ar.actions.link.includes("profile")) {
			const arr = ar.actions.link.split('/');
			activeCalendarProfile && activeCalendarProfile(arr[arr.length-1]);
		}else if (ar.actions.link.includes("representatives")) {
			activeRepresentativesList && activeRepresentativesList(ar.actions.link)
		} else if (ar.actions.link.includes("posters")) {
			activePosters && activePosters(ar.actions.link)
		}
		else {
			dispatch(setActiveElementArea(ar));
		}
	};
	return <>{
		isMobile? <AreaMobile area={area} clickArea={clickArea}  /> : <AreaDesktop clickArea={clickArea} area={area}/>
	}</>;
}
