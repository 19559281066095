import React, { useEffect, useState} from "react";

import {AreaSingleMapText} from "./AreaSingleMapText";
import {AreaSingleMapArea} from "./AreaSingleMapArea";
import {AreaSingleMapModel} from "../model/AreaComponentModel";

export function AreaSingleMap({
	imgRef,
	ar,
	clickArea
}: AreaSingleMapModel) {

	const [dimension, setDimension] = useState<any>({});


	const setText = (spot: any, parentWidth: any, parentHeight: any) => {
		const x = (spot.x * parentWidth) / 100;
		const y = (spot.y * parentHeight) / 100;
		const diff = parentWidth - window.innerWidth;
		return spot.type === "text"
			? {
					x,
					y,
					text: spot.text.text,
					style: {
						fontSize: spot.text.font_size + "px",
						position: "absolute",
						cursor: "pointer",
						bottom: parentHeight - y - spot.text.font_size - 20 + "px",
						left: (x - (diff? diff/2 : 0)) + "px",
					},
			  }
			: undefined;
	};
	const findImage = (spot: any, parentWidth: any, parentHeight: any) => {
		const style = spot?.default_style;
		if (style?.background_image_url) {
			const diff = parentWidth - window.innerWidth;
			const height = (spot.height_image_background * parentHeight) / 100;
			const width = (spot.width_image_background * parentWidth) / 100;
			const x = (spot.x_image_background * parentWidth) / 100;
			const y = (spot.y_image_background * parentHeight) / 100;
			return {
				url: style?.background_image_url,
				style: {
					position: "absolute",
					bottom: parentHeight - y - height + "px",
					width: width + "px",
					height: height + "px",
					left: (x - (diff? diff/2 : 0)) + "px",
				},
				styleBK: style,
				height,
				width,
				x,
				y,
			};
		} else {
			return undefined;
		}
	};
	const handleResize = () => {
		const backgroundWidth = imgRef.current?.width || 0;
		const backgroundHeight = imgRef.current?.height || 0;
		const x = (backgroundWidth * ar.x) / 100;
		const y = (backgroundHeight * ar.y) / 100;
		const width = (backgroundWidth * ar.width) / 100;
		const height = (backgroundHeight * ar.height) / 100;
		let coords = `${x},${y},${width + x},${height + y}`;
		if (ar.type === "poly") {
			coords = ar.points
				?.reduce(
					(arr: any[], ele) => [
						...arr,
						((width || 0) * ele.x) / 100 + x,
						((height || 0) * ele.y) / 100 + y,
					],
					[]
				)
				.join(",");
		}
		setDimension({
			backgroundWidth,
			backgroundHeight,
			x,
			y,
			width,
			height,
			coords,
			image: findImage(ar, backgroundWidth, backgroundHeight),
			text: setText(ar, backgroundWidth, backgroundHeight),
		});
	};
	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
	}, [imgRef.current?.width, imgRef.current?.height]);
	return (
		<>
			{dimension.image && (
				// <PopoverCustom tooltipBodyTemplate={"Title"}>
					<div
						className="div-spot"
						onClick={() => clickArea(ar)}
						style={dimension.image.style}>
						<img src={dimension.image.url} />
					</div>
				// </PopoverCustom>
			)}
				{dimension.text ? (
					<AreaSingleMapText dimension={dimension} ar={ar} clickArea={clickArea} />
				) : (
					<AreaSingleMapArea dimension={dimension} ar={ar} clickArea={clickArea}/>
				)}
		</>
	);
}
