import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {selectCountdownRegister, selectLanguage, SettingsModel} from "../event/eventSlice";
import { Login } from "../login/Login";
import { pages } from "../publicData";
import { ContentBox } from "./ContentBox/ContentBox";
import { Registration } from "./Registration/Registration";
import {useSelector} from "react-redux";


interface IPublicHome extends SettingsModel {
	setCurrentPage: (route: string, data?: string, callbackReset?: () => void, guest?: boolean) => void;
}
export const PublicHome = ({
	profiles,
	login: showLogin,
	registration: showRegister,
	allow_guest: allowGuest,
	logo_path: logo,
	id: eventId,
	setCurrentPage,
    ...rest
}: IPublicHome) => {
	const [inAnimation, setInAnimation] = useState<boolean>(false);
	const language = useSelector(selectLanguage);
	const countdown = useSelector(selectCountdownRegister);
	useEffect(() => {
		setTimeout(() => {
			setInAnimation(true);
		}, 200);
	}, []);

	const delayUnmountAnimation = (pageFrom: string, data?: any, guest: boolean = false) => {
		setInAnimation(false);
		setTimeout(() => {
			setCurrentPage(pageFrom, data, () => setInAnimation(true), guest);
		}, 400);
	};
	const descriptionType: string | undefined = Object.keys(rest).find(ele => ele.startsWith('description') && language === ele.replace('description_', ''))
	const description = (descriptionType && (rest as any)[descriptionType]) || '';
	return (
		<>
			<Container fluid>
				<Row className="justify-content-center">
					<Col xs="12" sm="12" md="12" lg="8" xl="9" style={{display: "flex",
						flexDirection: "column"}}>
						<ContentBox inAnimation={inAnimation} html={description} />
					</Col>
					<Col xs="12" sm="10" md="8" lg="4" xl="3">
						{(!!showRegister || !!countdown) && (!allowGuest) && (
							<Registration
								logo={logo}
								countdown={countdown}
								registrationAction={() =>
									delayUnmountAnimation(pages.registration)
								}
								inAnimation={inAnimation}
							/>
						)}
						{
							<Login
								inAnimation={inAnimation}
								disableLogin={!showLogin}
								allowGuest={!!allowGuest}
								loginHandler={(data) =>
									delayUnmountAnimation(pages.login, data)
								}
								loginGuest={() =>
									delayUnmountAnimation(pages.login, {lang:language}, true)
								}
								logo={logo}
							/>
						}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default PublicHome;
