import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { selectIsMobile } from "../../../../app/generalSettingsSlice";
import { MenuAppModel } from "./desktop/menuApp/model/MenuAppModel";
import {
	selectBigBanner
} from "../../../../app/auth/authSlice";
import {selectDockAreas, setActiveElementArea} from "../area/areaSlice";
import MenuAreasDesk from "./desktop/menuApp/MenuAreasDesk";
import MenuAreasMobile from "./mobile/MenuAreasMobile";

const MenuAreas = ({ theme, setCurrentArea }: MenuAppModel) => {
	const dispatch = useDispatch();
	const isMobile = useSelector(selectIsMobile);
	const menuItems =
		useSelector(selectDockAreas)?.map((ele) => ({
			...ele,
			label: ele.name,
			icon: { light: ele.icon_path, dark: ele.icon_path },
		})) || [];
	const bigBanner = useSelector(selectBigBanner);
	const openBigBannerModule = () => {
		bigBanner?.module &&
			dispatch(
				setActiveElementArea({
					actions: {
						link: bigBanner.module,
					},
				})
			);
	};
	return (
		<>
			{isMobile ? (
				<MenuAreasMobile
					theme={theme}
					setCurrentArea={setCurrentArea}
					menuItems={menuItems}
					bigBanner={bigBanner}
					openBigBannerModule={openBigBannerModule}
				/>
			) : (
				<MenuAreasDesk
					theme={theme}
					bigBanner={bigBanner}
					setCurrentArea={setCurrentArea}
					menuItems={menuItems}
					openBigBannerModule={openBigBannerModule}
				/>
			)}
		</>
	);
};

export default MenuAreas;
