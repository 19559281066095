import React from "react";
import { useTranslation } from "react-i18next";
import {MenuModel} from "../model/MenuModel";
import MenuTool from "./menuTool/MenuTool";
import MenuAreas from "../MenuAreas";
import { selectSettingEvent } from "../../../../public/event/eventSlice";
import { useSelector } from "react-redux";


export function MenuDesk({setCurrentArea, toggleChat, theme, isShowChat, toggleTool} : MenuModel) {
	const { t } = useTranslation();
	const setting = useSelector(selectSettingEvent);
	return (
		<>
			<MenuAreas
				setCurrentArea={setCurrentArea}
				theme={theme}
			/>
			{!setting.autologin_guest &&
				<MenuTool
					theme={theme}
					toggleChat={toggleChat}
					isShowChat={isShowChat}
					toggleTool={toggleTool}
				/>
			}
		</>
	);
}
