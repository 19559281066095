import React from "react";
import { useTranslation } from "react-i18next";
import Caption from "../../../../components/shared/headings/caption/Caption";
import HeadingH5 from "../../../../components/shared/headings/headingH5/HeadingH5";

const RowBtnLogin = ({ loginHandler }: { loginHandler: () => void }) => {
	const { t } = useTranslation();

	return (
		<div className="d-flex flex-column w-100">
			<HeadingH5 className="py-2 text-center">
				{t("login.alreadyAccount")}{" "}
				<Caption className="link medium" onClick={loginHandler}>
					{t("login.signIn")}
				</Caption>
				.
			</HeadingH5>
		</div>
	);
};

export default RowBtnLogin;
