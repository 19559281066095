import React, {useState} from "react";
import ListUsers from "./ListUsers";
import {addUpdateChannelAction, createNewChatAction, setChannelPrivate} from "../../../chatSlice";
import PublicChannel from "../PublicChannel";
import ListRooms from "./ListRooms";
import PersonalRooms from "./PersonalRooms";
import ListStands from "./ListStands";
import Profile from "./profile/Profile";
import {useDispatch} from "react-redux";
import {User} from "../../../../../app/auth/model/User";
import {SettingsModel} from "../../../../public/event/eventSlice";


export default function ListChat({
	showUsers,
	userLoggedBirdId,
	setShowUsers,
    sdkInstance,
	setPublicChannelHandler,
	selectChannel,
	 publicChannel,
	roomsChannel,
	channels,
	userLogged,
	standChannels,
}: {
	publicChannel: any;
	setPublicChannelHandler: (channel: any) => void;
	sdkInstance: any;
	userLogged?: User;
	selectChannel: any,
	showUsers: boolean;
	userLoggedBirdId: string;
	setShowUsers: (data: boolean) => void;
	roomsChannel: any[];
	channels: any[];
	standChannels: any[];
}) {
	const dispatch = useDispatch();
	const [showChat, setshowChat] = useState<string | undefined>(undefined);
	return (
		<div className="sendbird-app__channellist-wrap">
			{showUsers && (
				<ListUsers
					userLoggedId={userLoggedBirdId}
					createChannel={(users) => {
						dispatch(createNewChatAction(sdkInstance, users, () => setShowUsers(false)));
					}}
					sdkInstance={sdkInstance}
					closeHandler={() => setShowUsers(false)}
				/>
			)}
			<PublicChannel
				channel={publicChannel}
				setPublicChannel={setPublicChannelHandler}
				isActive={
					selectChannel?.type === "public" &&
					selectChannel.url === publicChannel.url
				}
			/>
			<div className="chat-container">
				<ListRooms
					roomsChannel={roomsChannel}
					setPublicChannel={setPublicChannelHandler}
					selectChannel={selectChannel}
					chatActive={showChat}
					setChatActive={setshowChat}

				/>

				{userLogged && (
					<PersonalRooms
						channels={channels}
						userLogged={userLoggedBirdId}
						selectChannel={selectChannel}
						setChannelPrivate={setChannelPrivate}
						chatActive={showChat}
						setChatActive={setshowChat}
					/>
				)}

				<ListStands
					channels={standChannels}
					setPublicChannel={setPublicChannelHandler}
					selectChannel={selectChannel}
					chatActive={showChat}
					setChatActive={setshowChat}

				/>
			</div>
		</div>
	);
}
