import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import Icon from "../../icon/Icon";
import MsgPropsStyle from "./models/MsgProps";

interface MsgProps {
	className?: string;
	toastStyle: MsgPropsStyle;
	title: string;
	body?: string;
	showToast?: boolean;
	closeAction: () => void;
}
const Msg = ({
	className,
	toastStyle,
	title,
	body,
	showToast = true,
	closeAction,
}: MsgProps) => {
	const [animationState, setAnimationState] = useState("out");
	useEffect(() => {
		setTimeout(() => {
			setAnimationState("in");
		}, 300);
	}, []);

	const closeHandler = () => {
		setAnimationState("out");
		setTimeout(() => {
			closeAction();
		}, 800);
	};

	return (
		<div className={`shared-msg ${animationState} ${className}`}>
			<Toast
				show={showToast}
				onClose={closeHandler}
				style={{
					backgroundColor: toastStyle.style.bgColor,
					color: toastStyle.style.color,
				}}>
				<Toast.Header
					style={{
						backgroundColor: toastStyle.style.bgColor,
						color: toastStyle.style.color,
					}}>
					{/* <Icon
						icon={toastStyle.icon.ico}
						colorStyle={toastStyle.icon.color}
						className="ml-2 mr-2"
						size="18px"></Icon> */}
					{/* <strong className="mr-auto">{title}</strong> */}
				</Toast.Header>
				{body && (
					<Toast.Body>
						<div className="content-body">{body}</div>
					</Toast.Body>
				)}
			</Toast>
		</div>
	);
};

export default Msg;
