import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InputFileUpdate from "./InputFileUpdate";

const ProfileImage = ({
	editImageHandler,
}: {
	editImageHandler: (data: any, callback: () => void) => void;
}) => {
	const { t } = useTranslation();

	const inputRef = useRef<HTMLInputElement>(null);
	const editImage = () => {
		inputRef?.current?.click();
	};
	return (
		<>
			<small className="primary pointer" onClick={editImage}>
				{t("profile.updateImgProfile")}
			</small>
			<InputFileUpdate
				inputRef={inputRef}
				editImageHandler={editImageHandler}
			/>
		</>
	);
};

export default React.memo(ProfileImage);