import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../../../../components/shared/design/form/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../../../components/shared/design/form/buttons/secondary-button/SecondaryButton";
import FormElements from "../../../../../../components/shared/utils/forms/FormElements";
import { FormElementModelNew } from "../../../../../../components/shared/utils/forms/models/FormElementModel";
import { UseFormSubmitModel } from "../../../../../../components/shared/utils/forms/models/UseFormModel";
import { useForm } from "../../../../../../components/shared/utils/forms/useForm";
import { createStructureForm } from "../../../../../../components/shared/utils/forms/useStructureForm";
import {useDispatch, useSelector} from "react-redux";
import {
    selectProfileBaseData,
    updateProfileAction
} from "../../../../../../app/auth/authSlice";
import {User} from "../../../../../../app/auth/model/User";

const EditProfileForm = ({ setProfile, cancelEdit }: { cancelEdit: () => void, setProfile: (user: User)=> void }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileBaseData = useSelector(selectProfileBaseData);
    const validations = Object.keys(profileBaseData || {}).reduce((acc, ele) => {
        return {...acc,  [ele]: {
                required: {
                    params: { name: t(ele) },
                }
            },}
    }, {})
    const structureForm: FormElementModelNew[] = createStructureForm([...Object.keys(profileBaseData || {}).map(ele => (
        {
            typeElement: "input",
            label: t(ele),
            name: ele,
            dataElement: {
                type: "text",
                classNameGroup: "inputBordered",
            },
            col: { xs: "6" },
        }))]);

    const submitHandler: UseFormSubmitModel = (event, data) => {
        const callback = (user: User) => {
            setProfile(user);
            cancelEdit();
        }
        dispatch(updateProfileAction(data, callback))
    };

    const { isSubmit, errors, submitAction, changeValue, dataForm } =
        useForm(submitHandler, validations, profileBaseData);

    return (
        <div className="right-panel w-100">
            <Form onSubmit={submitAction}>
                <FormElements
                    isSubmit={isSubmit}
                    data={structureForm}
                    errors={errors}
                    changeValue={changeValue}
                    dataForm={dataForm}
                />
                <Row className="justify-content-end mt-3">
                    <Col xs="auto">
                        <SecondaryButton type="submit" size="sm" onClick={cancelEdit}>
                            Annulla
                        </SecondaryButton>
                    </Col>
                    <Col xs="auto">
                        <PrimaryButton type="submit" size="sm">
                            Salva
                        </PrimaryButton>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default EditProfileForm;
