import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import "./config/i18n";
import axios from "axios";
import authInterceptor from "./interceptor/authInterceptor";
import status401Interceptor from "./interceptor/status401Interceptor";
import errorInterceptor from "./interceptor/errorInterceptor";
import successInterceptor from "./interceptor/successInterceptor";
import redirectInterceptor from "./interceptor/redirectInterceptor";
axios.interceptors.request.use(authInterceptor);
axios.interceptors.response.use((config: any) => config, status401Interceptor);
axios.interceptors.response.use(redirectInterceptor, (err: any) => Promise.reject(err));
axios.interceptors.response.use(successInterceptor, errorInterceptor);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
