import React, { useEffect, useState } from "react";
import CardRounded from "../../../components/shared/cards/card/cardRounded/CardRounded";
import { calcVh } from "../../../components/shared/utils/vhUtils";
import { SettingsModel } from "../event/eventSlice";
import RowBtnLogin from "../login/components/RowBtnLogin";
import Register from "../register/Register";

interface IPublicHome extends SettingsModel {
	goBase: () => void;
}
let timeoutAction: any;
export const PublicRegisterContainer = ({
	logo_path: logo,
	profiles,
	goBase,
}: IPublicHome) => {
	const [inAnimation, setInAnimation] = useState<boolean>(false);
	const [height, setHeight] = useState(`${calcVh(95)}`);
	useEffect(() => {
		calcMaxHeight();
	}, []);
	useEffect(() => {
		window.addEventListener("resize", () => {
			timeoutAction && clearTimeout(timeoutAction);
			timeoutAction = setTimeout(() => {
				calcMaxHeight();
				// setHeight(document.querySelector(".login-panel")?.clientHeight + "px");
			}, 400);
		});
		return () => window.removeEventListener("resize", calcMaxHeight);
	});
	const calcMaxHeight = () => {
		setHeight(`${calcVh(95)}`);
		setTimeout(() => {
			setHeight(document.querySelector(".login-panel")?.clientHeight + "px");
		}, 400);
	};

	useEffect(() => {
		setTimeout(() => {
			setInAnimation(true);
		}, 200);
	}, []);

	const delayUnmountAnimation = () => {
		setInAnimation(false);
		setTimeout(() => {
			goBase && goBase();
		}, 400);
	};

	const stylePanel = {
		maxHeight: height,
	};
	const styleContent: any = {
		overflowY: height === `${calcVh(950)}` ? "hidden" : "auto",
		WebkitOverflowScrolling: "touch",
	};
	return (
		<CardRounded
			className={`login-panel ${inAnimation ? "in" : ""}`}
			styleCard={stylePanel}>
			<img src={logo} alt="logo" className="logo" />
			<div className="content" style={styleContent}>
				<Register
					profiles={profiles}
					goLogin={delayUnmountAnimation}
					changeProfileId={calcMaxHeight}
				/>
				<RowBtnLogin loginHandler={delayUnmountAnimation} />
			</div>
		</CardRounded>
	);
};

export default PublicRegisterContainer;
