import React from "react";
import ToastPropsStyle from "../../../toast/models/ToastProps";
import ToastMsg from "../../../toast/ToastMsg";
import { ToastProps } from "../models/ToastProps";

const styleError: ToastPropsStyle = {
  style: { color: "#fff", bgColor: "#a10000" },
  icon: { color: "white", ico: "error" },
};

const ToastError = ({
  className,
  title,
  body,
  closeAction,
  ...rest
}: ToastProps) => {
  return (
    <ToastMsg
      className="shared-toastError"
      toastStyle={styleError}
      title={title}
      body={body}
      closeAction={() => closeAction && closeAction()}
    ></ToastMsg>
  );
};

export default ToastError;
